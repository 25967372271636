import {
	Button,
	Input,
	Loader,
	ReactDropdown,
	SketchColorPicker,
} from '@storybook';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';

import { API_URL, WARNINGS } from 'constant';
import {
	GoogleFontsState,
	IFont,
	kycSettingsState,
	loginState,
} from 'global-stores';
import { useNetwork, useNotification } from 'hooks';
import { useDashboradhook } from 'views/routes-children';
import './company-details-edit.scss';
import { isOnlySpace } from 'utils';

type IEditModalBody = {
	setIsOpenModal: (value: boolean) => void;
};

type ISelectedFont = {
	label: string;
	value: IFont;
	fontFamily?: string;
};

export const EditModalBody: FC<IEditModalBody> = ({ setIsOpenModal }) => {
	const [kycSettings, setKycSettings] = useRecoilState(kycSettingsState);
	const googleFonts = useRecoilValue(GoogleFontsState);
	const userDetail = useRecoilValue(loginState);
	const { companyName: company } = useMemo(() => userDetail, [userDetail]);
	const [isColorPickerVisible, setColorPickerVisible] = useState(false);
	const [currentColor, setCurrentColor] = useState('#0051cc');
	const [companyName, setCompanyName] = useState('');
	const [selectFont, setSelectedFont] = useState<ISelectedFont>({
		label: 'Poppins',
		value: {
			category: 'sans-serif',
			family: 'Poppins',
		},
	});

	const [isCompanyNameError, setCompanyNameError] = useState(false);
	const [companyNameErrorMessage, setCompanyNameErrorMessage] = useState('');

	const { post: changeSettings, loading: clientSecretLoader } = useNetwork();
	const { successNotification } = useNotification();
	const { getPipelineInit } = useDashboradhook();

	const { NAME_MISSING, SAVED, INVALID_WHITE_LABEL, EMPTY__WHITE_LABEL } =
		WARNINGS;
	const { _id } = kycSettings;

	useEffect(() => {
		if (kycSettings) {
			const { name, settings } = kycSettings;

			if (name || company) {
				setCompanyName(name ?? company);
			}

			if (settings) {
				const { brandColor, bodyFont } = settings ?? {};

				if (brandColor) {
					setCurrentColor(brandColor);
				}

				if (bodyFont?.family) {
					setSelectedFont({
						label: bodyFont.family,
						value: bodyFont,
					});
				}
			}
		}
	}, [kycSettings, company]);

	const Options = useMemo(() => {
		return (googleFonts?.data ?? googleFonts)?.map(font => {
			return { label: font.family, value: font, fontFamily: font.family };
		});
	}, [googleFonts]);

	const showColorPicker = useCallback(() => {
		setColorPickerVisible(true);
	}, []);

	const handleOnChangeColor = useCallback((brandColor: string) => {
		setCurrentColor(brandColor);
	}, []);

	const closeOutside = useCallback(() => {
		setColorPickerVisible(false);
	}, []);

	const handleChangeName = useCallback(
		(e: React.ChangeEvent<HTMLInputElement>) => {
			const { value } = e.target;
			setCompanyNameError(false);
			setCompanyName(value);
		},
		[]
	);

	const handleChangeFont = useCallback((e: ISelectedFont) => {
		setSelectedFont(e);
	}, []);
	// Rahul Singh: add loader on save button at edit in general setting
	const saveButtonLabels = useMemo((): JSX.Element => {
		if (clientSecretLoader) {
			return <Loader type="loader" dimension={18} className="loader-white" />;
		}
		return <div>Save</div>;
	}, [clientSecretLoader]);

	const handleSaveSettings = useCallback(async() => {
		let errorMessage = '';

		if (!companyName) {
			errorMessage = EMPTY__WHITE_LABEL;
		} else if (isOnlySpace(companyName)) {
			errorMessage = INVALID_WHITE_LABEL;
		}

		if (errorMessage) {
			setCompanyNameError(true);
			setCompanyNameErrorMessage(errorMessage);
			return;
		}

		const payload = {
			name: companyName.trim(),
			settings: {
				bodyFont: {
					category: selectFont?.value?.category ?? '',
					family: selectFont?.value?.family ?? '',
				},
				brandColor: currentColor,
				headerFont: {
					category: selectFont?.value?.category ?? '',
					family: selectFont?.value?.family ?? '',
				},
			},
		};

		const res = await changeSettings(`${API_URL.CLIENT_DETAILS}`, payload)
			if (res && Object.keys(res).length !== 0) {
				getPipelineInit();
				setKycSettings(prev => ({
					...prev,
					...payload,
					settings: { ...payload.settings, _id },
				}));
				successNotification(SAVED);
			}
		setIsOpenModal(false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		_id,
		changeSettings,
		companyName,
		currentColor,
		selectFont?.value?.category,
		selectFont?.value?.family,
		NAME_MISSING,
		SAVED,
	]);

	const renderSettings = useCallback(
		(label: string, type?: string) => {
			switch (type) {
				case 'color':
					return (
						<>
							<div className="modal-body__label">{label}</div>
							<div className="modal-body__input" onClick={showColorPicker}>
								<div
									className="brand-color__display-color"
									style={{ backgroundColor: currentColor }}
								/>
								<div className="brand-color__color-code">{currentColor}</div>
							</div>
							{isColorPickerVisible && (
								<div className="brand-color__color-picker-wrapper">
									<SketchColorPicker
										currentColor={currentColor}
										handleOnChange={(e: any) => handleOnChangeColor(e.hex)}
										closeOutside={closeOutside}
									/>
								</div>
							)}
						</>
					);

				case 'font':
					return (
						<>
							<div className="modal-body__label">{label}</div>
							<ReactDropdown
								controlStyle={{
									fontFamily: selectFont.fontFamily,
									minHeight: 56,
									height: 56,
									borderRadius: 8,
									margin: '4px 0px',
									backgroundColor: '#f0f4ff',
									'&:hover': { borderColor: '#4574f5', cursor: 'pointer' },
									paddingLeft: 6,
									color: '#5d657a',
								}}
								optionsStyle={{
									'&:hover': { background: 'var(--color-hover-light-100)' },
									height: 56,
									minHeight: 56,
									paddingLeft: 16,
									color: '#5d657a',
									fontWeight: 500,
									fontSize: 14,
								}}
								handleChangeSelect={handleChangeFont}
								value={selectFont}
								options={Options}
								isSearchable
								defaultValue={{ label: 'Times New Roman', value: '' }}
							/>
							<div className="modal-body__text-for-default">
								If no font is selected, we will default to Poppins Bold.
							</div>
						</>
					);
			}
			return null;
		},
		[
			Options,
			closeOutside,
			currentColor,
			handleChangeFont,
			handleOnChangeColor,
			isColorPickerVisible,
			selectFont,
			showColorPicker,
		]
	);

	return (
		<div className="modal-body">
			<div className="company-name">
				<Input
					label={'Whitelabel Name'}
					maxLength={50}
					inputType="text"
					placeholder="Enter your company name"
					value={companyName}
					handleChange={handleChangeName}
					isError={isCompanyNameError}
					errorMessage={companyNameErrorMessage}
				/>
			</div>

			<div className="brand-color">
				{renderSettings('Brand Color', 'color')}
			</div>

			<div className="font-for-header">
				{renderSettings('Font Family', 'font')}
			</div>

			<div className="modal-body__btn-container">
				<Button
					handleClick={handleSaveSettings}
					type="button__filled--primary btn-save-branding"
					label={saveButtonLabels}
				/>
			</div>
		</div>
	);
};
