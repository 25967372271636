import { FC, Fragment } from 'react';
import { IRiskView } from 'global-stores/sessions/type';
import { camelCaseToCapitalizedWords } from 'utils';
import { BasicSection, KeyValuePair, KybInfoHeader } from 'views';
import { v4 } from 'uuid';

interface IProps {
	riskData: IRiskView;
	type?: string;
	isFromKYBOfac?: boolean;
}

export const AmlRiskAnalysis: FC<IProps> = ({
	riskData,
	type,
	isFromKYBOfac=false,
}) => {
	if (Object.keys(riskData ?? {})?.length === 0) {
		return <></>;
	}
	return (
		<div>
			<KybInfoHeader type={type ?? 'Risk View'} />
			<div>
				{Object.entries(riskData ?? {}).map(([key, value]) => (
					<div
						key={v4()}
						className={`${isFromKYBOfac ? 'AmlRiskAnalysis' : ''}`}
					>
						{!isFromKYBOfac ? (
							<div className="aml-view-more-heading aml-risk-heading">
								{camelCaseToCapitalizedWords(key)}
							</div>
						) : (
							<div className='AmlRiskAnalysis__category'>
								<KeyValuePair
									label={'Risk View'}
									value={camelCaseToCapitalizedWords(key)}
								/>
							</div>
						)}
						{value &&
							typeof value === 'object' &&
							Object.keys(value).map(item =>
								item === 'riskScores' ? (
									<Fragment key={v4()}>
										<div className="AMLWatcherDetails--sanctions__country">
											<div className="AMLWatcherDetails--sanctions__label">
												{camelCaseToCapitalizedWords(item)}
											</div>
											<div>:</div>
											<div className="AMLWatcherDetails--sanctions__data">
												{Object.entries(
													value[item] as Record<string, string | number>
												).map(([key, value]) => (
													<div key={v4()}>
														{`${camelCaseToCapitalizedWords(key)}( ${value} )`}
													</div>
												))}
											</div>
										</div>
									</Fragment>
								) : (
									<BasicSection
										key={v4()}
										label={camelCaseToCapitalizedWords(item)}
										value={value?.[item]}
										isFromAmlRisk={true}
									/>
								)
							)}
					</div>
				))}
			</div>
		</div>
	);
};
