import { useOutsideClick } from 'hooks';
import React, {
	ChangeEvent,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react';
import InputMask from 'react-input-mask';
import {
	Calendar,
	DayValue,
} from '@hassanmojab/react-modern-calendar-datepicker';
import '@hassanmojab/react-modern-calendar-datepicker/lib/DatePicker.css';

import { DEFAULT_MAX_DATE, DEFAULT_MIN_DATE, formatDate } from './constant';

import '@storybook/react-date-picker/react-date-picker.scss';

/**
 * Interface for the DateCalendar component props.
 * Provides a contract for passing props to the DateCalendar component.
 */
interface IDateCalendar {
	value?: DayValue | null; // Selected date value in DayValue format (or null).
	isError?: boolean; // Indicates whether the input has an error.
	onChange?: (event: ChangeEvent<HTMLInputElement>) => void; // Callback triggered on date change.
	id?: string; // Optional ID for the input element.
	format?: 'mm/dd/yyyy' | 'dd/mm/yyyy' | 'yyyy/mm/dd'; // Date format.
	isModal?: boolean; // Determines if the calendar should display in a modal.
	minDate?: DayValue | null; // Minimum date allowed for selection.
	maxDate?: DayValue | null; // Maximum date allowed for selection.
	inputDisabled?: boolean; // Disables the date input field.
}

/**
 * DateCalendar Component
 * Provides a customizable date picker with an optional modal-style calendar.
 */
const DateCalendar: React.FC<IDateCalendar> = ({
	value,
	onChange,
	isError,
	format = 'mm/dd/yyyy',
	isModal = false,
	minDate = DEFAULT_MIN_DATE,
	maxDate = DEFAULT_MAX_DATE,
	inputDisabled,
}) => {
	const [showCalendar, setShowCalendar] = useState(false); // Toggles the visibility of the calendar.
	const [inputValue, setInputValue] = useState(''); // Stores the value displayed in the input field.
	const wrapperRef = useRef<HTMLDivElement>(null); // Ref for detecting clicks outside the calendar.

	/**
	 * Handles date selection from the calendar.
	 * @param day - The selected date in DayValue format.
	 */
	const handleCalendarChange = (day: DayValue) => {
		if (day) {
			const formattedDate = formatDate({ ...day, format });
			setInputValue(formattedDate); // Update input field with formatted date.

			// Trigger the onChange callback with the selected date.
			onChange?.({
				target: { value: day },
			} as any);
		}
		setShowCalendar(false); // Close the calendar after selection.
	};

	/**
	 * Handles changes in the input field and parses the entered date.
	 * @param e - Input change event.
	 */
	const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
		const { value } = e.target;
		setInputValue(value); // Update input value as user types.

		const [part1 = '0', part2 = '0', part3 = '0'] = value
			.split('/')
			.map(Number);

		let day = 0,
			month = 0,
			year = 0;

		// Parse the input based on the specified date format.
		switch (format) {
			case 'mm/dd/yyyy':
				[month, day, year] = [+part1, +part2, +part3];
				break;
			case 'dd/mm/yyyy':
				[day, month, year] = [+part1, +part2, +part3];
				break;
			case 'yyyy/mm/dd':
				[year, month, day] = [+part1, +part2, +part3];
				break;
		}

		// Trigger the onChange callback if all parts of the date are valid.
		if (day && month && year) {
			onChange?.({
				target: { value: { day, month, year } },
			} as any);
		}
	};

	// Close the calendar if a click is detected outside the wrapper.
	useOutsideClick(wrapperRef, () => setShowCalendar(false));

	// Update the input value when the value prop changes.
	useEffect(() => {
		if (value) {
			setInputValue(formatDate({ ...value, format }));
		} else {
			setInputValue('');
		}
	}, [value, format]);

	// Generate the input mask dynamically based on the specified format.
	const inputMask = useMemo(
		() =>
			format.replace('mm', '99').replace('dd', '99').replace('yyyy', '9999'),
		[format]
	);

	return (
		<div ref={wrapperRef}>
			<div
				onClick={() => setShowCalendar(!showCalendar)}
				className="react-date-picker__container"
				style={{ pointerEvents: inputDisabled ? 'none' : 'auto' }}
			>
				{/* Date input field with masking for the specified format */}
				<InputMask
					mask={inputMask}
					placeholder={format.toUpperCase()}
					value={inputValue}
					onChange={handleInputChange}
					className={`react-date-picker ${
						isError ? 'active-error-message' : ''
					}`}
				/>
			</div>

			{/* Calendar dropdown/modal */}
			{showCalendar && (
				<div
					className={isModal ? 'CalendarDropdownModal--container nowheel' : ''}
				>
					<div className="DateCalendar__dropper-wrapper">
						<Calendar
							onChange={handleCalendarChange}
							shouldHighlightWeekends
							{...(minDate && { minimumDate: minDate })} // Apply minimum date constraint.
							{...(maxDate && { maximumDate: maxDate })} // Apply maximum date constraint.
						/>
					</div>
				</div>
			)}
		</div>
	);
};


/**
 * CustomDatePicker Component
 * A wrapper around the DateCalendar component with additional logic for custom usage.
 */
export const CustomDatePicker: React.FC<any> = props => {
	const format = props?.question?.dateFormat || 'mm/dd/yyyy'; // Use the provided or default date format.
	const { survey } = props?.creator || {}; // Survey object from props.

	/**
	 * Handles the change event for the DateCalendar component.
	 * @param e - Change event from the DateCalendar.
	 */
	const handleChange = (e: any) => {
		const { value } = e.target;
		props.question.value = formatDate({ ...value, format }); // Update the question's value.
	};

	// Parses the current date value into DayValue format.
	const getDate = useMemo(() => {
		const value = props?.question?.value;
		if (value) {
			const [part1, part2, part3] = value.split('/').map(Number);
			let day = 0,
				month = 0,
				year = 0;

			switch (format) {
				case 'mm/dd/yyyy':
					[month, day, year] = [part1, part2, part3];
					break;
				case 'dd/mm/yyyy':
					[day, month, year] = [part1, part2, part3];
					break;
				case 'yyyy/mm/dd':
					[year, month, day] = [part1, part2, part3];
					break;
			}

			return { day, month, year };
		}
		return null;
	}, [format, props?.question?.value]);

	// Determines if the input should be read-only based on question properties.
	const isReadOnly = useMemo(
		() =>
			props?.question?.getPropertyValue('readOnly') ||
			props?.question?.isDesignMode ||
			props?.question?.isReadOnly,
		[props.question]
	);

	useEffect(() => {
		if (survey) {
			const onValidateQuestionHandler = (sender: any, options: any) => {
				// Regex for MM/DD/YYYY, YYYY/MM/DD, or DD/MM/YYYY formats
				const regex =
					/^(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])\/\d{4}$|^\d{4}\/(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])$|^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[0-2])\/\d{4}$/;

				// Validate if the value exists and matches the regex
				if (sender && options.value) {
					// First check for the regex match
					if (!regex.test(options.value)) {
						options.error =
							`Please enter a valid date in ${format} format`;
						return;
					}

					// Further check to see if the date is actually valid
					const dateParts = options.value.split(/\/|-|\.|,/); // Handles multiple delimiters if needed
					let isValid = false;

					// Handle each case separately based on format
					if (dateParts.length === 3) {
						let day, month, year;

						switch (format) {
							case 'mm/dd/yyyy':
								month = parseInt(dateParts[0], 10);
								day = parseInt(dateParts[1], 10);
								year = parseInt(dateParts[2], 10);
								break;
							case 'dd/mm/yyyy':
								day = parseInt(dateParts[0], 10);
								month = parseInt(dateParts[1], 10);
								year = parseInt(dateParts[2], 10);
								break;
							case 'yyyy/mm/dd':
								year = parseInt(dateParts[0], 10);
								month = parseInt(dateParts[1], 10);
								day = parseInt(dateParts[2], 10);
								break;
							default:
								month = parseInt(dateParts[0], 10);
								day = parseInt(dateParts[1], 10);
								year = parseInt(dateParts[2], 10);
						}

						// Validate the date using JavaScript's Date object
						const testDate = new Date(year, month - 1, day); // Month is 0-indexed

						// Check if the date is valid (e.g., no 30th February)
						if (
							testDate.getFullYear() === year &&
							testDate.getMonth() === month - 1 &&
							testDate.getDate() === day
						) {
							isValid = true;
						}
					}

					// If the date is not valid, set an error
					if (!isValid) {
						options.error =
							`Please enter a valid date in ${format?.toUpperCase()} format`;
					}
				}
			};

			// Event listener for the validation
			survey.onValidateQuestion.add(onValidateQuestionHandler);

			// Clean up the handler when the component unmounts or dependencies change
			return () => {
				survey.onValidateQuestion.remove(onValidateQuestionHandler);
			};
		}
		return;
	}, [format, survey]);

	return (
		<DateCalendar
			value={getDate}
			onChange={handleChange}
			format={format}
			inputDisabled={props?.question?.isReadOnly || isReadOnly}
		/>
	);
};
