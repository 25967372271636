import type { IConfig, TemplateStatus } from './types';
import type { ISignDocHistoryAPIResponse } from 'views/sign-doc-history';

import { useCallback, useEffect, useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { API_URL, MESSAGE } from 'constant';
import {
	SignDocModalState,
	UploadedTemplateDocsState,
	finalConfiguredAggrementDocsState,
} from 'global-stores';
import {
	useFirebaseInit,
	useFreePlan,
	useMarketing,
	useNetwork,
	useNotification,
} from 'hooks';
import { SignedDocInboxState } from 'views/signed-doc-inbox';
import { useFileUpload } from 'hooks/file-upload';
import { onValue, ref } from 'firebase/database';
import { Memory } from 'hooks/file-upload/constant';

export const useSignedDoc = () => {
	const {
		formData: uploadDoc,
		get,
		remove,
	} = useNetwork({ returnResponse: true });
	const setSignDocState = useSetRecoilState(SignDocModalState);
	const setAddedFiles = useSetRecoilState(UploadedTemplateDocsState);
	const setFinalSelectedDocs = useSetRecoilState(
		finalConfiguredAggrementDocsState
	);

	// Tracks the ID of the uploaded document
	const [uploadedDocId, setUploadedDocId] = useState('');

	// Tracks whether the upload process was successful
	const [uploadSuccess, setUploadSuccess] = useState(false);

	// Destructures the uploadDoc function from the custom useFileUpload hook
	const { uploadDoc: uploadFile } = useFileUpload();

	// Destructures the database instance from the custom useFirebaseInit hook
	const { database } = useFirebaseInit();

	const { errorNotification, successNotification } = useNotification();

	useEffect(() => {
		// Only monitor changes if a document ID is provided
		if (!uploadedDocId) return;

		// Create a reference to the document in the Firebase database
		const dataRef = ref(database, uploadedDocId);

		// Real-time listener for document changes
		const unsubscribe = onValue(dataRef, snapshot => {
			const data = snapshot.val();

			// eslint-disable-next-line no-console
			console.info('Document Update:---------', { uploadedFile: data }); // Debugging info

			// Ignore updates if the timestamp hasn't changed
			if (data?.timeStamp === Memory.getTimeStamp()) return;

			// Update the stored timestamp
			Memory.setTimeStamp(data?.timeStamp);

			// Process the uploaded document data if IDs match
			if (data?._id === uploadedDocId) {
				setUploadedDocId(''); // Reset the uploaded document ID
				setUploadSuccess(true);
				const { name, size, _id, createdAt, error } = data ?? {};
				if (name && !error) {
					setSignDocState(true);
					setTimeout(() => {
						setAddedFiles(prev => [
							...prev,
							{
								_id,
								name,
								size,
								createdAt,
								configured: false,
								isChecked: true,
							},
						]);
						successNotification('File Uploaded.');
						setFinalSelectedDocs(prev => {
							const prevState = JSON.parse(JSON.stringify(prev));
							const selectedFile = {
								_id,
								name,
								size,
								createdAt,
								configured: false,
								isChecked: true,
							};
							const selectedIndex = prevState.findIndex(
								(file: any) => file?._id === selectedFile._id
							);
							let newState: any[] = [];
							if (selectedIndex < 0) {
								newState = [...prevState, selectedFile];
							}
							return newState;
						});
					}, 300);
				} else {
					errorNotification(error ?? MESSAGE.ERROR);
				}
			}
		});

		// Cleanup the listener on component unmount or dependency change
		return () => unsubscribe();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [uploadedDocId, database]);

	const uploadDocs = useCallback(
		async (payload: any): Promise<boolean> => {
			const resp = await uploadDoc(
				`${API_URL.UPLOAD_AGREEMENT_TEMPLATE}`,
				payload
			);
			const { response, apiData } = resp;
			if (response?.status === 200) {
				const { name, size, _id, createdAt } = apiData.data ?? {};
				if (name) {
					setSignDocState(true);
					setTimeout(() => {
						setAddedFiles(prev => [
							...prev,
							{
								_id,
								name,
								size,
								createdAt,
								configured: false,
								isChecked: true,
							},
						]);
						successNotification('File Uploaded.');
						setFinalSelectedDocs(prev => {
							const prevState = JSON.parse(JSON.stringify(prev));
							const selectedFile = {
								_id,
								name,
								size,
								createdAt,
								configured: false,
								isChecked: true,
							};
							const selectedIndex = prevState.findIndex(
								(file: any) => file?._id === selectedFile._id
							);
							let newState: any[] = [];
							if (selectedIndex < 0) {
								newState = [...prevState, selectedFile];
							}
							return newState;
						});
					}, 300);
				}
				return true;
			}
			if (response.status === 400) {
				errorNotification(
					apiData.message ?? 'Unsupported file format selected.'
				);
				return false;
			}
			errorNotification(apiData.message ?? MESSAGE.ERROR_TRY);
			return false;
		},
		[
			errorNotification,
			setAddedFiles,
			setFinalSelectedDocs,
			setSignDocState,
			successNotification,
			uploadDoc,
		]
	);

	const getTemplateStatus = useCallback(
		async (templateId: string): Promise<TemplateStatus> => {
			if (templateId) {
				const resp = await get(`${API_URL.TEMPLATE_STATUS}/${templateId}`);
				const { apiPayload: apiData, response } = resp;
				if (response?.status === 200) {
					return apiData?.data?.status ?? apiData?.status;
				}
			}
			return 'saved';
		},
		[get]
	);

	const deleteEsignTemplate = useCallback(
		(templateId: string) => {
			remove(`${API_URL.SIGN_AGREEMENT}/${templateId}?type=esign`);
		},
		[remove]
	);

	const uploadDocument = useCallback(
		async (file: any) => {
			// Initially, set the upload success flag to false
			setUploadSuccess(false);

			// Call the function to upload the file and wait for the response
			const uploadReps = await uploadFile(file);

			// Destructure the response to extract documentId and success status
			const { documentId, success , message } = uploadReps ?? {};

			// Check if the upload was successful and the documentId is returned
			if (success && documentId) {
				// If successful, set the document ID and return true
				setUploadedDocId(documentId);
				return true; // Return true when the document is uploaded successfully
			} else {
				// If there's an error or no documentId, show an error notification
				errorNotification(message ?? MESSAGE.ERROR);
				return false; // Return false if upload fails
			}
		},
		[errorNotification, uploadFile] // Dependencies: errorNotification and uploadFile functions
	);

	return {
		uploadDocs,
		uploadSuccess,
		uploadDocument,
		getTemplateStatus,
		deleteEsignTemplate,
	};
};

export const useSimpliciSign = () => {
	const [isLoaded, setIsLoaded] = useState(true);
	const { post, patch } = useNetwork();
	const { errorNotification } = useNotification();

	const simpliciSignUseTemplate = useCallback(
		async (payload: any): Promise<IConfig | undefined> => {
			setIsLoaded(false);
			const response = await post(API_URL.ENVELOPE, payload);
			if (response?.prepareUrl) {
				setIsLoaded(true);
				return response;
			}
			errorNotification(MESSAGE.ERROR_TRY);
			setIsLoaded(true);
			return;
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	);

	const updateTemplate = useCallback(
		async (templateId: string, isCopy?: boolean) => {
			setIsLoaded(false);
			let url = `${API_URL.SIGN_AGREEMENT}/${templateId}`;
			if(isCopy === false) {
				url = `${url}?copy=false`
			}
			const resp = await patch(url, {
				source: 'default',
			});
			if (resp?._id) {
				setIsLoaded(true);
				return true;
			}
			return false;
			setIsLoaded(true);
		},
		[patch]
	);

	const updateTemplateName = useCallback(
		async (id: string, payload: { name: string }): Promise<boolean> => {
			setIsLoaded(false);
			const resp = await patch(`${API_URL.SIGN_AGREEMENT}/${id}?copy=false`, payload);
			if (resp?.name) {
				setIsLoaded(true);
				return true;
			} else {
				errorNotification(resp?.message ?? 'Failed to change template');
				setIsLoaded(true);
				return false;
			}
		},
		[errorNotification, patch]
	);

	return {
		simpliciSignUseTemplate,
		updateTemplateName,
		updateTemplate,
		isLoaded,
	};
};

export const useFiveInvitedAt = () => {
	const { trackAttribute } = useMarketing();
	const { hasSubscribedPerson } = useFreePlan();
	const { get } = useNetwork({ updateState: false });
	const { data } = useRecoilValue(SignedDocInboxState);

	const onFirstComplete = async (envelopeId: string) => {
		const resp: ISignDocHistoryAPIResponse = await get(
			`${API_URL.ENVELOPE_HISTORY}?envelopeId=${envelopeId}`
		);
		if (resp?.message === 'ok') {
			const { data = [] } = resp;
			const fistCompleted = data.find(el => el.event === 'COMPLETED');
			trackAttribute({
				first_flow_complete_at_sign: fistCompleted?.createdAt ?? '',
			});
		}
	};

	const onFirstOpen = async (envelopeId: string) => {
		const resp: ISignDocHistoryAPIResponse = await get(
			`${API_URL.ENVELOPE_HISTORY}?envelopeId=${envelopeId}`
		);
		if (resp.message === 'ok') {
			const { data = [] } = resp;
			const fistOpen = data.find(el => el.event === 'OPENED');
			trackAttribute({
				first_flow_start_at_sign: fistOpen?.createdAt ?? '',
			});
		}
	};

	const marketingInit = () => {
		if (data?.length && data.length < 6 && !hasSubscribedPerson) {
			trackAttribute({ freeplan_use_sign: data?.length ?? '' });
			const firstFive = data?.slice(-5);
			(firstFive ?? []).forEach(el => {
				trackAttribute({
					freeplan_first_at_sign: el.createdAt ?? '',
					freeplan_second_at_sign: el.createdAt ?? '',
					freeplan_third_at_sign: el.createdAt ?? '',
					freeplan_fourth_at_sign: el.createdAt ?? '',
					freeplan_last_at_sign: el.createdAt ?? '',
				});
			});
			const firstInvite = data?.at(-1);
			if (firstInvite)
				trackAttribute({
					first_invite_sent_at_sign: firstInvite.createdAt ?? '',
				});
			(firstFive ?? []).forEach(el => {
				if (el.status === 'completed') {
					onFirstComplete(el?._id ?? '');
					return;
				}
			});
			(firstFive ?? []).forEach(el => {
				if (el.status === 'configured') {
					onFirstOpen(el?._id ?? '');
					return;
				}
			});
		}
	};
	return {
		marketingInit,
	};
};
