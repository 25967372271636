import { FC, useState } from 'react';
import { KybInfoHeader } from 'views';
import './aml-view-more-hoc.scss';

interface IProps {
	Component: any;
	componentProp: any;
	heading: string;
	isdefaultShowAllinfo?: boolean;
	showViewMoreHeading?: boolean;
	isLineBetween?: boolean
}

export const AmlWatcherViewMoreHOC: FC<IProps> = ({
	Component,
	componentProp,
	heading,
	isdefaultShowAllinfo,
	showViewMoreHeading,
	isLineBetween
}) => {
	const [showMore, setShowMore] = useState(isdefaultShowAllinfo ?? false);
	const toggleShowMore = () => setShowMore(prev => !prev);
	const displayedItems = Array.isArray(componentProp)
		? showMore
			? componentProp ?? []
			: componentProp?.slice(0, 4) ?? []
		: Object.entries(componentProp || {})
				.map(([key, value]) => ({ key, value }))
				.slice(0, showMore ? Object.keys(componentProp ?? {})?.length : 4);

	if (displayedItems?.length === 0) {
		return <></>;
	}

	return (
		<div className="view-more__hoc--wrapper">
			{showViewMoreHeading ? (
				<KybInfoHeader type={heading} />
			) : (
				<div className="aml-view-more-heading">{heading}</div>
			)}
			<div className="enchanced-view-more-wrapper">
				{!isdefaultShowAllinfo && (displayedItems ?? []).length >= 4 && (
					<button className="view-more__buttons" onClick={toggleShowMore}>
						{showMore ? 'View Less' : 'View More'}
					</button>
				)}
			</div>
			<div className="view-more__components--wrapper">
				<Component
					items={displayedItems}
					isDataModified={Array.isArray(componentProp)}
					isLineBetween={isLineBetween}
				/>
			</div>
		</div>
	);
};
