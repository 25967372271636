export type IPermissionScreen =
	| 'Onboarding'
	| 'Funds'
	| 'Sessions'
	| 'SignDashboard'
	| 'Templates'
	| 'Inbox'
	| 'Sent'
	| 'Signed Document Templates'
	| 'GeneralSettings'
	| 'DevelopersKeys'
	| 'Developer Keys'
	| 'User Roles'
	| 'UserRoles'
	| 'Billing'
	| 'CheckDefense'
	| 'esign Templates'
	| 'Draft'
	;

export const PERMISSION_SCREEN: { [key in IPermissionScreen]: string } = {
	Onboarding: 'onboarding',
	Funds: 'funds',
	Sessions: 'session',
	SignDashboard: 'signDashboard',
	Templates: 'template',
	'Signed Document Templates': 'template',
	'esign Templates': 'template',
	Inbox: 'inbox',
	Sent: 'sent',
	GeneralSettings: 'generalSettings',
	DevelopersKeys: 'developersKeys',
	'Developer Keys': 'developersKeys',
	'User Roles': 'userRoles',
	UserRoles: 'userRoles',
	Billing: 'billing',
	CheckDefense: 'checkDefense',
	Draft: 'draft'
};
