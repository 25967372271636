export const KYB_INFO_DETAILS: { [key: string]: { [key: string]: string }[] } =
	{
		businessInfo: [
			{
				key: 'CompanyName',
				label: 'Company name',
			},
			{
				key: 'websites',
				label: 'Website URL',
			},
			{
				key: 'linkedInUrl',
				label: 'LinkedIn URL',
			},
			{
				key: 'StreetAddress1',
				label: 'Street address',
			},
			{
				key: 'City',
				label: 'City',
			},
			{
				key: 'State',
				label: 'State',
			},
			// {
			// 	key: 'Country',
			// 	label: 'Country',
			// },
			{
				key: 'Zip5',
				label: 'ZIP code',
			},
			{
				key: 'Phone',
				label: 'Phone',
			},
			{
				key: 'FEIN',
				label: 'EIN',
			},
		],
		representativeInfo: [
			{
				key: 'First',
				label: 'First name',
			},
			{
				key: 'Last',
				label: 'Last name',
			},
			{
				key: 'DOB',
				label: 'Date of birth',
			},
			// {
			// 	key: 'StreetNumber',
			// 	label: 'Street No.',
			// },
			// {
			// 	key: 'StreetName',
			// 	label: 'Street Name',
			// },
			// {
			// 	key: 'StreetSuffix',
			// 	label: 'Street Suffix',
			// },
			// {
			// 	key: 'City',
			// 	label: 'City',
			// },
			// {
			// 	key: 'State',
			// 	label: 'State',
			// },
			// {
			// 	key: 'County',
			// 	label: 'Country',
			// },
			// {
			// 	key: 'Zip5',
			// 	label: 'ZIP code',
			// },
			{
				key: 'Phone',
				label: 'Phone',
			},
			{
				key: 'SSN',
				label: 'SSN',
			},
			// {
			// 	key: 'document',
			// 	label: 'Driver license/Passport number',
			// },
		],
		Compliance: [
			{
				key: 'SOSFilingName',
				label: 'Filing name at SOS',
			},
			{
				key: 'TimeOnSOS',
				label: 'Time on SOS',
			},
			{
				key: 'SOSStatus',
				label: 'SOS Status',
			},
			{
				key: 'SICCode',
				label: 'SIC Code',
			},
			// Arun kumar : Not getting from lexis nexis
			// {
			// 	key: 'NAICScode',
			// 	label: 'NAICS code',
			// },
			// { key: 'NAICSdescription', label: 'NAICS description' },
			{
				key: 'BusinessFirstSeenYYYY',
				label: 'Business first seen',
			},
			{
				key: 'TimeOnPublicRecord',
				label: 'Time On Public Record',
			},

			{
				key: 'SICDescription',
				label: 'Business description',
			},
			{
				key: 'County',
				label: ' Buisness County',
			},
			// Arun kumar : Not getting from lexis nexis
			// {
			// 	key: 'Parentcompany',
			// 	label: ' Parent Company',
			// },

			{
				key: 'LNStatus',
				label: 'LN Status',
			},
		],
	};

export const KYB_INFORMATION_DETAILS: {
	[key: string]: { [key: string]: string }[];
} = {
	location: [
		// Might For Future Use
		// {
		// 	key: 'registrationNumber',
		// 	label: 'Registration number',
		// },
		{
			key: 'registrationDate',
			label: 'Registration date',
		},
		{
			key: 'address',
			label: 'Address',
		},
		// Might For Future Use
		// {
		// 	key: 'city',
		// 	label: 'City',
		// },
		// {
		// 	key: 'state',
		// 	label: 'State',
		// },
		// {
		// 	key: 'country',
		// 	label: 'Country',
		// },
		// {
		// 	key: 'zipCode',
		// 	label: 'ZIP code',
		// },
		{
			key: 'type',
			label: 'Type of address',
		},
		{
			key: 'tin',
			label: 'TIN / Registration number ',
		},
		{
			key: 'fein',
			label: 'TIN',
		},
		{
			key: 'countryOfFormation',
			label: 'Country of Formation',
		},
		{
			key: 'dateOfFormation',
			label: 'Date of Formation',
		},
		{
			key: 'stateOfFormation',
			label: 'State of Formation',
		},

		{
			key: 'errorMessages',
			label: 'Rejection Reason',
		},
	],
	contactDetails: [
		{
			key: 'phone',
			label: 'Telephone number',
		},
		{
			key: 'website',
			label: 'Website link',
		},
		{
			key: 'domain',
			label: 'Domain',
		},
	],
	// extraInfo: [
	// 	{
	// 		key: 'jurisdictionCode',
	// 		label: 'Jurisdiction code',
	// 	},
	// 	{
	// 		key: 'incorporationDate',
	// 		label: 'Incorporation date',
	// 	},
	// 	{
	// 		key: 'taxNumber',
	// 		label: 'Tax number',
	// 	},
	// 	{
	// 		key: 'filingYear',
	// 		label: 'Filing year',
	// 	},
	// ],

	authorizedRepresentative: [
		{
			key: 'name',
			label: 'Name',
		},
		{
			key: 'designation',
			label: 'Designation/Position',
		},
		{
			key: 'phone',
			label: 'Mobile Number',
		},
		{
			key: 'email',
			label: 'Email',
		},
		{
			key: 'dateOfBirth',
			label: 'Date Of Birth',
		},
		{
			key: 'nationalIdNumber',
			label: 'SSN',
		},
		{
			key: 'relatesToBusiness',
			label: 'Related to business',
		},
	],
	compliance: [
		{
			key: 'SICCode',
			label: 'SICCode',
		},
		{
			key: 'SICDescription',
			label: 'SICDescription',
		},
		{
			key: 'NAICSCode',
			label: 'NAICSCode',
		},
		{
			key: 'NAICSDescription',
			label: 'NAICSDescription',
		},
		{
			key: 'SOSFilingName',
			label: 'SOSFilingName',
		},
		{
			key: 'TimeOnSOS',
			label: 'TimeOnSOS',
		},
		{
			key: 'SOSStatus',
			label: 'SOSStatus',
		},
		{
			key: 'LNStatus',
			label: 'LNStatus',
		},
		{
			key: 'TimeOnPublicRecord',
			label: 'TimeOnPublicRecord',
		},
		{
			key: 'County',
			label: 'County',
		},
		{
			key: 'BusinessFirstSeenYYYY',
			label: 'BusinessFirstSeenYYYY',
		},
	],
};

export const KYB_INFO_HEADER: { [key: string]: string } = {
	location: 'Basic Informations',
	contactDetails: 'Contact Informations',
	extraInfo: 'Other Informations',
	authorizedRepresentative: 'Authorised Representatives',
	financialDetails: 'Financial Details',
	compliance: 'Compliance Informations',
	similarCompanies: 'SimilarCompanies',
};

export const KYB_INFO_HEADER_KEY: string[] = [
	'location',
	'contactDetails',
	'authorizedRepresentative',
	'compliance',
];

// Arun kumar : This is will run when supplimntal will off
export const KYB_INFO_HEADER_KEY_WITHOUT_COMPLIANCE: string[] = [
	'businessInfo',
];

export const KYB_BUSINESS_VERIFICATION = [
	{
		key: 'BUREAU',
		label: 'Bureau verification',
	},
	{
		key: 'GOVTREGISTRATIONS',
		label: 'Government registrations',
	},
	{
		key: 'PUBLICRECORDFILINGS',
		label: 'Public record filling',
	},
	{
		key: 'BUSDIRECTORIES',
		label: 'Business directory verification',
	},
	{
		key: 'PHONESOURCE',
		label: 'Phone source',
	},
];

export const KYB_BUSINESS_WATCHLIST = [
	{
		key: 'watchlist_name',
		label: 'Watchlist name',
	},
	{
		key: 'watchlist_record_number',
		label: 'Watchlist record number',
	},
	{
		key: 'company_name',
		label: 'Company name',
	},
];

export const KYB_REVENUE_GROWTH = [
	{
		key: 'card_revenue',
		label: 'Annual revenue',
	},
	{
		key: 'card_transactions',
		label: 'Annual transactions',
	},
	{
		key: 'Average ticket size',
		label: 'Average ticket size',
	},
	{
		key: 'card_customers',
		label: 'Average daily customers',
	},
	{
		key: 'card_revenue_growth',
		label: 'Annual revenue growth',
	},
];

export const transactionToggleHeader = [
	{
		name: 'Daily',
		key: '1m',
	},
	{
		name: 'Weekly',
		key: '3m',
	},
	{
		name: 'Monthly',
		key: '12m',
	},
];

export const revenueToggleHeader = [
	{
		name: 'Monthly',
		key: 'Monthly',
	},
	{
		name: 'Trailing 12 months',
		key: 'Trailing 12 month (TTM)',
	},
];

// KYB Comprohensive Details
export const KYB_TYPE_TABS: { [key: string]: string } = {
	'kyb-enhanced': 'Enhanced',
	'kyb-comprehensive': 'Comprehensive',
	'kyb-negative-filing': 'Negative Filling',
	'dun-brad-street-section': 'Dun Brad Street Section',
	'business-sanctions-ofac': 'Business Sanctions/OFAC',
};

export const KYB_ICONS: { [key: string]: string } = {
	individual: 'ri-user-fill',
	industry: 'ri-building-fill',
	agency: 'ri-bank-fill',
	riquestionmark: 'ri-information-fill',
	'property-info': 'ri-community-fill',
	'motor-vehicle': 'ri-car-fill',
	'water-craft-vehicle': 'ri-ship-2-fill',
	'air-craft-vehicle': 'ri-plane-fill',
	company: 'ri-building-fill',
	fileError: 'ri-file-damage-line',
};

export const KYB_OFAC_CARDS_KEYS: any = {
	entityId: 'Entity Id',
	riskScore: 'Risk score',
	name: 'Name',
	date: 'Date',
	ForeignID: 'Foreign ID',
	OriginalID: 'Original ID',
	OtherInformation: 'Other information',
	aliases: 'Aliases',
	Legal_Basis: 'Legal basis',
	Gender: 'Gender',
	titles: 'Titles',
	pob: 'Place of Birth',
	Org_PID: 'Org PID',
	nativeCharName: 'Native character names',
	dob: 'DOB',
	passport: 'Passport',
	SubCategory: 'Sub category',
	EntityLevel: 'Entity level',
	addresses: 'Addresses',
	totalSubCases: 'Total Subcases',
	totalMatches: 'Total Matches',
	caseId: 'Case ID',
	DirectID: 'Direct ID',
	SourceLink: 'Source link',
	programs: 'Programs',
	OriginalSource: 'Original Source',
};

export const AUTHORIZED_REPRESENTATIVE_LABEL: Record<string, string> = {
	'status': "Status",
    "designation": "Designation/Position",
    "phone": "Mobile Number",
    "email": "Email",
    "dateOfBirth": "Date Of Birth",
    "nationalIdNumber": "SSN",
    "relatesToBusiness": "Related to business",
	"type":"Reprentative Type",
	"ownershipPercentage":"Ownership Percentage"
}

export const PSB_OWNERSHIP_TYPE :  Record <string, string> = {
	'owner' :"Owner",
	'signer' : "Signer",
	"ownerAndSigner":"Owner & Signer",
	"NA" :"N/A"
}
