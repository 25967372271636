import { Fragment } from 'react';
import { Image } from '@storybook/image';

import {
	Accrediation,
	EmailVerification,
	EmptyScreen,
	IndentityVerification,
	KYCVerification,
	Success,
	TextMessageVerification,
	FundVerifyScreen,
	SignMobileUI,
} from './components';
import { IMAGE_GCP_URL } from 'constant';
import { atom } from 'recoil';

const facialImg = `${IMAGE_GCP_URL}/faceverification.svg`;
const nextIcon = `${IMAGE_GCP_URL}/next.svg`;
const carIcon = `${IMAGE_GCP_URL}/car.svg`;
const globeIcon = `${IMAGE_GCP_URL}/globe.svg`;
const dlCardImage = `${IMAGE_GCP_URL}/dl-blank-card.svg`;
const nationalIDCardImg = `${IMAGE_GCP_URL}/national-id-blank-card.svg`;
const passportCardImg = `${IMAGE_GCP_URL}/passport-blank-card.svg`;

export const IndentityVerificationObject = [
	{
		_id: 1,
		name: 'Driver’s License',
		leftIcon: carIcon,
		rightIcon: nextIcon,
	},
	{
		_id: 1,
		name: 'Passport',
		leftIcon: globeIcon,
		rightIcon: nextIcon,
	},
];

export const pipelineMobileScreen: any = {
	driverLicense: {
		header: <Image fileName="blue_header.svg" width="100%" />,
		body: <KYCVerification bodyName="driverLicenseVerificationSteps" />,
		footer: <Image fileName="" width="100%" />,
	},
	passport: {
		header: <Image fileName="blue_header.svg" width="100%" />,
		body: <KYCVerification bodyName="passportVerificationSteps" />,
		footer: <Image fileName="footer.svg" width="100%" />,
	},
	allId: {
		header: <Image fileName="blue_header.svg" width="100%" />,
		body: <IndentityVerification />,
		footer: <Image fileName="footer.svg" width="100%" />,
	},
	nationalId: {
		header: <Image fileName="blue_header.svg" width="100%" />,
		body: <KYCVerification bodyName="nationalIdCardVerificationSteps" />,
		footer: <Image fileName="footer.svg" width="100%" />,
	},
	successScreenCompletion: {
		header: <Image fileName="blue_header.svg" width="100%" />,
		body: <Success />,
		footer: <Image fileName="footer.svg" width="100%" />,
	},
	amlVerification: {
		body: <Image fileName="AML.svg" width="100%" />,
		footer: <Image fileName="footer.svg" width="100%" />,
	},
	deliveryMethodMessage: {
		header: <Image fileName="phone-header.svg" width="100%" />,
		body: <TextMessageVerification />,
		footer: <Image fileName="footer.svg" width="100%" />,
	},
	deliveryMethodEmail: {
		header: (
			<Fragment>
				<Image fileName="light_header.svg" width="100%" />
				<Image
					className="mobile-email-header"
					fileName="email_header.svg"
					width="100%"
				/>
			</Fragment>
		),
		body: <EmailVerification />,
		footer: <Image fileName="footer.svg" width="100%" />,
	},
	fundInvestmentVerification: {
		// header: <Image fileName="fund-verify-imageheader.svg" width="100%" />,
		body: <FundVerifyScreen />,
		footer: <Image fileName="footer.svg" width="100%" />,
	},
	emptyScreen: {
		header: <Image fileName="" width="100%" />,
		body: <EmptyScreen />,
		footer: <Image fileName="footer.svg" width="100%" />,
	},
	accreditationVerify: {
		header: <Image fileName="iphone-header.svg" width="100%" />,
		body: <Accrediation />,
		footer: <Image fileName="footer.svg" width="100%" />,
	},
	kybVerification: {
		header: <Image fileName="iphone-header.svg" width="100%" />,
		body: <Image fileName="kyb.png" width="100%" />,
		footer: <Image fileName="footer.svg" width="100%" />,
	},
	signAgreementVerification: {
		body: <SignMobileUI />,
		footer: <Image fileName="footer.svg" width="100%" />,
	},
	authentication: {
		body: <Image url={facialImg} width="100%" />,
	},
};

export const pipelineMobileScreenBodySettings: any = {
	driverLicenseVerificationSteps: {
		step1: {
			label: 'Driver’s License Verification',
			title: "Scan front and back of your driver's License ",
			subtitle:
				'Use a well lit area and make sure the details are clear and unobstructed.',

			settings: [
				{
					step: 'step2',
					label: 'Start Verification',
					className:
						'button__filled button__filled--primary button__large button__block',
					isDisabled: false,
				},
			],
		},
		step2: {
			label: 'Driver’s License Verification',
			title: "Scan front of your driver's License ",
			subtitle:
				'Use a well lit area and make sure the details are clear and unobstructed.',
			settings: [
				{
					step: 'step3',
					label: 'Next',
					className:
						'button__filled button__filled--primary button__large button__block',
					isDisabled: true,
				},
				{
					step: 'step1',
					label: 'Back',
					className:
						'button__filled button__filled--secondary button__large button__block',
					isDisabled: false,
				},
			],
		},
		step3: {
			label: 'Driver’s License Verification',
			title: "Scan back of your driver's License ",
			subtitle:
				'Use a well lit area and make sure the details are clear and unobstructed.',

			settings: [
				{
					step: 'Submit',
					label: 'Submit',
					className:
						'button__filled button__filled--primary button__large button__block',
					isDisabled: true,
				},
				{
					step: 'step2',
					label: 'Back',
					className:
						'button__filled button__filled--secondary button__large button__block',
					isDisabled: false,
				},
			],
		},
		blankImage: dlCardImage, //always keep blankImage in last
	},
	passportVerificationSteps: {
		step1: {
			label: 'Passport Verification',
			title: 'Scan data page of your passport ',
			subtitle:
				'Use a well lit area and make sure the details are clear and unobstructed.',
			settings: [
				{
					step: 'step2',
					label: 'Start Verification',
					className:
						'button__filled button__filled--primary button__large button__block',
					isDisabled: false,
				},
			],
		},
		step2: {
			label: 'Passport Verification',
			title: 'Scan data page of your passport',
			subtitle:
				'Use a well lit area and make sure the details are clear and unobstructed.',
			settings: [
				{
					step: 'step2',
					label: 'Start Verification',
					className:
						'button__filled button__filled--primary button__large button__block',
					isDisabled: false,
				},
			],
		},
		step3: {
			label: 'Passport Verification',
			title: 'Scan front of your passport ',
			subtitle:
				'Use a well lit area and make sure the details are clear and unobstructed.',
			settings: [{ prevStep: null, nextStep: 'step2' }],
		},
		blankImage: passportCardImg, //always keep blankImage in last
	},
	nationalIdCardVerificationSteps: {
		step1: {
			label: 'National Photo ID Verfication',
			title: 'Scan front and back of your National photo ID ',
			subtitle:
				'Use a well lit area and make sure the details are clear and unobstructed.',
			settings: [
				{
					step: 'step2',
					label: 'Start Verification',
					className:
						'button__filled button__filled--primary button__large button__block',
					isDisabled: false,
				},
			],
		},
		step2: {
			label: 'National Photo ID Verfication',
			title: 'Scan front of your national photo ID ',
			subtitle:
				'Use a well lit area and make sure the details are clear and unobstructed.',
			settings: [{ prevStep: null, nextStep: 'step2' }],
		},
		step3: {
			label: 'Passport Verification',
			title: 'Scan front of your passport ',
			subtitle:
				'Use a well lit area and make sure the details are clear and unobstructed.',
			settings: [{ prevStep: null, nextStep: 'step2' }],
		},
		blankImage: nationalIDCardImg, //always keep blankImage in last
	},
};

export const accrediationObject = [
	{
		title: 'No password',
		description: 'We do not store or share any passwords or login credential',
		icon: 'ri-lock-2-fill',
	},
	{
		title: 'Strong Privacy',
		description:
			'We do not share any data with third parties without your consents',
		icon: 'ri-eye-off-fill',
	},
	{
		title: 'Secure',
		description:
			'Your data stays fully encrypted using highest industry standard',
		icon: 'ri-shield-check-fill',
	},
];

export const selectAccreditation = [
	{
		icon: 'ri-user-3-fill icon-individual',
		title: 'Individual',
		desc: 'Myself as an',
	},
	{
		icon: 'ri-briefcase-fill icon-entity',
		title: 'Entity',
		desc: 'Business or trust as an',
	},
];

export const ComplexMetaDataId = atom<string>({
	key: 'complex-meta-mata-id',
	default: '',
});

export const getImage: { [key: string]: string } = {
	payIn: 'fund_account_02.svg',
	payOut: 'receive_fund_02.svg',
	payInUnitPricing: 'unit_pricing.svg',
};
