import type { PaymentAddress } from 'views/user-identity-flow/store';
import type { PaymentAddressError } from 'views/user-identity-flow/hooks';

import { FC } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { AutoFillLocation, Input, ReactDropdown } from '@storybook';
import { useCountryStateCity } from 'hooks';
import CountryJson from 'views/pipelines/components/address-details/countries.json';
import { BillingAddressFrom, BillingAddressFromState } from 'views/settings';
import { IndetityFlowNavigate } from 'views/user-identity-flow/constants';
import {
	BillingPaymentAddress,
	SubscriptionIdentityNaviagtion,
} from 'views/user-identity-flow/store';
import './payment-address.scss';

interface IBillingAddress {
	address: PaymentAddress;
	addressError: PaymentAddressError;
	updateAddress: (name: string, value: string) => void;
	label: string;
}

export const PaymentAddressDetails: FC<IBillingAddress> = ({
	address,
	updateAddress,
	addressError,
	label,
}) => {
	const setBillingAddress = useSetRecoilState(BillingPaymentAddress);
	const billingAddressFrom = useRecoilValue(BillingAddressFromState);
	const navigate = useRecoilValue(SubscriptionIdentityNaviagtion);
	const {
		address1 = '',
		address2 = '',
		country = '',
		state = '',
		zip = '',
		city = '',
	} = address ?? {};
	const {
		address1: address1Error,
		zip: zipError,
		address2: address2Error,
	} = addressError;

	const { CityOptions, StateOptions } = useCountryStateCity({
		country,
		formState: state,
	});

	const handleAutoInputValue = (data: any) => {
		setBillingAddress((prevState: any) => {
			const newState = {
				...prevState,
				address1: data?.enteredAddress ?? '',
				country: data?.country ?? '',
				state: data?.state ?? '',
				zip: data?.postal_code ?? '',
				city: data?.city ?? '',
			};
			return newState;
		});
	};

	const classNameForDropdown = () => {
		return billingAddressFrom === BillingAddressFrom.FromBillingInfo ||
			navigate === IndetityFlowNavigate.BillingForm
			? ''
			: 'react-dropdown';
	};

	return (
		<div className="business__form">
			<div className="full__span">
				<AutoFillLocation
					label={label}
					placeHolder={'Street Address'}
					isRequired={true}
					handleOnChange={e => updateAddress('address1', e.target.value)}
					value={address1}
					isError={address1Error}
					isManualSearch
					onSuccess={handleAutoInputValue}
					errorMessage="Please enter a valid address"
				/>
			</div>
			<div className="full__span">
				<Input
					label="House/Apt./Suite"
					inputType="text"
					placeholder="Apartment, Suite, etc."
					handleChange={e => updateAddress('address2', e.target.value)}
					value={address2}
					maxLength={30}
					isError={address2Error}
				/>
			</div>

			<ReactDropdown
				label="Country"
				options={CountryJson}
				isSearchable
				placeholder="Select"
				handleChangeSelect={e => updateAddress('country', e.value)}
				value={country === '' ? '' : { label: country, value: country }}
				isRequired
			/>
			<Input
				label="Zip Code"
				inputType="text"
				placeholder="Zip Code"
				handleChange={e => {
					e.target.value = e.target.value.trim();
					if (e.target.value.length <= 10) {
						updateAddress('zip', e.target.value);
					}
				}}
				value={zip}
				isError={zipError || (zip.length > 0 && zip.length < 4)}
				onKeyDown={(e: any) =>
					['e', 'E', '+', '-', '.'].includes(e.key) && e.preventDefault()
				}
				isRequired
				errorMessage={
					zip.length > 0 && zip.length < 4
						? 'Zip code must be contain at least 4 digits'
						: ''
				}
			/>
			<div className={classNameForDropdown()}>
				<ReactDropdown
					label="State"
					handleChangeSelect={e => updateAddress('state', e.value)}
					isSearchable
					value={state === '' ? '' : { label: state, value: state }}
					options={StateOptions || []}
					optionsDropHeight={120}
					isRequired
				/>
			</div>

			<div className={classNameForDropdown()}>
				<ReactDropdown
					label="City"
					handleChangeSelect={e => updateAddress('city', e.value)}
					options={CityOptions || []}
					value={city === '' ? '' : { label: city, value: city }}
					optionsDropHeight={120}
					isSearchable
					isRequired
				/>
			</div>
		</div>
	);
};
