export const SessionTable = {
	HEADER: [
		{
			label: 'All',
			key: 'checkbox',
			format: 'jsx',
			width: '16px',
			className: 'fund_name',
		},
		{
			label: 'Onboarding Details',
			fundKey:'fundName',
			nameKey:'name',
			emailKey:'email',
			contactKey:'contact',
			key:'date',
			format: 'jsx',
			width: 'calc(30% - 16px)',
			className: 'onbording_details',
		},
		//Paras : created new session list for new graph. so temporary need this code for backup
		// {
		// 	label: 'Fund/Company',
		// 	key: 'fundName',
		// 	format: 'jsx',
		// 	//@avinash: need to remove 8px for checkbox
		// 	width: 'calc(15% - 8px)',
		// 	className: 'fund_name',
		// },
		// {
		// 	label: 'Name',
		// 	key: 'name',
		// 	format: 'string',
		// 	width: '9%',
		// },
		// {
		// 	label: 'Email & Contact',
		// 	key: 'email',
		// 	key2: 'contact',
		// 	format: 'jsx',
		// 	//@avinash: need to remove 8px for checkbox
		// 	width: 'calc(10% - 8px)',
		// 	className: 'email__contact',
		// },
		// {
		// 	label: 'Date & Time',
		// 	key: 'date',
		// 	key2: 'date',
		// 	format: 'jsx',
		// 	width: '9%',
		// },
		{
			label: 'Status & Onboarding Flow',
			key: 'fullStatus',
			format: 'jsx',
			key2: 'onBoardingName',
			width: '63%',
		},
		{
			label: 'Actions',
			key: 'action',
			format: 'jsx',
			className: 'action__btn-Review',
			width: '7%',
		},
	],
	COMPLEX_HEADER: [
		{
			label: 'All',
			key: 'checkbox',
			format: 'jsx',
			width: '16px',
			className: 'fund_name',
		},
		{
			label: 'Fund/Company',
			key: 'fundName',
			format: 'string',
			width: '15%',
			className: 'fund_name',
		},
		{
			label: 'Name',
			key: 'name',
			format: 'string',
			width: '20%',
		},
		{
			label: 'Email & Contact',
			key: 'email',
			key2: 'contact',
			format: 'jsx',
			width: '15%',
			className: 'email__contact',
		},
		{
			label: 'Date & Time',
			key: 'date',
			key2: 'date',
			format: 'jsx',
			width: '10%',
		},
		{
			label: 'Steps In Flow ',
			key: 'nodes',
			format: 'jsx',
			key2: 'onBoardingName',
			width: '30%',
		},
		{
			label: 'Actions',
			key: 'action',
			format: 'jsx',
			className: 'action__btn-Review',
			width: '10%',
		},
	],
};

export const Actions: any[] = [
	{
		label: 'Approved',
		value: 'completed',
		color: 'var(--color-green)',
		isVisible:true,
	},
	{
		label: 'Under Review',
		value: 'processing',
		color: 'var(--color-orange)',
		isVisible:true,
	},
	{
		label: 'Rejected',
		value: 'rejected',
		color: '#F55353',
		isVisible:true,
	},
	{
		label: 'On Hold',
		value: 'onhold',
		color: 'var(--color-orange)',
		isVisible:false,
	}
];

export const GraphColorLegendsStep = [
	{
		color: 'var(--color-gray-2)',
		label: 'Not Started',
	},
	{
		color: 'var(--color-blue-dark-200)',
		label: 'Started',
	},
	{
		color: 'var(--color-orange)',
		label: 'Under Review',
	},
	{
		color: 'var(--color-green)',
		label: 'Approved',
	},
	{
		color: 'var(--color-danger)',
		label: 'Rejected',
	},
];

export const LabelDataActivity: { [key: string]: string } = {
	kyc: 'KYC',
	fundInvestment: 'Fund Investment',
	accreditation: 'Accreditation',
	'506c': 'Accred.(506c)',
	'506b': 'Accred.(506b)',
	aml: 'AML',
	signAgreement: 'Sign Agreement',
	amlVerification: 'AML',
	payIn: 'Fund (Pay In)',
	payOut: 'Fund (Pay Out)',
	kyb: 'KYB',
	signAgreement_esign: 'Sign Doc.',
	signAgreement_docusign: 'Sign Doc.',
	form: 'Form',
	dynamicForm: 'Questionnaire',
	kybForm: 'KYB Document',
};

export const sessionMessage = {
	AddCcMessage: 'Please add the email in CC',
	AddBCcMessage: 'Please add the email in BCC',
};

export const FileTypes: any = {
	JPG: true,
	JPEG: true,
	PNG: true,
	DOC: true,
	DOCX: true,
	PDF: true,
	GIF: true,
	CSV: true,
	TXT: true,
	XSL: true,
};

export const SessionTypesQuery = {
	Archived_Sessions: 'archived_session',
};

export const SessionStepStatus = {
	COMPLETED: 'COMPLETED',
};

export const DownloadActions = [
	{
		label: 'Download as PDF',
		value: 'pdf',
	},
	{
		label: 'Download as CSV',
		value: 'csv',
	},
];

export const Signatory: { [key: string]: boolean } = {
	'Registered Broker Dealer': true,
	'Licensed Attorney': true,
	'Registered Investment Advisor': true,
	'Certified Public Accountant': true,
};
