import classNames from 'classnames';
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import { Input, Loader } from '@storybook';
import {
	filteringTagsState,
	IComplexPipeline,
	IFunds,
	isAssigneeCheckLoadingState,
	pipelinesFiltersState,
	selectedReviewersState,
	userDataState,
} from 'global-stores';
import { useNetwork, useOutsideClick } from 'hooks';
import { Popup } from '../popup';
import { TextFilter, useSessionsTextFilter } from '../text-filter';

import { AssignSelectedSessionState, LabelDataActivity } from 'views/sessions';
import '../popup/popup.scss';
import './filter.scss';
import { API_URL } from 'constant';
import { debounce } from 'utils/debounce';
import ReviewerDropdown, { Assignee, IReviewer } from 'views/sessions/components/assign-dropdown/ReviewerDropdown';
import { useSessionAssign } from 'views/sessions/hooks/use-session-assign';
import { AssigneeSessionState } from 'views/sessions/store';

const actions = [
	{ onboardingFlowName: 'KYC', value: 'kyc' },
	{ onboardingFlowName: 'AML', value: 'aml' },
	{ onboardingFlowName: 'KYB', value: 'kyb' },
	{ onboardingFlowName: 'Accred.(506b)', value: '506b' },
	{ onboardingFlowName: 'Accred.(506c)', value: '506c' },
	{ onboardingFlowName: 'Fund (Pay In)', value: 'payIn' },
	{ onboardingFlowName: 'Fund (Pay Out)', value: 'payOut' },
	{ onboardingFlowName: 'Sign Doc', value: 'signAgreement' },
	{
		onboardingFlowName: 'Form/Individual or Business Questionnaire.',
		value: 'form',
	},
	{ onboardingFlowName: 'Questionnaire', value: 'dynamicForm' },
	{ onboardingFlowName: 'KYB Document', value: 'kybForm' },
];

export const FilterPopup: FC<any> = ({ filterPage }) => {
	const [isOnboardingOpen, setOnboardingOpen] = useState(false);
	const [isActiveStatusOpen, setActiveStatusOpen] = useState(false);
	const [isFundsOpen, setFundsOpen] = useState(false);
	const [isAssignedReviewerOpen, setAssignedReviewerOpen] = useState(false);
	const [filteringTags, setFilteringTags] = useRecoilState(filteringTagsState);
	const [pipelinesFilter, setPipelinesFilter] = useRecoilState(
		pipelinesFiltersState
	);

	const [searchedFunds, setSearchedFunds] = useState<string>('');
	const [searchComplex, setSearchComplex] = useState<string>('');
	const [checkedFunds, setCheckedFunds] = useState<IFunds[]>([]);
	const [checkedOnboarding, setCheckedOnboarding] = useState<
		IComplexPipeline[]
	>([]);

	const [isFilterLoading, setFilterLoading] = useState<{
		[key: string]: boolean;
	}>({});
	const [isResetting, setIsResetting] = useState(false);
	const setSelectedSession = useSetRecoilState(AssignSelectedSessionState);

	// hooks
	const { resetSessions, resetingSessionsFilter } = useSessionsTextFilter();
	const {
		get: getFundsList,
		data: fundsListApi,
		loading: isFundsLoading,
	} = useNetwork();

	const {
		get: getComplexList,
		data: complexFlowList,
		loading: isComplexLoading,
	} = useNetwork();
	const setIsAssigneeCheckLoading= useSetRecoilState(isAssigneeCheckLoadingState);
	const { AssignReviewer } = useSessionAssign();
    const sessionAssignee = useRecoilValue(AssigneeSessionState);
	const [searchTerm, setSearchTerm] = useState('');
	const  [selectedReviewers,setSelectedReviewers] = useRecoilState(selectedReviewersState);
	const loginStateData = useRecoilValue(userDataState);

	useEffect(() => {
		AssignReviewer();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const filteredData = useMemo(
		() =>
			sessionAssignee?.filter(
				(currentUserAssignee: { _id: string }) =>
					currentUserAssignee._id !== loginStateData.id
			),
		[sessionAssignee, loginStateData.id]
	);

	const reviewers = useMemo(
		() =>
			filteredData.map((assignee: Assignee) => ({
				id: `${assignee?._id}`,
				name: `${assignee.firstName} ${assignee.lastName}`,
				image: `${assignee.image}`,
			})),
		[filteredData]
	);

	const handleReviewerSelect = useCallback(
		(item: IReviewer) => {
			
				setIsAssigneeCheckLoading(item.id);
				setTimeout(() => {
					setIsAssigneeCheckLoading(null);
				}, 1250);
			
			setSelectedReviewers(prev => {
				const isSelected = prev.some(reviewer => reviewer.id === item.id);
				const newSelectedReviewers = isSelected
					? prev.filter(reviewer => reviewer.id !== item.id)
					: [...prev, item];
				return newSelectedReviewers;
			});

			setFilteringTags((prevTags: any) => {
				const newTags = { ...prevTags };
				const isSelected = selectedReviewers.some(
					reviewer => reviewer.id === item.id
				);
				if (selectedReviewers.length === 1 && isSelected) {
					delete newTags['assignedUserIds'];
				} else {
					const userId = item.id;
					if (isSelected) {
						newTags['assignedUserIds'] = (
							newTags['assignedUserIds'] ?? []
						).filter((id: string) => id !== userId);
					} else {
						newTags['assignedUserIds'] = [
							...(newTags['assignedUserIds'] ?? []),
							userId,
						];
					}
				}

				return newTags;
			});
		},
		[setIsAssigneeCheckLoading, setSelectedReviewers, setFilteringTags, selectedReviewers]
	);

	const handleCheckedfunds = useCallback(
		(item: IFunds) => {
			const checkingOnboardings = [...(filteringTags?.fund_name ?? [])];
			return checkingOnboardings.includes(item.name);
		},
		[filteringTags?.fund_name]
	);

	const handleFunds = useCallback(
		({ fundsSearch }: { fundsSearch?: string; isMounting?: boolean }) => {
			const searchParam = fundsSearch ? `search=${fundsSearch}` : '';

			const url = `${API_URL.FUNDS}?${searchParam}`;
			getFundsList(url);
		},

		[getFundsList]
	);

	const handleComplex = useCallback(
		({
			complexTablesearch,
		}: {
			complexTablesearch?: string;
			isMounting?: boolean;
		}) => {
			if (getComplexList) {
				const searchParam = complexTablesearch
					? `search=${complexTablesearch}`
					: 'search=';

				const url = `${API_URL.COMPLEX_FLOW}?${searchParam}`;
				getComplexList(url);
			}
		},

		[getComplexList]
	);

	const searchingFunds = debounce((value: string) => {
		handleFunds({ fundsSearch: value.trim() });
	}, 500);

	const handleSearchFunds = useCallback(
		(e: React.ChangeEvent<HTMLInputElement>) => {
			setSearchedFunds(e.target.value);
			searchingFunds(e.target.value);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[handleFunds]
	);

	useEffect(() => {
		if (filterPage === 'sessions') {
			handleFunds({
				fundsSearch: searchedFunds ? searchedFunds : '',
			});
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (filterPage === 'sessions') {
			handleComplex({
				complexTablesearch: searchComplex ? searchComplex : '',
			});
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (complexFlowList && filteringTags && !searchComplex) {
			const isChecked = (complexFlowList.data[0].result ?? []).filter(
				(item: IComplexPipeline) =>
					(filteringTags.onboardingFlowName ?? []).includes(item.name)
			);

			setCheckedOnboarding(isChecked);
		}

		if (fundsListApi && filteringTags && !searchedFunds) {
			const isChecked = (fundsListApi?.data[0]?.data ?? []).filter(
				(item: IFunds) => (filteringTags.fund_name ?? []).includes(item.name)
			);

			setCheckedFunds(isChecked);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [complexFlowList, fundsListApi]);

	const refOnboarding: any = useRef();
	useOutsideClick(refOnboarding, () => {
		setOnboardingOpen(false);
	});

	const reffunds: any = useRef();
	useOutsideClick(reffunds, () => {
		setFundsOpen(false);
	});

	const refActiveStatus = useRef();
	useOutsideClick(refActiveStatus, () => {
		setActiveStatusOpen(false);
	});

	const handleChangeFilter = useCallback(
		(
			e: React.ChangeEvent<HTMLInputElement>,
			key: string,
			status: string,
			index: string,
			item: IFunds
		) => {
			const { checked } = e.target;
			setFilterLoading({ ...isFilterLoading, [index]: true });
			setSelectedSession([]);
			setTimeout(() => {
				if (checked) {
					const isChecked = [...checkedFunds];
					isChecked.push(item);
					setCheckedFunds(isChecked);
					setFilteringTags((prev: string[]) => {
						const newState = JSON.parse(JSON.stringify(prev));
						return { ...newState, [key]: [...(newState[key] ?? []), status] };
					});
				} else {
					const clonedState = JSON.parse(JSON.stringify(filteringTags));

					const filteredValue = clonedState[key].filter(
						(item: string) => item !== status
					);
					clonedState[key] = filteredValue;
					if (!filteredValue.length) {
						delete clonedState[key];
					}
					setFilteringTags(clonedState);
				}
				setFilterLoading({ ...isFilterLoading, [index]: false });
			}, 1000);
		},
		[
			checkedFunds,
			filteringTags,
			isFilterLoading,
			setFilteringTags,
			setSelectedSession,
		]
	);

	const handleUncheckFilter = useCallback(
		(
			e: React.ChangeEvent<HTMLInputElement>,
			key: string,
			status: string,
			item: IFunds
		) => {
			e.stopPropagation();
			const { checked } = e.target;

			if (!checked) {
				const notChecked = checkedFunds.filter(
					noCheck => noCheck.name !== item.name
				);
				setCheckedFunds(notChecked);
				const clonedState = JSON.parse(JSON.stringify(filteringTags));

				const filteredValue = clonedState[key].filter(
					(item: string) => item !== status
				);
				clonedState[key] = filteredValue;
				if (!filteredValue.length) {
					delete clonedState[key];
				}
				setFilteringTags(clonedState);
			}
		},
		[checkedFunds, filteringTags, setFilteringTags]
	);

	const isSomeFilterLoading = useMemo(() => {
		const values = Object.values(isFilterLoading ?? {});
		return values.some(value => value);
	}, [isFilterLoading]);

	const renderOnboardingOpen = useMemo(() => {
		return (
			<Popup
				type="onboarding_flow"
				isSearch={true}
				filterPage={filterPage}
				searchComplex={searchComplex}
				setSearchComplex={setSearchComplex}
				handleComplex={handleComplex}
				complexFlowList={complexFlowList}
				isComplexLoading={isComplexLoading}
				checkedOnboarding={checkedOnboarding}
				setCheckedOnboarding={setCheckedOnboarding}
			/>
		);
	}, [
		checkedOnboarding,
		complexFlowList,
		filterPage,
		handleComplex,
		isComplexLoading,
		searchComplex,
	]);

	const renderAssigneeOpen = useMemo(() => {
		return (
			<ReviewerDropdown
				isSearch={true}
				reviewers={reviewers}
				searchTerm={searchTerm}
				setSearchTerm={setSearchTerm}
				handleReviewerSelect={handleReviewerSelect}
				isFiltter={true}
			/>
		);
	}, [handleReviewerSelect, reviewers, searchTerm]);

	const renderListCheckedFunds = useMemo(() => {
		const renderList = checkedFunds ?? [];

		return renderList?.map((item: IFunds, index: number) => {
			return (
				<>
					<div
						// eslint-disable-next-line react/no-array-index-key
						key={`checked_${item.name}_${index}`}
						className="popup-menu-item"
					>
						<div className="popup-menu-item__left">
							<input
								disabled={isSomeFilterLoading}
								id={`checked_item_${index}`}
								className="popup-menu-item__checkbox"
								type="checkbox"
								checked={handleCheckedfunds(item)}
								onChange={e =>
									handleUncheckFilter(e, 'fund_name', item.name, item)
								}
							/>

							<label
								htmlFor={`checked_item_${index}`}
								className="Popup--label-txt"
							>
								{item.name}
							</label>
						</div>
					</div>
				</>
			);
		});
	}, [
		checkedFunds,
		isSomeFilterLoading,
		handleCheckedfunds,
		handleUncheckFilter,
	]);

	const renderFundsOpen = useMemo(() => {
		const fundsList = (fundsListApi?.data[0]?.data ?? [])?.filter(
			(fund: IFunds) => !filteringTags?.fund_name?.includes(fund.name)
		);
		return (
			<>
				<div className="popup-wrapper popup-wrapper--onboarding ">
					<div className="popup-input-wrapper">
						<Input
							inputType="text"
							handleChange={handleSearchFunds}
							label=""
							value={searchedFunds}
							placeholder="Search..."
							suffixIcon="ri-search-line"
							handleChangeCountry={() => ({})}
						/>
					</div>
					<div className="padding-wrapper">
						{checkedFunds.length > 0 && (
							<div className={`popup-menu-list divider`}>
								<span className="popup-menu-item">Selected Funds</span>
								{renderListCheckedFunds}
							</div>
						)}
						<span className="popup-menu-item">Available Funds</span>
						{fundsList?.length === 0 && (
							<div className="RenderListItem--empty">No Data</div>
						)}
						{isFundsLoading && (
							<div className="popup-menu-item">
								<Loader type="loader" dimension={24} />
							</div>
						)}

						{!isFundsLoading &&
							fundsList?.map((item: IFunds, index: number) => (
								<>
									<div
										// eslint-disable-next-line react/no-array-index-key
										key={`${item.name}_${index}`}
										className="popup-menu-item"
										// onClick={() => handleClickToOpen(item.value)}
									>
										<div className="popup-menu-item__left">
											{isFilterLoading[`${index}`] ? (
												<div style={{ width: 20, height: 18 }}>
													<Loader type="loader" dimension={18} />
												</div>
											) : (
												<input
													disabled={isSomeFilterLoading}
													id={`item_${index}`}
													className="popup-menu-item__checkbox"
													type="checkbox"
													checked={handleCheckedfunds(item)}
													onChange={e =>
														handleChangeFilter(
															e,
															'fund_name',
															item.name,
															`${index}`,
															item
														)
													}
												/>
											)}
											<label
												htmlFor={`item_${index}`}
												className="Popup--label-txt"
											>
												{item.name}
											</label>
										</div>
									</div>
								</>
							))}
					</div>
				</div>
			</>
		);
	}, [
		handleSearchFunds,
		searchedFunds,
		checkedFunds.length,
		renderListCheckedFunds,
		fundsListApi?.data,
		isFundsLoading,
		filteringTags?.fund_name,
		isFilterLoading,
		isSomeFilterLoading,
		handleCheckedfunds,
		handleChangeFilter,
	]);

	const renderActiveStatusOpen = useMemo(() => {
		return (
			<Popup
				type={filterPage === 'pipelines' ? 'no_status_action' : 'active_status'}
				list={actions}
				filterPage={filterPage}
			/>
		);
	}, [filterPage]);

	const handleClickOnboarding = useCallback(() => {
		setOnboardingOpen(!isOnboardingOpen);
		setActiveStatusOpen(false);
	}, [isOnboardingOpen]);

	const handleClickFunds = useCallback(() => {
		setFundsOpen(!isFundsOpen);
		setActiveStatusOpen(false);
	}, [isFundsOpen]);

	const handleClickActiveStatus = useCallback(() => {
		setActiveStatusOpen(pre => !pre);
		setOnboardingOpen(false);
	}, []);

	const refAssignReviewer = useRef();
	useOutsideClick(refAssignReviewer, () => {
		setAssignedReviewerOpen(false);
	});

	const handleClickAssignReviewer = useCallback(() => {
		setAssignedReviewerOpen(!isAssignedReviewerOpen);
		setOnboardingOpen(false);
	}, [isAssignedReviewerOpen]);

	const handleResetOnboarding = useCallback(() => {
		const resetOnboarding = { ...filteringTags };
		delete resetOnboarding.onboardingFlowName;
		setFilteringTags(resetOnboarding);
	}, [filteringTags, setFilteringTags]);

	const handleResetfunds = useCallback(() => {
		const resetOnboarding = { ...filteringTags };
		delete resetOnboarding.fundName;
		setFilteringTags(resetOnboarding);
	}, [filteringTags, setFilteringTags]);

	const handleResetActions = useCallback(() => {
		setSelectedReviewers([]);
		if (filterPage === 'pipelines') {
			setPipelinesFilter({});
			return;
		}
		if (filteringTags?.onboardingFlowName) {
			const onboardingOnly = {
				onboardingFlowName: [...filteringTags.onboardingFlowName],
			};
			return setFilteringTags(onboardingOnly);
		}
		return setFilteringTags({});
	}, [filterPage, filteringTags, setFilteringTags, setPipelinesFilter, setSelectedReviewers]);

	const filterActiveIndicator = useMemo(() => {
		if (filterPage === 'pipelines') {
			if (Object.keys(pipelinesFilter).length === 1) {
				return LabelDataActivity[
					Object.keys(pipelinesFilter)[0] as keyof typeof LabelDataActivity
				];
			}
			let sum = 0;
			Object.keys(pipelinesFilter).forEach(tag => {
				if (tag !== 'onboardingFlowName') sum += 1;
			});
			return sum > 0 ? `${sum} selected` : 'Select';
		} else {
			if (Object.keys(filteringTags).length === 1) {
				return LabelDataActivity[
					Object.keys(filteringTags)[0] as keyof typeof LabelDataActivity
				];
			}
			let sum = 0;
			Object.keys(filteringTags).forEach(tag => {
				if (tag !== 'onboardingFlowName' && tag !== 'fund_name' && tag !== 'assignedUserIds') sum += 1;
			});
			return sum > 0 ? `${sum} selected` : '';
		}
	}, [filterPage, filteringTags, pipelinesFilter]);

	const classes = useCallback(
		(check: boolean) =>
			classNames('ri-arrow-down-s-line', {
				'filter-popup__opened': check,
				'filter-popup__closed': !check,
			}),
		[]
	);

	const handleResetAll = useCallback(() => {
		setIsResetting(true);
		setTimeout(() => {
			handleResetOnboarding();
			handleResetActions();
			handleResetfunds();
			setFilteringTags({});
			if (filterPage === 'sessions') {
				resetSessions();
				setSelectedSession([]);
			}
			setIsResetting(false);
		}, 1000);
	}, [
		handleResetActions,
		handleResetOnboarding,
		handleResetfunds,
		setFilteringTags,
		resetSessions,
		setSelectedSession,
		filterPage,
	]);

	const selectClass = classNames('filter-popup__active-status-btn', {
		'filter-popup__active-status': isActiveStatusOpen,
	});

	const renderPage = useMemo(() => {
		return (
			<>
				{filterPage === 'pipelines' && (
					<div className="filter-popup-wrapper">
						<div
							ref={refActiveStatus as any}
							className="filter-popup__active-status-wrapper"
						>
							<div className="filter-popup__header">
								<div className="filter-popup__header__label">Actions</div>
								<div className="reset-filter-all" onClick={handleResetActions}>
									Reset
								</div>
							</div>
							<div className={selectClass} onClick={handleClickActiveStatus}>
								<span className="filter-popup__active-status-btn__label">
									{filterActiveIndicator ?? 'Select actions'}
								</span>
								<i className={classes(isActiveStatusOpen)}></i>
							</div>
							{isActiveStatusOpen && renderActiveStatusOpen}
						</div>
					</div>
				)}
				{filterPage === 'sessions' && (
					<div className="session-filter-popup-wrapper">
						<div className="headingrow">
							<span className="filter-popup__header__label">Filter</span>
							{resetingSessionsFilter || isResetting ? (
								<div className="reset-filter-all">Reseting...</div>
							) : (
								<div
									onClick={() => handleResetAll()}
									className="reset-filter-all"
								>
									Reset
								</div>
							)}
						</div>
						<TextFilter />
						<div
							ref={refOnboarding}
							className="filter-popup__onboardingflow-wrapper bottom-touch"
						>
							<div
								className="filter-popup__header bottom-touch"
								onClick={handleClickOnboarding}
							>
								<div>
									<div className="filter-popup__header__label">
										Onboarding Flow
									</div>
									<span className="filter-popup__header__selected-count">
										{filteringTags?.onboardingFlowName &&
											filteringTags?.onboardingFlowName?.length + ' selected'}
									</span>
								</div>

								<i className={classes(isOnboardingOpen)}></i>
							</div>
							{isOnboardingOpen && renderOnboardingOpen}
						</div>
						<div
							ref={refActiveStatus as any}
							className="filter-popup__active-status-wrapper bottom-touch"
						>
							<div
								className="filter-popup__header bottom-touch"
								onClick={handleClickActiveStatus}
							>
								<div>
									<div className="filter-popup__header__label">
										Actions & Status
									</div>
									<span className="filter-popup__header__selected-count">
										{filterActiveIndicator}
									</span>
								</div>

								<i className={classes(isActiveStatusOpen)}></i>
							</div>

							{isActiveStatusOpen && renderActiveStatusOpen}
						</div>
						<div
							ref={reffunds}
							className="filter-popup__active-status-wrapper bottom-touch"
						>
							<div
								className="filter-popup__header bottom-touch"
								onClick={handleClickFunds}
							>
								<div>
									<div className="filter-popup__header__label">Funds</div>{' '}
									<span className="filter-popup__header__selected-count">
										{filteringTags?.fund_name &&
											filteringTags?.fund_name?.length + ' selected'}{' '}
									</span>
								</div>
								<i className={classes(isFundsOpen)}></i>
							</div>
							{isFundsOpen &&
								(fundsListApi?.data[0]?.data?.length === 0 && !searchedFunds ? (
									<div className="RenderListItem--no-data">No Data</div>
								) : (
									renderFundsOpen
								))}
						</div>
						<div
							ref={refAssignReviewer as any}
							className="filter-popup__active-status-wrapper "
						>
							<div
								className="filter-popup__header bottom-touch"
								onClick={handleClickAssignReviewer}
							>
								<div>
									<div className="filter-popup__header__label">
										Assigned Reviewer
									</div>
									
								</div>

								<i className={classes(isAssignedReviewerOpen)}></i>
							</div>

							{isAssignedReviewerOpen && renderAssigneeOpen }
						</div>
					</div>
				)}
			</>
		);
	}, [filterPage, handleResetActions, selectClass, handleClickActiveStatus, filterActiveIndicator, classes, isActiveStatusOpen, renderActiveStatusOpen, resetingSessionsFilter, isResetting, handleClickOnboarding, filteringTags?.onboardingFlowName, filteringTags?.fund_name, isOnboardingOpen, renderOnboardingOpen, handleClickFunds, isFundsOpen, fundsListApi?.data, searchedFunds, renderFundsOpen, handleClickAssignReviewer, isAssignedReviewerOpen, renderAssigneeOpen, handleResetAll]);

	return <>{renderPage}</>;
};
