import { Component } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ReactNotifications } from 'react-notifications-component';
import { RecoilRoot } from 'recoil';
import PropTypes from 'prop-types';
import { App } from 'main';
import posthog from 'posthog-js';

import 'react-notifications-component/dist/theme.css';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file

// Replace with your GA4 Measurement ID
const GA_MEASUREMENT_ID = 'G-DFZ2ND2B4Z';

// Add the GA4 script to the document head
const initializeGA = () => {
  const script = document.createElement('script');
  script.src = `https://www.googletagmanager.com/gtag/js?id=${GA_MEASUREMENT_ID}`;
  script.async = true;
  document.head.appendChild(script);

  script.onload = () => {
    // Initialize GA4 after the script has loaded
    (window as any).dataLayer = (window as any).dataLayer || [];
    function gtag(...args: any[]) {
		(window as any).dataLayer.push(args);
    }
    gtag('js', new Date());
    gtag('config', GA_MEASUREMENT_ID);
	gtag('config', GA_MEASUREMENT_ID, {
		debug_mode: true,
	  });
  };
};

// Initialize GA
initializeGA();

const options = {
	api_host: 'https://us.i.posthog.com', // put this value in env
};

posthog.init('phc_aZDzpEYlhSQrw7VrL6tBkwu0Sx70taMOvtQKchBMV8E', options);

export class OnboardedDashboard extends Component {
	render() {
		return (
			<RecoilRoot>
				<BrowserRouter>
					<ReactNotifications />
					<App webComponentProps={this.props} />
				</BrowserRouter>
			</RecoilRoot>
		);
	}
}

(OnboardedDashboard as any).propTypes = {
	bgcolor: PropTypes.string,
	search: PropTypes.string,
	theadcolor: PropTypes.string,
	clientid: PropTypes.string,
	clientsecret: PropTypes.string,
	btnprimary: PropTypes.string,
	btnsecondary: PropTypes.string,
	notification: PropTypes.string,
	type: PropTypes.string,
	admin: PropTypes.string,
	sidebar: PropTypes.string,
	isinsideadmin: PropTypes.string,
	clientlogosmall: PropTypes.string,
	clientlogo: PropTypes.string,
	companyname: PropTypes.string,
	companyemail: PropTypes.string,
};
