import { FC, useCallback, useState } from 'react';
import './ofac-results.scss';
import { KeyValuePair } from '../key-value-pair';

interface IProps {
	children: JSX.Element;
	title: string;
	count: number;
	label: string;
	value: string;
}

export const OFACResults: FC<IProps> = ({
	children,
	title,
	count,
	label,
	value,
}) => {
	const [isOpen, setIsOpen] = useState(true);

	const handleDropClick = useCallback(() => {
		setIsOpen(!isOpen);
	}, [isOpen]);

	return (
		<div className="OFACResults__accordion">
			<div onClick={handleDropClick} className="OFACResults__accordion-header">
				<div className="OFACResults__accordion-data">
					<div className="OFACResults__accordion-heading">
						{title}&nbsp;{count}
					</div>
					<div className="OFACResults__accordion-subheading">
						<KeyValuePair value={value} label={label} />
					</div>
				</div>
				<div className="OFACResults__accordion-icon">
					{isOpen ? (
						<i className="ri-arrow-drop-up-line"></i>
					) : (
						<i className="ri-arrow-drop-down-line"></i>
					)}
				</div>
			</div>
			{isOpen && children}
		</div>
	);
};
