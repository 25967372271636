import { FC, MouseEvent, useCallback, useMemo, useState } from 'react';
import Tippy from '@tippyjs/react';

import { Image } from '@storybook';
import { FileTypes } from '../constants';
import { DocumentAccessMessage } from 'constant';
import { useNotification } from 'hooks';

interface IProps {
	name: string;
	type: string;
	password: string;
	url: string;
	_id: string;
}

export const CommentDocument: FC<IProps> = ({
	name,
	type,
	password,
	url,
	_id,
}) => {
	const [isView, setIsView] = useState(false);
	const [isCopied, setIsCopied] = useState(false);
	const { errorNotification } = useNotification();

	const handleViewPassword = useCallback((e: MouseEvent<HTMLButtonElement>) => {
		e.stopPropagation();
		setIsView(prev => !prev);
	}, []);

	const handleCopyPassword = useCallback(
		(e: MouseEvent<HTMLButtonElement>) => {
			e.stopPropagation();
			if (password) {
				navigator.clipboard.writeText(password);
			}
			setIsCopied(true);
			setTimeout(() => {
				setIsCopied(false);
			}, 1000);
		},
		[password]
	);

	const attachedfile = useMemo(() => {
		const splitedFile = name.split('.');
		const file: any = splitedFile[splitedFile.length - 1]?.toUpperCase();
		if (FileTypes[file]) {
			return file + '.svg';
		}
		return 'Default.svg';
	}, [name]);

	const handleClick = useCallback(() => {
		if (password) {
			// eslint-disable-next-line no-alert
			const entered = prompt(DocumentAccessMessage?.PROMPT_MESSAGE);
			if (entered === password) {
				window.open(url ?? '', '_blank');
			} else {
				errorNotification(DocumentAccessMessage?.ERROR_MESSAGE);
			}
		} else {
			window.open(url ?? '', '_blank');
		}
	}, [errorNotification, password, url]);

	return (
		<div onClick={handleClick} key={_id ?? ''} className="comment-document">
			<div className="comment-document__disc-wrapper">
				<div className="comment-document__left">
					<div className="comment-document__left__image-wrapper">
						<Image fileName={attachedfile} width={'20px'} />
					</div>
					<Tippy
						content={
							<div className="comment-document__heading-tippy">
								{name ?? ''}
							</div>
						}
					>
						<div className="comment-document__left__heading">{name ?? ''}</div>
					</Tippy>
					<div className="comment-document__left__sub-heading">
						{type ?? ''}
					</div>
				</div>
				<div className="comment-document__right">
					{/* <i className="ri-more-2-line" /> */}
				</div>
			</div>
			{password ? (
				<div className="comment-document__password-wrapper">
					<div className="comment-document__password-text">Password:</div>
					<div className="comment-document__password">
						{isView ? password : '********'}
					</div>
					<div className="comment-document__password-btn-wrapper">
						<button
							className="comment-document__password-btn"
							onClick={handleViewPassword}
						>
							{isView ? 'Hide' : 'View'}
						</button>
						<button
							className="comment-document__password-btn"
							onClick={handleCopyPassword}
						>
							{isCopied ? 'Copied' : 'Copy'}
						</button>
					</div>
				</div>
			) : (
				<></>
			)}
		</div>
	);
};
