import type { ITransactionUser } from 'views/transactions/stores';

import { useCallback, useMemo } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { IMAGE_GCP_URL } from 'constant';
import { SelectedSessionsIdState, SessionItemSelected } from 'global-stores';
import { TransactionType, userCardInfo } from 'views/transactions/constants';
import { SelectedTransactionState } from 'views/transactions/stores';
import { UserFaceMatch } from 'views/sessions/components/kyc-details/user-face-match';
import { v4 } from 'uuid';

export const TransactionUser = ({
	type,
	userImage,
	recipient_img,
	senderEmail,
	recipientEmail,
	senderPhone,
	recipientPhone,
	senderSessionId,
	recipientSessionId,
	senderMatchScore,
	setTransactionType,
	fetchTransactionSession,
}: ITransactionUser) => {
	//globle states
	const selectedTransaction = useRecoilValue(SelectedTransactionState);
	const setSessionItemSelected = useSetRecoilState(SessionItemSelected);
	const setSelectedSessionId = useSetRecoilState(SelectedSessionsIdState);

	const senderImage = useMemo(() => {
        if (/storage.googleapis.com/gi.test(userImage)) {
            return userImage
        }
        return `data:image/svg+png;base64,${userImage}`
    }, [userImage]);

	const handleClickOnlink = useCallback(
		(id: string, name: string, tranType: TransactionType) => () => {
			setTransactionType(tranType);
			setSelectedSessionId([id]);
			setSessionItemSelected({ name, _id: id });
			fetchTransactionSession(id);
		},
		[
			setSelectedSessionId,
			setSessionItemSelected,
			setTransactionType,
			fetchTransactionSession,
		]
	);

	const hasComplinceData = useCallback(
		(type: TransactionType) => {
			return (
				(type === TransactionType.Sender && senderSessionId) ||
				(type === TransactionType.Recipient && recipientSessionId)
			);
		},
		[recipientSessionId, senderSessionId]
	);

	const viewKycAml = useMemo(
		() =>
			hasComplinceData(type) ? (
				<div
					onClick={handleClickOnlink(
						type === TransactionType.Sender
							? senderSessionId
							: recipientSessionId,
						selectedTransaction[type] ?? '',
						type
					)}
					className="TransactionCard--info-wrapper__info-link"
				>
					<i className="ri-eye-line" />
					<span>View details</span>
				</div>
			) : (
				`No compliance data found for ${type}`
			),
		[
			handleClickOnlink,
			hasComplinceData,
			recipientSessionId,
			selectedTransaction,
			senderSessionId,
			type,
		]
	);

	const userInfoValues: {
		[key: string]: string | JSX.Element;
	} = useMemo(
		() => ({
			name: selectedTransaction[type] ?? 'NA',
			email:
				type === TransactionType.Sender
					? senderEmail || 'NA'
					: recipientEmail || 'NA',
			phone:
				type === TransactionType.Sender
					? senderPhone || 'NA'
					: recipientPhone || 'NA',
			kycAml: viewKycAml,
		}),
		[
			selectedTransaction,
			type,
			senderEmail,
			recipientEmail,
			senderPhone,
			recipientPhone,
			viewKycAml,
		]
	);

	const renderTransactionInfo = useMemo(
		() => (
			<div className="TransactionCard--info-wrapper">
				{(userCardInfo?.[type] ?? []).map(({ key, label }) => (
					<div className="TransactionCard--info-wrapper__info" key={label}>
						<div className="TransactionCard--info-wrapper__info-label">
							{label}
						</div>
						<div className="TransactionCard--info-wrapper__separate">:</div>
						<div className="TransactionCard--info-wrapper__info-value">
							<span className="span">{userInfoValues?.[key] || 'NA'}</span>
						</div>
					</div>
				))}
			</div>
		),
		[type, userInfoValues]
	);

	
	const getColors = useCallback((percent: number) => {
		if (percent >= 96) {
			return { matchChartColor: "#33b87a", unMatchChartColor: "#99e0c6" };
		} else if (percent >= 85) {
			return { matchChartColor: "#e5ae40", unMatchChartColor: "#f7dab0" };
		} else {
			return { matchChartColor: "#d32222", unMatchChartColor: "#f28a8a" };
		}
	}, []);
	
	const renderFaceMatchSection = useMemo(() => {
		const matchPercent = senderMatchScore
			? Math.min(100, Math.max(0, Number(senderMatchScore) * 100))
			: 0;
	
		const { matchChartColor, unMatchChartColor } = getColors(matchPercent);
	
		return (
			senderMatchScore &&
			type === TransactionType.Sender && (
				<UserFaceMatch
					title="Simplici"
					matchPercent={matchPercent}
					matchChartColor={matchChartColor}
					unMatchChartColor={unMatchChartColor}
					id={v4()}
				/>
			)
		);
	}, [senderMatchScore, type, getColors]);

	return (
		<div className="TransactionCard--user">
			<div className="TransactionCard--image-wrapper">
				<img
					src={
						type === TransactionType.Sender
							? senderImage ?? ''
							: recipient_img ?? ''
					}
					alt={type}
					className="TransactionCard--image-wrapper__image"
					onError={e => {
						const target = e.target as HTMLImageElement;
						target.src = `${IMAGE_GCP_URL + '/AvatarDefault-Light.svg'} `;
						target.alt = type;
					}}
				/>
				{renderFaceMatchSection}
			</div>
			{renderTransactionInfo}
		</div>
	);
};
