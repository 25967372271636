import { useMemo, useState } from 'react';
import {
	useRecoilState,
	useRecoilValue,
	useResetRecoilState,
	useSetRecoilState,
} from 'recoil';

import { API_URL } from 'constant';
import { BusinessPaymentCardState, loginState } from 'global-stores';
import { useNetwork, useNotification } from 'hooks';
import {
	ActivePaymentType,
	AddCardModalState,
	BillingAddressFromState,
	PaymentNavigationState,
	PrimaryAccountChecked,
	PrimaryAchAccountChecked,
} from 'views/settings/billing/stores';
import { BillingPaymentAddress } from 'views/user-identity-flow';
import {
	BillingAddressFrom,
	PaymentCardType,
	PaymentNavigation,
} from '../../constant';
import { usePlaid } from './use-plaid';

export const usePayments = () => {
	// State
	const [addPaymentNaviagtion, setAddPaymentNaviagtion] = useRecoilState(
		PaymentNavigationState
	);
	const setBusinessPaymentCardState = useSetRecoilState(
		BusinessPaymentCardState
	);
	const setOpenView = useSetRecoilState(AddCardModalState);
	const resetaddCardModalState = useResetRecoilState(AddCardModalState);
	const restPaymentNavigationState = useResetRecoilState(
		PaymentNavigationState
	);
	const defaultCheck = useRecoilValue(PrimaryAccountChecked);

	const restActivePaymentType = useResetRecoilState(ActivePaymentType);

	const billingAddress = useRecoilValue(BillingPaymentAddress);
	const loginUser = useRecoilValue(loginState);
	const choose = useRecoilValue(ActivePaymentType);
	const [billingAddressFrom, setBillingAddressFrom] = useRecoilState(
		BillingAddressFromState
	);
	const resetBillingAddress = useResetRecoilState(BillingPaymentAddress);

	const [isLoading, setIsLoading] = useState(false);
	const defaultAchCheck = useRecoilValue(PrimaryAchAccountChecked);
	// Hooks
	const { initPlaid, isLoadingPlaid } = usePlaid({ isSignup: false });
	const { errorNotification } = useNotification();
	const { patch: updateCompanyDetails, post } = useNetwork({
		updateState: false,
	});

	const { BUSINESSES } = API_URL;

	const handleClose = () => {
		setOpenView({
			isOpen: false,
			isEdit: false,
		});

		setTimeout(() => {
			resetaddCardModalState();
			restPaymentNavigationState();
			restActivePaymentType();
			setBillingAddressFrom('');
		}, 500);
	};

	const setDefaultAchAccount = async () => {
		setIsLoading(true);
		if (Object.values(defaultAchCheck ?? {})?.every(el => el)) {
			const payload = {
				method: 'ach',
				data: {
					...defaultAchCheck,
					accountType: defaultAchCheck.accountType.toUpperCase(),
				},
			};
			const defaultResp = await post(API_URL.Payment_Methods, payload);
			if (defaultResp.success) {
				setAddPaymentNaviagtion(PaymentNavigation.Processing);
			}
		} else {
			setAddPaymentNaviagtion(PaymentNavigation.Processing);
		}
		setIsLoading(false);
	};

	const handleBilling = async () => {
		setIsLoading(true);
		const resp = await updateCompanyDetails(
			`${BUSINESSES}/${loginUser?.business ?? ''}`,
			{
				billingAddress,
			}
		);
		if (resp?._id) {
			if (billingAddressFrom === BillingAddressFrom.FromBillingInfo)
				handleClose();
			else setAddPaymentNaviagtion(PaymentNavigation.Success);
			setBusinessPaymentCardState((prev: any) => ({ ...prev, billingAddress }));
		} else {
			errorNotification(resp?.message);
		}
		setIsLoading(false);
		setBillingAddressFrom('');
	};

	const handleWireAccount = async () => {
		if (defaultCheck) {
			const payload = {
				method: 'wire',
			};
			const defaultResp = await post(API_URL.Payment_Methods, payload);
			if (defaultResp.success) {
				setAddPaymentNaviagtion(PaymentNavigation.Processing);
			}
		} else {
			setAddPaymentNaviagtion(PaymentNavigation.Processing);
		}
	};

	const handleChoosePaymentMethode = () => {
		if (choose === PaymentCardType.Ach) {
			initPlaid();
		} else if (
			choose === PaymentCardType.Wire ||
			choose === PaymentNavigation.WireScreen
		)
			setAddPaymentNaviagtion(PaymentNavigation.WireScreen);
		else setAddPaymentNaviagtion(PaymentNavigation.CardScreen);
	};

	const handleNextBtn = () => {
		switch (addPaymentNaviagtion) {
			case PaymentNavigation.ChoosePage:
				handleChoosePaymentMethode();
				break;
			case PaymentNavigation.Billing:
				handleBilling();
				break;
			case PaymentNavigation.WireScreen:
				handleWireAccount();
				break;
			case PaymentNavigation.DefaultAchPage:
				setDefaultAchAccount();
				break;
			default:
				break;
		}
	};

	const handleBackBtn = () => {
		switch (addPaymentNaviagtion) {
			case PaymentNavigation.ChoosePage:
				handleClose();
				break;
			case PaymentNavigation.Billing:
				if (billingAddressFrom === BillingAddressFrom.FromBillingInfo) {
					handleClose();
					break;
				}
				resetBillingAddress();
				setAddPaymentNaviagtion(PaymentNavigation.Success);
				break;
			case PaymentNavigation.WireScreen:
				setAddPaymentNaviagtion(PaymentNavigation.ChoosePage);
				break;
			default:
				break;
		}
	};

	const disabledNextBtn = useMemo(() => {
		switch (addPaymentNaviagtion) {
			case PaymentNavigation.CardView:
				return false;
			case PaymentNavigation.AchView:
				return false;
			case PaymentNavigation.ChoosePage:
				if (choose === PaymentNavigation.AchView) {
					return isLoadingPlaid;
				}
				return false;
			case PaymentNavigation.CardScreen:
				return false;
			case PaymentNavigation.Billing:
				return (
					Object.entries(billingAddress ?? {})
					  .filter(([key]) => key !== 'address2') 
					  .some(([, value]) => !value.trim()) || 
					(billingAddress?.zip?.length ?? 0) < 4 
				  );
			default:
				return false;
		}
	}, [addPaymentNaviagtion, isLoadingPlaid, choose, billingAddress]);

	const shouldShowSideBar = useMemo(
		() => /CardView|AchView|WireView/.test(addPaymentNaviagtion),
		[addPaymentNaviagtion]
	);

	const hideFooterBtn = useMemo(() => {
		return /Success|Card|WireView|AchView|CardView|Processing/.test(
			addPaymentNaviagtion
		);
	}, [addPaymentNaviagtion]);

	const hideBackBtn = useMemo(() => {
		return /AchDefaultAccount/.test(addPaymentNaviagtion);
	}, [addPaymentNaviagtion]);

	return {
		handleNextBtn,
		addPaymentNaviagtion,
		handleBackBtn,
		isLoadingPlaid,
		disabledNextBtn,
		hideFooterBtn,
		shouldShowSideBar,
		isLoading,
		hideBackBtn,
	};
};
