/**
 * @Manish Import necessary dependencies from React and other modules
 * */
import { FC, useCallback, useMemo } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { Button } from '@storybook';

/**
 * @Manish Import components and states from local files and external modules
 * */
import Modal from '@storybook/new-modal/modal';
import { IsCreateTemplateStepperOpen, SelectedSignerState } from '../store';
import { SignSelectorScreen } from './sign-selector-screen';
import { AgreementTemplate } from './agreement-template';
import {
	SignDocModalState,
	UploadedTemplateDocsState,
	templateNameState,
} from 'global-stores';
import { SimpliciSignOverlay } from './simplici-sign-overlay';

/**
 * @Manish Define the interface for the CreateTemplateSteps component
 */
interface ICreateTemplateSteps {
	handleOnClickSaveCreateDoc: () => void; // Specify the correct type for this prop
	isDisabled: boolean;
}

/**
 * @Manish Create the CreateTemplateSteps functional component
 * */
export const CreateTemplateSteps: FC<ICreateTemplateSteps> = ({
	handleOnClickSaveCreateDoc,
	isDisabled,
}) => {
	/**
	 * @Manish Initialize state variables and functions using Recoil hooks
	 * */
	const [isModal, setIsModal] = useRecoilState(IsCreateTemplateStepperOpen);
	const setSignDocState = useSetRecoilState(SignDocModalState);
	const setTempName = useSetRecoilState(templateNameState);
	const setAddedFiles = useSetRecoilState(UploadedTemplateDocsState);
	const [selectedSigner, setSelectedSigner] =
		useRecoilState(SelectedSignerState);

	/**
	 * @Manish Define a callback function to handle closing the create document modal
	 */
	const handleOnClickCloseCreatDoc = useCallback(() => {
		setSignDocState(false);
		setTempName('');
		setAddedFiles([]);
		setSelectedSigner('');
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	/**
	 * @Manish Determine the content to be rendered based on the selectedSigner state
	 * */
	const renderCreateTemplateSteps = useMemo(() => {
		switch (selectedSigner) {
			case 'esign':
			case 'docusign':
			case 'eSign_editable':
				return (
					<>
						<AgreementTemplate />
						<div className="CreateAgreementModal-btn">
							<Button
								handleClick={handleOnClickCloseCreatDoc}
								label="Back"
								type="button__filled button__filled--secondary button__large"
							/>
							<Button
								handleClick={handleOnClickSaveCreateDoc}
								label="Configure Doc."
								type="button__filled button__filled--primary button__large"
								disabled={isDisabled}
							/>
						</div>
					</>
				);
		default:
				return <SignSelectorScreen />;
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		handleOnClickSaveCreateDoc,
		isDisabled,
		selectedSigner,
	]);

	return (
		<>
			<Modal
				isOpen={isModal}
				modalName="Create Agreement Modal"
				closeModal={() => setIsModal(false)}
				className="createAgreementModal"
				showCloseBtn
				isStopOutsideClick={false}
				showHeader={selectedSigner !== 'esign_overlay'}
				title={
					selectedSigner && (
						<div className="CreateAgreementModal-header">
							<div className="CreateAgreementModal-head-title">
								Create Agreement Template
							</div>
							<div className="OnboardingInviteModal-head-desc">
								Create agreement template helps you add and configure documents
								as per the requirement of your agreement which you may then use
								for signing or other purposes.
							</div>
						</div>
					)
				}
			>
				{renderCreateTemplateSteps}
			</Modal>
			{selectedSigner === 'esign_overlay' && (
				<SimpliciSignOverlay
					handleCreateTemplate={handleOnClickSaveCreateDoc}
				/>
			)}
		</>
	);
};
