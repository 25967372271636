import { FC, useCallback, useEffect, useMemo } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import {
	AddedActionsState,
	SelectedRadioState,
	useOnboardingHook,
} from 'views/onboarding-flow';
import { ComplexSettingFormState } from 'views/pipeline/store';
import { UnitPrice } from '../unit-price';

import { Tooltip } from '@storybook';
import { loginState } from 'global-stores';
import { FundsPayoutHideBusiness } from '../constants';

import './radio-selection.scss';

interface IRadioSelection {
	data: any;
	defaultValue?: any;
	stepKey: string;
	actionKey: string;
	id?: string;
	isDisable?: boolean
	isPriceHide?: boolean
}
export const RadioSelection: FC<IRadioSelection> = ({
	data,
	stepKey,
	actionKey,
	defaultValue,
	id,
	isDisable,
	isPriceHide = false
}) => {
	const setSelectedRadioLabel = useSetRecoilState(SelectedRadioState);
	const { business = '' } = useRecoilValue(loginState);

	const isHideFundsPayout = useMemo(() => {
		return FundsPayoutHideBusiness.includes(business ?? "");
	}, [business]);

	const setAddedActions = useSetRecoilState(AddedActionsState);

	const [complexSettingForm, setComplexSettingForm] = useRecoilState(
		ComplexSettingFormState
	);

	const { isPricingHide } = useOnboardingHook();

	useEffect(() => {
		setSelectedRadioLabel(defaultValue);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [defaultValue]);

	const updateActionPrice = useCallback(
		(name: string) => {
			setAddedActions(prev => {
				const prevState = JSON.parse(JSON.stringify(prev));
				const foundActionIndex = prevState.findIndex(
					({ key }: any) => key === stepKey
				);
				const foundOptionIndex = (
					prevState[foundActionIndex]?.metadata?.[0]?.options ?? []
				).findIndex((option: any) => {
					return option.key === name;
				});
				const { price } =
					prevState[foundActionIndex]?.metadata?.[0]?.options?.[
						foundOptionIndex
					] ?? {};
				if (price != null) {
					prevState[foundActionIndex].price = price;
				}
				return prevState;
			});
		},
		[setAddedActions, stepKey]
	);

	const handleRadioSelect = useCallback(
		(value: string) => {
			if (stepKey === 'signAgreementVerification') {
				updateActionPrice(value);
			}

			if (
				id &&
				stepKey === 'business_sanctions_ofac' &&
				actionKey === 'amlFrequency'
			) {
				const payload = {
					[actionKey]: value,
				};

				setComplexSettingForm((prev: any) => {
					const newObj = structuredClone(prev);
					if (!newObj[id]) {
						newObj[id] = {};
					}
					Object.assign(newObj[id], payload);
					return newObj;
				});
			}
			setSelectedRadioLabel(value);
			setAddedActions(prev => {
				const prevState = JSON.parse(JSON.stringify(prev));
				const foundIndex = prevState.findIndex(
					({ key }: any) => key === stepKey
				);
				const subIndex = prevState[foundIndex]?.metadata.findIndex(
					({ key }: any) => key === actionKey
				);
				prevState[foundIndex].metadata[subIndex].default = value;
				return prevState;
			});

			if (id) {
				const payload = {
					[actionKey]: value,
				};

				setComplexSettingForm((prev: any) => {
					const newObj = structuredClone(prev);
					if (!newObj[id]) {
						newObj[id] = {};
					}
					Object.assign(newObj[id], payload);
					return newObj;
				});
			}
		},
		[
			actionKey,
			id,
			setAddedActions,
			setComplexSettingForm,
			setSelectedRadioLabel,
			stepKey,
			updateActionPrice,
		]
	);

	// Setting default value for complex config
	useEffect(() => {
		if (id && !complexSettingForm?.[id]) {
			const payload = {
				[actionKey]: defaultValue,
			};
			setComplexSettingForm((prev: any) => {
				const newObj = structuredClone(prev);
				if (!newObj[id]) {
					newObj[id] = {};
				}
				Object.assign(newObj[id], payload);
				return newObj;
			});
		}
	}, [actionKey, complexSettingForm, defaultValue, id, setComplexSettingForm]);

	const initalValue = useCallback(
		(item: any) => {
			return complexSettingForm[id ?? '']?.[actionKey] === item.key;
		},
		[actionKey, complexSettingForm, id]
	);

	const getActionInfo = useCallback((actionKey: string) => {
		if (actionKey === 'oneTime') {
			return 'Verification';
		} else if (actionKey === 'dailyOnGoing') {
			return 'Per year';
		} else return 'Packet';
	}, []);

	const renderList = useMemo(
		() =>
			data?.map((item: any, index: number) => {
				if (isHideFundsPayout && item?.key === 'payOut') {
					return <></>;
				}
				return (
					<>
						<label
							htmlFor={item.key}
							key={`${item.key}_${index.toString()}`}
							className={`radio-wrapper pipeline-settings__${item.key}`}
							onClick={() => {
								if (!isDisable) {
								  handleRadioSelect(item.key);
								}
							  }}
						>
							{isDisable ? (
								<Tooltip text={'Please enable the toggle button to proceed.'}>
									<input
										disabled={isDisable ?? false}
										type="radio"
										checked={initalValue(item)}
									/>
								</Tooltip>
							) : (
								<input
									disabled={isDisable ?? false}
									type="radio"
									checked={initalValue(item)}
								/>
							)}
							<div className="radio-wrapper__content">
								<div className="radio-wrapper__content__label">
									<div>{item?.label ?? '--'}</div>
									{!isPriceHide && isPricingHide && item?.price && (
										<div className="radio-wrapper__content--price">
											{/* Arun Kumar : For Sign Aggrement verification changed into Packet */}
											{`$${item?.price?.toFixed(2) ?? 0}/${getActionInfo(item?.key ?? "")}`}
										</div>
									)}
								</div>
								<div className="radio-wrapper__content__sub_label">
									{item?.description ?? '--'}
								</div>
							</div>
						</label>
						{Array.isArray(item?.metadata) &&
							item.metadata.map(
								({
									key = '',
									description = '',
									label = '',
									type = '',
								}: {
									[key: string]: string;
								}) => {
									// Check if the current item's key is 'payIn' and the type is 'toggle'
									if (item?.key === 'payIn' && type === 'toggle') {
										return (
											<UnitPrice
												description={description}
												label={label}
												name={key}
												id={id ?? ''}
												key={key}
											/>
										);
									}
									// Return an empty fragment for cases that don't match the condition
									return <></>;
								}
							)}
					</>
				);
			}),
		[data, isHideFundsPayout, isDisable, initalValue, isPriceHide, isPricingHide, getActionInfo, handleRadioSelect, id]
	);
	return <div>{renderList}</div>;
};
