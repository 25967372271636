import {
	CardNumberElement,
	useElements,
	useStripe,
} from '@stripe/react-stripe-js';
import { API_URL } from 'constant';
import { useNetwork, useNotification } from 'hooks';
import { useMemo, useState } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import {
	BusinessPaymentCardState,
	CardsListViewState,
	OpenInviteSelectorModal,
	PipelineIdState,
} from 'global-stores';
import { IndetityFlowNavigate } from '../constants';
import { BillingPaymentAddress } from '../store';

export type PaymentAddressError = {
	address1: boolean;
	city: boolean;
	state: boolean;
	zip: boolean;
	address2: boolean;
};

export const usePayment = ({
	setNavigate,
}: {
	setNavigate: (val: string) => void;
}) => {
	const setCardLists = useSetRecoilState(CardsListViewState);
	const selectedPipelineId = useRecoilValue(PipelineIdState);
	const setInviteModalOpen = useSetRecoilState(OpenInviteSelectorModal);
	const setBusinessCard = useSetRecoilState(BusinessPaymentCardState);

	const [isBillingError, setIsBillingError] = useState<PaymentAddressError>({
		address1: false,
		city: false,
		state: false,
		zip: false,
		address2: false,
	});
	const [isLoading, setIsLoading] = useState(false);
	const [billingAddress, setBillingAddress] = useRecoilState(
		BillingPaymentAddress
	);

	const stripe = useStripe();
	const elements = useElements();
	const { successNotification, errorNotification } = useNotification();
	const { post: saveCard } = useNetwork();

	const onHandleBillingAddress = (name: string, value: string) => {
		if (name === 'country' && billingAddress.country !== value) {
			setBillingAddress((prevState: any) => {
				const newState = {
					...prevState,
					state: '',
					city: '',
					zip: '',
				};
				return newState;
			});
		}

		if (name === 'zip' && value.length > 10) return;

		setBillingAddress(prev => ({ ...prev, [name]: value }));
		if (!value.trim()) {
			setIsBillingError(prev => ({ ...prev, [name]: true }));
			return;
		}
		setIsBillingError(prev => ({ ...prev, [name]: false }));
	};

	const onHandleSubmit = async (event: React.FormEvent) => {
		event.preventDefault();
		setIsLoading(true);
		if (!stripe || !elements) {
			// Stripe.js has not loaded yet. Make sure to disable
			// form submission until Stripe.js has loaded.
			return;
		}
		const tokenDetails = await stripe.createToken(
			elements.getElement(CardNumberElement) as any
		);
		if (tokenDetails.error) {
			return errorNotification(tokenDetails.error.message as string);
		}
		if (!tokenDetails) {
			setIsLoading(false);
			return errorNotification('Invalid Card');
		}
		saveCard(API_URL.BUSINESS_CARD, {
			token: tokenDetails.token.id,
			default: true,
		})
			.then(resp => {
				if (resp?.id) {
					setBusinessCard((prev: any) => ({ ...prev, card: resp }));
					setCardLists(prev => [...prev, resp]);
					successNotification('Card Saved Successfully!');
					if (selectedPipelineId) {
						setInviteModalOpen(true);
					}
					setNavigate(IndetityFlowNavigate.Processing);
				} else {
					errorNotification(resp?.message);
				}
			})
			.finally(() => {
				setIsLoading(false);
			});
		return null;
	};

	const isDisabled = useMemo(
		() => Object.values(billingAddress ?? {}).some(el => !el) || !stripe,
		[billingAddress, stripe]
	);

	return {
		onHandleSubmit,
		onHandleBillingAddress,
		isBillingError,
		billingAddress,
		isDisabled,
		isLoading,
	};
};
