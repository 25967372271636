export type IPacketOrigin = 'basic' | 'overlay' | 'onboarding' | 'editable';

export const SignDocSentboxHeader = [
	{
		label: 'Subject',
		key: 'name',
		key2: 'recipients',
		format: 'jsx',
	},
	{
		label: 'Status',
		key: 'status',
		key2: 'statusMessage',
		format: 'jsx',
	},
	{
		label: 'Type',
		key: 'origin',
		format: 'jsx',
	},
	{
		label: 'Face Auth.',
		key: 'authType',
		format: 'jsx',
	},
	{
		label: 'Created At',
		key: 'updatedAtDate',
		key2: 'updatedAtTime',
		format: 'jsx',
	},
	{
		label: 'Actions',
		key: 'actions',
		format: 'jsx',
	},
];

export const PACKET_ORIGIN: {
	[key in Uppercase<IPacketOrigin>]: Lowercase<key>;
} = {
	BASIC: 'basic',
	ONBOARDING: 'onboarding',
	OVERLAY: 'overlay',
	EDITABLE: 'editable',
};
