import { useUpdateUserToken } from 'hooks';
import { useCallback } from 'react';

export function useRetryOperation() {
	const { updateToken } = useUpdateUserToken();

	const checkRetryCondition = useCallback((message: string) => {
		if (
		  message.toLowerCase()?.includes("logged in required") ||
		  message.toLowerCase()?.includes("unauthorized")
		) {
		  return true;
		}
		return false;
	  }, []);

	async function retryOperation(operation: () => Promise<any>): Promise<any> {
		try {
			const result = await operation();
			// Handle unauthorized or forbidden access
			if (result?.status === 401 || result?.status === 403) {
				const isUpdated = await updateToken(); 
				if (isUpdated) {
					const retryResult = await operation();
					return Promise.resolve(retryResult); 
				}
			}

			if (result?.status === 200) {
				return result;
			}

			// Clone the original response object to safely retry reading the JSON body
			const cloneResult = result.clone();
			// Attempt to parse the cloned response body as JSON
			const retryResult = await cloneResult.json();

			if (retryResult?.message) {
                // Check if the message contains "logged in required"
                if (checkRetryCondition(retryResult?.message ?? "")) {
                    // Return the original response for further processing
                    return null;
                } else {
					return result
                    // Handle other messages if necessary
                    throw new Error(`Unexpected message: ${retryResult.message}`);
                }
            } else {
                // If no message is found, throw an error with a meaningful message
                throw new Error(`Unexpected status code: ${result?.status}`);
            }
		} catch (error: any) {
            const errorMessage = error?.response?.data?.message?.toLowerCase() || '';
            if (!errorMessage.includes('logged in required') && !errorMessage.includes('unauthorized')) {
                return Promise.reject(error);
            }
            if (errorMessage.includes('logged in required') || errorMessage.includes('unauthorized')) {
                const isUpdated = await updateToken(); 
                if (isUpdated) {
                    const retryResult = await operation();
                    return Promise.resolve(retryResult); 
                }
            }
        }

	}

	return { retryOperation };
}
