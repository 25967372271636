import { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil';

import { Button, Loader } from '@storybook';
import { UploadedDocumentsState } from './store';
import {
	BrowseFile,
	UploadDocumentNavbar,
	UploadDocumentSuccess,
	UploadedFiles,
} from './components';
import { DocumentsUploaded, ProgressState, useUploadDocuments } from './store';
import { Progress } from './components/progressbar';
import ErrorPage from 'components/error-page/error-page';

export const UploadDocuments = () => {
	const params: any = useParams();
	const {
		fetchUploadedDocuments,
		uploadAllDocument,
		isLoaded: uploadingDocuments,
	} = useUploadDocuments();
	//globle states
	const [oldDocuments, setOldDocuments] = useRecoilState(
		UploadedDocumentsState
	);
	const { data: addedFiles, isLoaded: fileAdditionSuccess } =
		useRecoilValue(DocumentsUploaded);
	const resetAddedFiles = useResetRecoilState(DocumentsUploaded);
	const progress = useRecoilValue(ProgressState);

	// local states
	const [navigateToSuccess, setNavigateToSuccess] = useState(false);

	useEffect(() => {
		fetchUploadedDocuments(params.id);
		resetAddedFiles()
	}, [fetchUploadedDocuments, params.id, resetAddedFiles]);

	const handleClick = useCallback(async () => {
		const payload = {
			id: params.id,
			data:
				addedFiles?.map(item => {
					return {
						type: item.type.value,
						docId: item._id,
						password: item.password,
					};
				}) ?? [],
		};
		const status = await uploadAllDocument(payload);
		if (status) {
			const newUploaded = addedFiles.map(
				({ _id, createdAt, name, password, type, url }) => ({
					_id,
					createdAt,
					name,
					password,
					type,
					url,
					updatedAt: new Date(),
					contentType: '',
				})
			);
			setOldDocuments(prev => ({
				...prev,
				data: [...newUploaded, ...prev.data],
			}));
			setNavigateToSuccess(true);
		}
	}, [addedFiles, params.id, setOldDocuments, uploadAllDocument, setNavigateToSuccess]);

	const submitBtnLabel = useMemo((): string | JSX.Element => {
		return !uploadingDocuments ? (
			<Loader dimension={18} type="loader" className="loader-white" />
		) : (
			'Submit Documents'
		);
	}, [uploadingDocuments]);

	const renderOldDocs = useMemo(() => {
		if (oldDocuments.error) {
			return <ErrorPage />;
		}
		return (
			<>
				<div className="upload-documents">
					<h5 className='upload-documents__title'>Upload your documents</h5>
					<div
						className={`${addedFiles.length > 0 || oldDocuments?.data?.length > 0 ? 'file-uploaded' : 'empty-files'
							}`}
					>
						<BrowseFile />
					</div>
					{!fileAdditionSuccess && (
						<Progress
							label={progress?.data[0]?.name ?? ''}
							uploadPercentage={progress.perentage}
							fileName={''}
						/>
					)}
					<div className="upload-documents__uploaded-docs-wrapper">
						{addedFiles.length > 0 && (
							<UploadedFiles data={addedFiles} type={'documents_uploaded'} />
						)}
						{oldDocuments?.data?.length > 0 && (
							<UploadedFiles
								data={oldDocuments.data}
								type={'previously_uploaded'}
							/>
						)}
					</div>
				</div>
				<div className="upload-documents__footer1">
					<div className="upload-documents__footer1-container">
						<Button
							width="165px"
							disabled={!addedFiles.length || !uploadingDocuments}
							handleClick={handleClick}
							label={submitBtnLabel}
							type="button button__filled--primary"
						/>
					</div>
				</div>
			</>
		);
	}, [
		addedFiles,
		fileAdditionSuccess,
		handleClick,
		oldDocuments.data,
		oldDocuments.error,
		progress?.data,
		progress.perentage,
		submitBtnLabel,
		uploadingDocuments,
	]);

	const renderScreens = useMemo(() => {
		if (!oldDocuments.isLoaded) {
			return (
				<div className="upload-docs-loading">
					<Loader className="loader-blue" dimension={62} />
				</div>
			);
		}
		return renderOldDocs;
	}, [oldDocuments.isLoaded, renderOldDocs]);

	return (
		<div className="upload-documents-body">
			<UploadDocumentNavbar />
			{navigateToSuccess ? <UploadDocumentSuccess fileCount={addedFiles.length}/> : renderScreens}
		</div>
	);
};
