import { isStringURL } from 'helpers';
import { FC, Fragment, useCallback } from 'react';
import { camelCaseToCapitalizedWords } from 'utils';
import { v4 } from 'uuid';
interface IProps {
	label: string;
	value: string | string[];
	isFromAmlRisk?: boolean;
	isFromAML?: boolean;
}

export const BasicSection: FC<IProps> = ({ label, value, isFromAmlRisk,	isFromAML }) => {

	if (!label || !value || value === '-') {
		return <></>;
	}

	// eslint-disable-next-line react-hooks/rules-of-hooks
	const getClassName = useCallback((key: string) => {
		switch (key.toLowerCase()) {
			case 'high':
				return 'risk__high';
			case 'medium':
				return 'risk__medium';
			case 'low':
				return 'risk__low';
			default:
				return 'risk__low';
		}
	}, []);

	// eslint-disable-next-line react-hooks/rules-of-hooks
	const getIconByStatus = useCallback((key: string) => {
		switch (key.toLowerCase()) {
			case 'high':
			case 'medium':
				return 'ri-error-warning-fill';
			case 'low':
				return 'ri-checkbox-circle-fill';
			default:
				return 'ri-checkbox-circle-fill';
		}
	}, []);

	// eslint-disable-next-line react-hooks/rules-of-hooks
	const isCheckArrayOfObject = useCallback((value: any)=>{
		return (
			Array.isArray(value) &&
			(value ?? []).every(item => typeof item === 'object' && item !== null && !Array.isArray(item))
		  );
	},[])

	const renderValue = (val: string | string[]) => {
		if (!Array.isArray(val) && typeof val !== 'string' && (Object.keys(val ?? {}).length > 0)) {
			return 'N/A';
		}
		if (label === 'Source link' && typeof val === 'string') {
			return (
				<div className="source-link">
					<a href={val} target="_blank" rel="noreferrer">
						{val}
					</a>
				</div>
			);
		}
		if (typeof val === 'string' && isStringURL(val)) {
			return (
				<div className="source-link">
					<a href={val} target="_blank" rel="noreferrer">
						{val}
					</a>
				</div>
			);
		}

		if (Array.isArray(val) && val?.every(item => typeof item === 'string')) {
			if (val?.length === 0) {
				return 'N/A';
			}
			return val.map(item => (
				<Fragment key={v4()}>
					{isStringURL(item) ? (
						<div className="source-link">
							<a href={item} target="_blank" rel="noreferrer">
								{item }
							</a>
						</div>
					) : (
						<div>{item?.length > 0 ? item : "N/A"}</div>
					)}
				</Fragment>
			));
		}
		if (typeof val === 'boolean') {
			return <div>{val ? 'true' : 'false'}</div>;
		}
		if (
			isFromAmlRisk &&
			(val === 'High' || val === 'Medium' || val === 'Low')
		) {
			return (
				<div className={`aml-risk__status__wrapper ${getClassName(val ?? '')}`}>
					<i className={getIconByStatus(val)}></i> {val}
				</div>
			);
		}
		return <div>{val?.toString() ?? "N/A"}</div>;
	};

	return (
		<Fragment key={label}>
			{isFromAML && Array.isArray(value) && isCheckArrayOfObject(value) ? (
				<Fragment>
					{value.length !== 0 && <div className="aml-basic-array-label">{label}</div>}
					{value.length !== 0 &&(value ?? []).map(item => (
						<Fragment key={v4()}>
							{Object.entries(item ?? {}).map(([key, value]) =>
								typeof value === 'string' ||
								typeof value === 'number' ||
								typeof value === 'boolean' ? (
									<div
										className="kyb-wrapper__details__kybInfoDetailsRow"
										key={key}
									>
										<div className="kyb-wrapper__details__kybInfoDetailsRow__label">
											{camelCaseToCapitalizedWords(key)}
										</div>
										<div className="kyb-wrapper__details__kybInfoDetailsRow__value">
											<span>:</span>
											<div>{value ?? "N/A"}</div>
										</div>
									</div>
								) : null
							)}
						</Fragment>
					))}
				</Fragment>
			) : (
				<div className="kyb-wrapper__details__kybInfoDetailsRow">
					<div className="kyb-wrapper__details__kybInfoDetailsRow__label">
						{label || '--'}
					</div>

					<div className="kyb-wrapper__details__kybInfoDetailsRow__value">
						<span>:</span>
						<div>{renderValue(value) || '--'}</div>
					</div>
				</div>
			)}
		</Fragment>
	);
};
