import { IKycDetailsKeys } from '../store';

export const kycDetailsObj: { [key in IKycDetailsKeys | any]: string } = {
	idType: 'ID Type',
	state: 'ID State',
	mrzLine1: 'MRZ Line 1',
	mrzLine2: 'MRZ Line 2',
	dateOfExpiry: 'Date Of Expiry',
	dateOfIssue: 'Date Of Issue',
	country: 'ID Country',
	firstName: 'First Name',
	lastName: 'Last Name',
	dateOfBirth: 'Date Of Birth',
	gender: 'Gender',
	address: 'ID Address',
	city: 'ID City',
	zip: 'ID Zip Code',
	ssn: 'Tax ID',
	Ssn: 'Tax ID',
	breakdown: 'KYC Summary About ',
	document: 'Document',
	basicInfo: 'Basic Info',
	frontImage: 'docImage',
	backImage: 'docImage',
	idNumber: 'ID Number',
	conclusion: 'Conclusion',
	// Arun kumar : Now gender will visible in kyc details
	sex: 'sex',
	report: 'Rejection reason',
};

export const KYCIDTYPE: Record<string, string> = {
	DL: 'Drivers License',
	passport: 'Passport',
	'drivers-license': 'Drivers License',
};

export const UserAddressObj: { [key in IKycDetailsKeys | any]: string } = {
	state: 'State',
	country: 'Country',
	firstName: 'First Name',
	lastName: 'Last Name',
	address: 'Address',
	city: 'City',
	zip: 'Zip Code',
};

export const kycReportsData: { [key: string]: string } = {
	idType: 'Identity Type',
	state: 'State',
	country: 'Country',
};
export const BasicInfoLabel: { [key: string]: string } = {
	firstName: 'First Name',
	lastName: 'Last Name',
	dateOfBirth: 'Date Of Birth',
	gender: 'Gender',
	address: 'Address',
	city: 'City',
	state: 'State',
	country: 'Country',
	zip: 'Zip Code',
	phone: 'Phone',
};

export const SSNLabel: { [key: string]: string } = {
	ssn: 'Tax ID',
};

export const KYCDetailsHeader: { [key: string]: string } = {
	document: 'Document',
	basicInfo: 'Basic Info',
	breakdown: 'KYC Summary About ',
	ssn: 'SSN/Tax ID',
	address: 'User Provided Address',
};

export const assigneeStatusAndLabel: {
	reassign: {
		label: string;
		text: string;
	};
	assign: {
		label: string;
		text: string;
	};
	unassign: {
		label: string;
		text: string;
	};
	selfAssign: {
		label: string;
	};
} = {
	reassign: {
		label: 'changed',
		text: 'the reviewer from #from to #to',
	},
	assign: {
		label: 'assigned',
		text: 'the session to #to',
	},
	unassign: {
		label: 'unassigned',
		text: '#from from reviewer',
	},
	selfAssign: {
		label: 'self assigned ',
	},
};