import { IOption, ReactDropdown } from '@storybook';
import axios from 'axios';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import './countery-dropdown.scss';

interface ICountryForm {
	handleUpdateCountryDetails: (data: any) => void;
	defaultValue: any;
	isDisabled: boolean;
}

export const CounteryStateDropdown: FC<ICountryForm> = ({
	handleUpdateCountryDetails,
	defaultValue,
	isDisabled,
}) => {
	const [options, setOptions] = useState([]);
	const [selectedCountry, setSelectedCountry] = useState<IOption>({});
	const [selectedState, setSelectedState] = useState<IOption>({});

	function getAccessToken() {
		return 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbiI6IjY2MmI5OTdhY2EzNzhjODA1NDRlNmE0MyIsImNsaWVudF9pZCI6IjI2ODA5YmU2NTc3ZTRhOWU4OWRiNTdkZTE1NmQzYWM5IiwiYnVzaW5lc3MiOiI2NTU2NzdmMDFiMzY1YzVhMzc5ZGVlNGQiLCJpYXQiOjE3MTQxMzMzNzAsImV4cCI6MTc0NTY2OTM3MH0.dXW1Ursz2qJboJBKAnyWjqRMvSetZPyKovZC7w4pxLk';
	}

	const formatValue = (country?: string): string => {
		return (country?.replace(/_/g, ' ') ?? '--')
			.toLowerCase()
			.replace(/\b\w/g, (char: string) => char.toUpperCase());
	};

	const fetchCountryData = useCallback(async () => {
		const { data } = await axios.get(
			`https://api.beta.satschel.com/v2/pipelines/kyb-countries`,
			{
				headers: {
					Authorization: `Bearer ${getAccessToken()}`,
				},
			}
		);
		const formatedData = data?.data?.map((country: { name: string }) => ({
			label: formatValue(country.name ?? '--'),
			value: country,
		}));
		setOptions(formatedData);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleOnChange = useCallback((event: any) => {
		setSelectedState({});
		setSelectedCountry(event);
	}, []);

	useEffect(() => {
		fetchCountryData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (!selectedCountry?.label) return;
		handleUpdateCountryDetails({
			country: selectedCountry?.value?.name ?? '',
			state: selectedState?.vaue?.name ?? '',
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedCountry, selectedState]);

	const getCountryOption = useCallback(
		(value: any) => {
			if (!options.length) return {};
			return (options as any).find(
				(el: any) =>
					el?.value?.name === value ||
					el?.value?.iso2 === value ||
					el?.value?.iso3 === value
			);
		},
		[options]
	);

	const countryValue = useMemo(() => {
		const countryOption = getCountryOption(defaultValue?.country);
		return selectedCountry?.label ? selectedCountry : { label: countryOption?.label, value: countryOption?.value };
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [defaultValue?.country, selectedCountry, options]);


	return (
		<div className="CounteryStateDropdown__container">
			<ReactDropdown
				options={options}
				isSearchable={true}
				handleChangeSelect={handleOnChange}
				optionsDropHeight={230}
				label={'Country'}
				value={countryValue}
				placeholder={'Country'}
				key={'Country'}
				Isdisable={isDisabled}
			/>
		</div>
	);
};
