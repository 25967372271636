import { Button, Loader } from '@storybook';
import Modal from '@storybook/new-modal/modal';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';
import { SessionTreeGraph } from 'views/complex-onboarding-flow/components';
import { IsComplexStepRecipientInviteModalOpen, SignAgreementComplexStepConfigured } from 'views/multi-sign-agreement';
import { SelectedQRInstanceState, useOnboardingQrFlow } from '../onboarding-qr-flow';
import { ComplexConfigStep } from '../store';
import { useTrackEvents } from 'helpers';
import { EVENTS_TRACKING } from 'hooks';

type Props = {
	isOpen: boolean;
	closeModal: () => void;
	details: any;
	isOnboardingList?: boolean;
};

export const ComplexStepsRecipientInviteView = ({
	isOpen,
	details,
}: Props) => {

	const [isSubmitLoading, setIsSubmitLoading] = useState(false);
	const setComplexStepRecipientInviteModalIsOpen = useSetRecoilState(IsComplexStepRecipientInviteModalOpen);
	const QRInstancesForMultisign = useRecoilValue(SelectedQRInstanceState);
	const resetComplexStepRecipientInviteModal = useResetRecoilState(IsComplexStepRecipientInviteModalOpen);
	const resetQRInstancesForMultisign  = useResetRecoilState(SelectedQRInstanceState);
	const resetConfigData  = useResetRecoilState(ComplexConfigStep);
	const multiSignConfigurationStatus = useRecoilValue(
		SignAgreementComplexStepConfigured
	);

	const { reset } = useOnboardingQrFlow();
	const {
		handleNext,
	} = useOnboardingQrFlow();
	const {trackEvents} = useTrackEvents()
	
	const handleBack = useCallback(() => {
		setComplexStepRecipientInviteModalIsOpen(false);
	},[setComplexStepRecipientInviteModalIsOpen]);
	
	const handleNextButtonForCsv = useCallback(() => {
		setIsSubmitLoading(true);
		handleNext();
		trackEvents((EVENTS_TRACKING.INVITE_SENT as any), {invite_method: 'captable'});
	},[handleNext, setIsSubmitLoading, trackEvents]);
	
	const overlayTemplateIds = useMemo(() => {
		return QRInstancesForMultisign?.reduce<string[]>((templateIds, { node, templateType }) => {
		  if (node && templateType === 'overlay') templateIds.push(node);
		  return templateIds;
		}, []) || [];
	}, [QRInstancesForMultisign]);

	const isSubmitDisbaled = useMemo(() => {
		const allOverlay = QRInstancesForMultisign?.filter(item => item.templateType === 'overlay'); 
		const allMultiSignStepConfigured = Object.keys(
			multiSignConfigurationStatus
		).filter(node => multiSignConfigurationStatus[node]?.status === 'configured');

		if(allOverlay?.length === allMultiSignStepConfigured.length)
			return false
		return true
	},[QRInstancesForMultisign, multiSignConfigurationStatus])

	useEffect(() => {
		return () => {
			resetConfigData();
			resetComplexStepRecipientInviteModal();
			resetQRInstancesForMultisign();
			reset();
		}
	},[resetComplexStepRecipientInviteModal, resetQRInstancesForMultisign, reset, resetConfigData])

	return (
		<Modal
			isOpen={isOpen}
			modalName="Complex Onboarding Flow Recipient Invite"
			closeModal={handleBack}
			className="conditional-onboarding-details"
			showCloseBtn={true}
			isStopOutsideClick
			title={
				// Gaurav: Changed title and description according to new design.
				<div className="conditional-onboarding-details__title-wrapper">
					<div className="conditional-onboarding-details__title">
						Onboarding flow
					</div>
					
					<div className="conditional-onboarding-details__sub-title">
						Please provide all these required details
					</div>
				</div>
			}
		>
			
			<div className='onboarding-session-tree-graph--container'>

			<SessionTreeGraph details={details} isRecipientInvite={true} overlayTemplateIds={overlayTemplateIds} isInvite />
			</div>
				
			
			<div className="OnboardingInviteModal-btn">
				<Button
					handleClick={handleBack}
					label={'Close'}
					type="button__filled button__filled--secondary button__large"
				/>
				<Button
					handleClick={handleNextButtonForCsv}
					label={
						isSubmitLoading ? (
							<Loader
								type="loader"
								dimension={20}
								className="loader-white"
							/>
						) : (
							<>Submit</>
						)
					}
					type="button__filled button__filled--primary button__large"
					disabled={isSubmitDisbaled || isSubmitLoading}
				/>
			</div>
		</Modal>
	);
};
