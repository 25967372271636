import { useCallback } from 'react';
import axios from 'axios';

import { base64ToBinary, convertFileToBase64, formatBase64 } from 'utils'; // Utility functions for file conversion
import { API_URL } from 'constant'; // Application constants, including API endpoints
import { envHost } from 'helpers'; // Helper to retrieve environment-related configurations
import { useNetwork } from 'hooks/network'; // Custom hook for network operations

export const useFileUpload = () => {
	const { post } = useNetwork(); // Extract the `post` function from a custom network hook

	/**
	 * Function to fetch a pre-signed upload URL for a given file name.
	 * This URL is used to upload the file directly to GCP.
	 */
	const gerUploadUrl = useCallback(
		async (fileName: string) => {
			// Send a POST request to the backend to get the pre-signed URL for the file
			const response = await post(API_URL.PRE_SIGN_UPLOAD, {
				filename: fileName || '', // Ensure an empty string is passed if fileName is undefined
			});

			// Return the response or null if no response is received
			return response ?? null;
		},
		[post] // Dependency on the `post` function
	);

	/**
	 * Function to upload a file to GCP using a pre-signed URL.
	 * Converts the file to binary before sending it to GCP.
	 */
	const uploadOnGcp = useCallback(
		async (url: string, file: File) => {
			try {
				// Step 1: Convert the file to Base64
				const base64 = await convertFileToBase64(file);
				if (!base64) throw new Error('Failed to convert file to Base64.');

				// Step 2: Format the Base64 string (e.g., remove metadata)
				const formattedBase64 = formatBase64(base64 as string);
				if (!formattedBase64) throw new Error('Failed to format Base64 data.');

				// Step 3: Convert the formatted Base64 string to binary (Uint8Array)
				const fileBinary = base64ToBinary(formattedBase64);
				if (!fileBinary) throw new Error('Failed to convert Base64 to binary.');

				// Step 4: Send a PUT request to the pre-signed URL with the binary data
				const response = await axios.put(url, fileBinary, {
					headers: {
						'Content-Type': file?.type ?? '', // MIME type of the file
						'x-goog-meta-presignedupload': 'true', // Metadata indicating a presigned upload
						'x-goog-meta-apiDomain': envHost, // Additional metadata with the environment host
					},
				});

				// If the upload is successful, return a success response
				if (response.status === 200) {
					return { success: true };
				}

				// Throw an error if the response is not successful
				throw new Error('Failed to upload the file to GCP.');
			} catch (error) {
				// Return null if any error occurs during the process
				return null;
			}
		},
		[] // No dependencies required
	);

	/**
	 * Main function to handle document upload:
	 * 1. Fetches the pre-signed upload URL from the backend.
	 * 2. Uploads the file to GCP using the pre-signed URL.
	 * 3. Returns the document ID if successful.
	 */
	const uploadDoc = useCallback(
		async (file: File) => {
			// Step 1: Get the pre-signed URL for the file upload
			const uploadResp = await gerUploadUrl(file?.name ?? '');

			// Destructure the response to retrieve the upload URL and document ID
			const { uploadUrl, documentId, message } = uploadResp ?? {};

			// Step 2: If the upload URL is available, proceed to upload the file to GCP
			if (uploadUrl) {
				const resp = await uploadOnGcp(uploadUrl, file);

				// Step 3: If the upload is successful, return the response along with the document ID
				return resp ? { ...resp, documentId, message } : resp;
			} else {
				// Return null if no upload URL is available
				return message ? { success: false, documentId: '', message } : null;
			}
		},
		[gerUploadUrl, uploadOnGcp] // Dependencies: `gerUploadUrl` and `uploadOnGcp` functions
	);

	// Return the main document upload function for external use
	return { uploadDoc };
};
