import { IMetadata, IService, ISummaryBilling, Ibreakdown } from '../type';

import { useCallback, useEffect, useMemo, useState } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import { API_URL } from 'constant';
import { useTrackEvents } from 'helpers';
import { useNetwork } from 'hooks';
import { billingDetailState, CostBreakdownDetailState } from '../../stores';
import {
	BillingDetailsLoaderState,
	SelectedDateRangeState,
} from 'global-stores';
import { useFormatDate } from './farmatDate';

export const useSummaryBilling = () => {
	const { trackEvents } = useTrackEvents();
	const { formatDate } = useFormatDate();

	const [billingDetailData, setBillingDetailData] =
		useRecoilState(billingDetailState);
	const dateRange = useRecoilValue(SelectedDateRangeState);
	const setBillingLoaderState = useSetRecoilState(BillingDetailsLoaderState);
	const setCostBreakdownDetails = useSetRecoilState(CostBreakdownDetailState);

	const [showSelectedBillingDetail, setShowBillingSelectedDetail] = useState<
		ISummaryBilling | any
	>({});

	//network
	const { get: getBillingData } = useNetwork({ updateState: false });
	const { endDate, startDate }: any = dateRange?.[0] ?? {};
	const fromDates = formatDate(startDate);
	const endDates = formatDate(endDate);
	const timeZoneOffset = new Date().getTimezoneOffset();

	const fetchBillingsDetails = useCallback(async () => {
		setBillingLoaderState(true);
		const resp = await getBillingData(
			`${API_URL.BILLING}?timeZoneOffset=${timeZoneOffset}&from=${fromDates}&to=${endDates}`
		);
		if (resp?.message === 'ok') {
			const { data } = resp;
			const breakdown: Ibreakdown[] = []
			let breakdownTotal = 0
			let billingStartDate = null
			let billingEndDate = null
			const calData = data.map((item: ISummaryBilling) => {
				const totalOnboardingServiceAmount = item.services.onboarding.reduce((prev, cur) => {
					if (cur.key === 'onboardingService') {
						return (parseFloat((cur.price * cur.quantity).toFixed(2)) + prev)
					}
					return prev + 0
				}, 0)
				const totalSimpliciSignServiceAmount = item.services.simpliciSign.reduce((prev, cur) => {
					if (cur.key === 'simpliciSignService') {
						return (parseFloat((cur.price * cur.quantity).toFixed(2)) + prev)
					}
					return prev + 0
				}, 0)
				const usage = item.totalAmount - (totalOnboardingServiceAmount + totalSimpliciSignServiceAmount)
				const subscription = (item.totalAmount - usage)
				breakdownTotal += usage + subscription
				breakdown.push({
					name: item.name ?? '',
					businessId: item.businessId,
					usage: usage,
					subscription: subscription,
				})
				if(item.type === "mainBusiness" && item?.billingCycle){
					billingStartDate = item.billingCycle.start
					billingEndDate = item.billingCycle.end
				}
				return { ...item, totalOnboardingServiceAmount, totalSimpliciSignServiceAmount }
			})
			setCostBreakdownDetails({
				total: breakdownTotal,
				breakdown: breakdown,
				startDate: billingStartDate,
				endDate: billingEndDate
			})
			trackEvents('billing-info', { details: calData?.services });
			setBillingDetailData(calData);
		}
		setBillingLoaderState(false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [endDates, fromDates, timeZoneOffset]);

	const findMainBusiness = useMemo(() => {
		return billingDetailData.find(item => item.type === 'mainBusiness');
	}, [billingDetailData]);

	useEffect(() => {
		setShowBillingSelectedDetail(findMainBusiness);
	}, [findMainBusiness]);

	const findSubAccountBusiness: any = useCallback(
		(subAccountId: string) => {
			return billingDetailData.find(item => item.businessId === subAccountId);
		},
		[billingDetailData]
	);

	const defaultMainOption = useMemo(() => {
		if (findMainBusiness?.name) {
			return [
				{ label: findMainBusiness?.name, value: findMainBusiness?.businessId },
			];
		}
		return [];
	}, [findMainBusiness?.businessId, findMainBusiness?.name]);

	const mergeMetadata = (
		metadata1: IMetadata[] = [],
		metadata2: IMetadata[] = [],
		type: 'merge' | 'remove'
	): IMetadata[] => {
		const metadataMap = new Map<string, IMetadata>();

		[...metadata1, ...metadata2].forEach(item => {
			if (metadataMap.has(item.key)) {
				const existingItem = metadataMap.get(item.key)!;

				metadataMap.set(item.key, {
					...existingItem,
					quantity:
						type === 'merge'
							? existingItem.quantity + item.quantity
							: existingItem.quantity - item.quantity,
				});
			} else {
				metadataMap.set(item.key, item);
			}
		});

		return Array.from(metadataMap.values());
	};
	const mergeServices = (
		services1: IService[],
		services2: IService[],
		type: 'merge' | 'remove'
	): IService[] => {
		const servicesMap = new Map<string, IService>();

		[...services1, ...services2].forEach(item => {
			if (servicesMap.has(item.key)) {
				const existingItem = servicesMap.get(item.key)!;
				servicesMap.set(item.key, {
					...existingItem,
					quantity:
						type === 'merge'
							? existingItem.quantity + item.quantity
							: existingItem.quantity - item.quantity,
					metadata: mergeMetadata(existingItem.metadata, item.metadata, type),
				});
			} else {
				servicesMap.set(item.key, item);
			}
		});

		return Array.from(servicesMap.values());
	};

	const mergeData = (
		data1: ISummaryBilling,
		data2: ISummaryBilling,
		type: 'merge' | 'remove'
	): ISummaryBilling => {
		return {
			...data1,
			totalAmount:
				type === 'merge'
					? data1.totalAmount + data2.totalAmount
					: data1.totalAmount - data2.totalAmount,
			services: {
				onboarding: mergeServices(
					data1.services.onboarding,
					data2.services.onboarding,
					type
				),
				simpliciSign: mergeServices(
					data1.services.simpliciSign,
					data2.services.simpliciSign,
					type
				),
				simpliciText: mergeServices(
					data1.services.simpliciText,
					data2.services.simpliciText,
					type
				),
			},
		};
	};

	return {
		fetchBillingsDetails,
		showSelectedBillingDetail,
		setShowBillingSelectedDetail,
		findMainBusiness,
		findSubAccountBusiness,
		mergeData,
		defaultMainOption,
		fromDates,
	};
};
