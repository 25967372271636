import { Button, Image, Loader, ReactDropdown, ReactSwitch } from '@storybook';
import { FC, Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';

import { ExportPdf } from '../export-pdf';
import { renderColor } from './util';
import { useSessionDetailsActions } from 'views/sessions/hooks/use-session-actions';
import { DownloadActions } from 'views/sessions/constants';
import {
	ComplexSelectedSession,
	ComplexSelectedSessionAssigneeIDState,
	ComplexSessionInfoState,
	ComplexSessionNodesState,
	ConditionalSessionFlowState,
	FundDetailState,
	loginState,
	PreviousSessionDataState,
	SelectedSessionsIdState,
	SessionItemSelected,
	SessionState,
	userDataState,
} from 'global-stores';
import { useComplexSessionInfo } from 'views/sessions/hooks';
import { ColorLegends } from '../color-legends';
import { InboxSignModal } from 'views/signed-doc-inbox/components/inbox-sign-modal';
import { useNotification} from 'hooks';
import { useSignedDocsInbox } from 'views/signed-doc-inbox/store/hooks';
import { REACT_APP_ARQ_SECURITIES_BUSINESSID } from 'envs';
import classNames from 'classnames';
import { AssignReviewerModal } from '../assign-reviewer-modal/assign-reviewer-modal';
import { AssignSelectedSessionState, dropDownSelectedReviewerState } from '../select-fund-modal';
import { useSessionAssign } from 'views/sessions/hooks/use-session-assign';
import { AssigneeSessionState, IsAssigneeSessionLoadingState } from 'views/sessions/store';
import { Assignee } from '../assign-dropdown/ReviewerDropdown';
import { PERMISSION_SCREEN, useUserRoles } from 'views';
interface IKycDetailsHeader {
	isTransactionPage?: boolean;
	handleBackModal?: () => void;
	sessionSelected: {
		name?: string;
		_id?: string;
		type?: string;
		representativeId?: string;
		nodes?: [{stepId: string, envelopeId: string}];
		businessId?: string;
	};
	isComplexFlow: boolean;
	setIsComplexFlow: any;
	getLabel?: (val: string) => any;
	isFundView?: boolean;
	isShowTreeFlow?: boolean;
}

export const SessionDetailsHeader: FC<IKycDetailsHeader> = ({
	sessionSelected,
	handleBackModal,
	isTransactionPage,
	isComplexFlow,
	getLabel,
	setIsComplexFlow,
	isFundView = false,
	isShowTreeFlow = true,
}) => {
	const isComplexTab = useRecoilValue(ConditionalSessionFlowState);
	const complexSelectedSession = useRecoilValue(ComplexSelectedSession);
	const complexSelectedSessionAssigneeID = useRecoilValue(ComplexSelectedSessionAssigneeIDState);
	const sessionList = useRecoilValue(SessionState);
	const selectedIdState = useRecoilValue(SelectedSessionsIdState);
	const fundDetailList = useRecoilValue(FundDetailState);
	const setSessionItemSelected = useSetRecoilState(SessionItemSelected);
	const setComplexSessionNodes = useSetRecoilState(ComplexSessionNodesState);
	const setSelectedIdState = useSetRecoilState(SelectedSessionsIdState);
	const { complexSessionInfo } = useComplexSessionInfo();
	const previousSessionData = useRecoilValue(PreviousSessionDataState);
	const resetComplexSessionInfo = useResetRecoilState(ComplexSessionInfoState);
	const { exportCsv, exportPdf } = useSessionDetailsActions();
	const [showSignModal, setShowSignModal] = useState<boolean>(false);
	const [signLoader, setSignLoader] = useState<boolean>(false);
	const [signPrepareUrl, setSignPrepareUrl] = useState<{ env: string; url: string }>({
		env: '',
		url: '',
	});

	const { signDocInbox, getSignedDocsInbox } = useSignedDocsInbox();
	const { checkUserWritePermission } = useUserRoles();
	const { successNotification } = useNotification();
	const userDetail = useRecoilValue(loginState);

	const setSelectedSession = useSetRecoilState(AssignSelectedSessionState);
	const [openAssignReviewer, setOpenAssignReviewer] = useState(false);
	const { AssignReviewer } = useSessionAssign();
	const sessionAssignee = useRecoilValue(AssigneeSessionState);
	const isAssigneeSessionLoading = useRecoilValue(IsAssigneeSessionLoadingState);
	const getDropDownSelectedReviewer = useRecoilValue(dropDownSelectedReviewerState);
	const loginStateData = useRecoilValue(userDataState);
	const SessionListFullStatus = useMemo(() => {
		if (isFundView) {
			return fundDetailList.find(
				value => value.sessionId === selectedIdState[0]
			)?.fullStatus;
		} else {
			return sessionList.find(value => value._id === selectedIdState[0])
				?.fullStatus;
		}
	}, [selectedIdState, sessionList, fundDetailList, isFundView]);

	const handleChangeDownloadAction = useCallback((event: any) => {
		const { value } = event ?? {};
		if (value === 'csv') {
			exportCsv(sessionSelected._id ?? '', sessionSelected._id ?? 'session');
		} else {
			exportPdf([sessionSelected._id], 'pdf');
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// @paras: this back button handler is only for kyc-aml verification members screen
	const handleKycAmlBackButton = useCallback(() => {
		if (previousSessionData) {
			const sessionId = previousSessionData?._id;
			const allNodes = previousSessionData?.nodes?.map(el => {
				return {
					...el,
					visited: true,
					session: sessionId || '',
				};
			});
			resetComplexSessionInfo();
			setComplexSessionNodes(allNodes ?? []);
			complexSessionInfo(sessionId ?? '');
			setSessionItemSelected({
				...previousSessionData,
				type: 'complex',
				name: previousSessionData?.name ?? 'NaN',
				_id: sessionId || '',
			});
			setSelectedIdState([sessionId ?? '']);
		}
	}, [
		complexSessionInfo,
		previousSessionData,
		setComplexSessionNodes,
		setSelectedIdState,
		setSessionItemSelected,
		resetComplexSessionInfo,
	]);

	const signNodeData: any = useMemo(() => {
		return sessionSelected?.nodes?.find(el => el.stepId === 'signAgreement' && !!el?.envelopeId)
	}, [sessionSelected])

	const signBtnLabel = useMemo(() => {
		return signLoader ? (
			<div className="loader-wrapper">
				<Loader className="loader-white" dimension={20} type="loader" />
			</div>
		) : (
			signNodeData?.action?.status === 'completed' ? 'Signed' :'Sign/View'
		);
	}, [signLoader, signNodeData]);

	const handleSignDoc = useCallback(async () => {
		setSignLoader(true);
		const response = await signDocInbox(
			{
				envelopeId: signNodeData?.envelopeId ?? '',
				recipientEmail: userDetail.email,
			},
			setSignLoader
		);
		const { url, env } = response ?? {};
		if (url && env) {
			setSignPrepareUrl({ url, env });
			setShowSignModal(true);
		}
	}, [signDocInbox, signNodeData, userDetail.email]);

	const handleCloseIframe = useCallback(async () => {
		setShowSignModal(false);
		setSignPrepareUrl({ env: '', url: '' });
	}, []);

	const handleSubmitModal = useCallback(() => {
		handleCloseIframe();
		setTimeout(() => {
			successNotification('Document Signed Successfully.');
			getSignedDocsInbox(false);
		}, 1000);
	}, [getSignedDocsInbox, handleCloseIframe, successNotification]);

	const checkSessionCompleted = useMemo(() => {
		if(complexSelectedSession?.status !== "completed" ){
			return false;
		}
		const filterdNodes = complexSelectedSession.nodes.filter((el: any) => el.visited && el.stepId !== 'authentication' && el.stepId !== 'signAgreement');
		return filterdNodes.every((el: any) => el.action.status === 'completed')
	}, [complexSelectedSession])

	useEffect(() => {
		
		if (complexSelectedSessionAssigneeID?.length > 0|| complexSelectedSessionAssigneeID ===null ) {
			AssignReviewer();
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [ complexSelectedSessionAssigneeID]);

	const reviewers = useMemo(
		() =>
			sessionAssignee.map((assignee: Assignee) => ({
				id: `${assignee?._id}`,
				name: assignee?._id === loginStateData.id
					? 'Self assigned'
					: `${assignee.firstName} ${assignee.lastName}`,
				image: `${assignee.image}`,
			})),
		[loginStateData.id, sessionAssignee]
	);

	const getAssignedReviewer = useMemo(
		() =>
			reviewers.filter(
				(reviewer: { id: string }) =>
					reviewer.id === complexSelectedSessionAssigneeID
			),
		[reviewers, complexSelectedSessionAssigneeID]
	);

	const showUserImage = useMemo(() => {
		const image = getAssignedReviewer[0]?.image;
		const selectedReviewerImage = getDropDownSelectedReviewer
			.map(reviewer => reviewer.image)
			.join(', ');
		return selectedReviewerImage || image;

	}, [getAssignedReviewer, getDropDownSelectedReviewer]);
	

	const showUserName = useMemo(() => {
		const selectedReviewerNames = getDropDownSelectedReviewer
			.map(reviewer => reviewer.name)
			.join(', ');
		const reviewerName = selectedReviewerNames || getAssignedReviewer[0]?.name;
		if (reviewerName && reviewerName!=='Unassign') {
			return reviewerName
		} else {
			return 'Assign Reviewer';
		}
	}, [getDropDownSelectedReviewer, getAssignedReviewer]);

	const classes = useCallback(
		(check: boolean) =>
			classNames('ri-arrow-down-s-line', {
				'filter-popup__opened': check,
				'filter-popup__closed': !check,
			}),
		[]
	);

	const isUserPermissionWrite = useMemo(
		() => checkUserWritePermission(PERMISSION_SCREEN.Sessions),
		[checkUserWritePermission]
	);
	
	const handleClickAssignReviewer = useCallback(() => {
		if (isUserPermissionWrite) {
			setOpenAssignReviewer(true);
			setSelectedSession([sessionSelected]);
		}
		return;
	}, [isUserPermissionWrite, sessionSelected, setSelectedSession]);

	const renderAssineeImg = useCallback(() => {
		if(showUserImage === 'null') {
			return (
				<div className="unassign-defult-image">
					<i className="ri-user-line" />
				</div>
			);
		} else {
			return <Image url={showUserImage} className="reviewer-image" />;
		}
	},[showUserImage])
	return (
		<div className="kyc--Details--header--sticky">
			<div
				className={`kycDetails--header--export-pdf kycDetails--header--sticky ${
					isTransactionPage ? 'kyc-details-transaction-top-header' : ''
				}`}
			>
				<div className="kycDetails--header_detail kycDetails--header--sticky align__back_icon">
					{isTransactionPage && (
						<div onClick={handleBackModal} className="arrow__left_icon_main">
							<i className="ri-arrow-left-line fa-lg" />
						</div>
					)}
					{/* @paras: this back button is only for kyc-aml verification members screen */}
					{sessionSelected?.representativeId?.length && (
						<div
							onClick={handleKycAmlBackButton}
							className="arrow__left_icon_main"
						>
							<i className="ri-arrow-left-line fa-lg" />
						</div>
					)}

					<Fragment>
						<div className="kycUserName">
							<span>{sessionSelected.name}</span>
						</div>
						{!isComplexTab && !isTransactionPage && (
							<div className="Session-show-status">
								{Object.keys(SessionListFullStatus ?? {})?.map(keys => {
									const colors: any = renderColor(
										keys,
										SessionListFullStatus?.[keys as any] as string
									);

									return (
										<div
											key={'status-circle' + keys}
											className="Session-show-status-contain"
										>
											<div
												className="Session-show-status-circle"
												style={{ backgroundColor: colors.color }}
											/>
											<div>
												{getLabel && getLabel(keys)}
												{colors.status?.length > 0 && ` (${colors.status})`}
											</div>
										</div>
									);
								})}
							</div>
						)}
					</Fragment>
				</div>
				<div className="kycDetails--header--toggleContainer">
					{isComplexTab && isShowTreeFlow && (
						<div
							className="kycDetails--header--toggleContainer_togglebtn
						"
						>
							<ColorLegends title={'Status & Flow Legends:'} />
							<div className="kycDetails--header--toggleContainer_row">
								<div className="kycDetails--header--toggleContainer_text">
									Tree Flow
								</div>
								<ReactSwitch
									id={''}
									onColor="#33b87a"
									checked={isComplexFlow}
									handleChange={() =>
										setIsComplexFlow((prev: boolean) => !prev)
									}
								/>
							</div>
						</div>
					)}
					{/* // pradeep : removed complex condition for download zip */}
					{
						<div className="kycDetails--header-btn-container">
							{sessionSelected.type === 'complex' ? (
								<>
									{!!signNodeData &&
										checkSessionCompleted &&
										userDetail.business ===
											REACT_APP_ARQ_SECURITIES_BUSINESSID &&
										(signNodeData?.action?.status === 'processing' ||
											signNodeData?.action?.status === 'completed') && (
											<Button
												label={signBtnLabel}
												handleClick={() => handleSignDoc()}
												disabled={
													signLoader ||
													signNodeData?.action?.status === 'completed'
												}
												type="button__filled--primary sign-btn"
											/>
										)}
									<div className="assigned-user-dropdown">
										<div
											className={isUserPermissionWrite ? "assigned-user-dropdown-header":"assigned-user-dropdown-header-disable"}
											onClick={handleClickAssignReviewer}
										>
											<div className="assignee-content">
												{isAssigneeSessionLoading ? (
													<div className="assignee-session-loading">
														<Loader type="loader" dimension={20} />
													</div>
												) : (
													<>
															{showUserName !== 'Assign Reviewer' &&renderAssineeImg()}
														<span
															className={
																showUserName !== 'Assign Reviewer'
																	? 'assignee-username'
																	: ''
															}
														>
															{showUserName}
														</span>
													</>
												)}
											</div>

											<i className={classes(openAssignReviewer && !isAssigneeSessionLoading)}></i>
										</div>

										{openAssignReviewer && !isAssigneeSessionLoading && (
											<AssignReviewerModal
												isOpen={openAssignReviewer}
												handleClose={() => {
													setOpenAssignReviewer(false);
												}}
												isCurrentUser={true}
											/>
										)}
									</div>
									<ReactDropdown
										handleChangeSelect={handleChangeDownloadAction}
										options={DownloadActions}
										defaultValue={{ label: 'Download' }}
										controlStyle={{ width: 215 }}
									/>
								</>
							) : (
								<ExportPdf
									sessionIDs={[sessionSelected._id]}
									type={'pdf'}
									label={'Download'}
								/>
							)}
						</div>
					}
				</div>
			</div>
			{showSignModal && (
				<InboxSignModal
					modalVisibility={showSignModal}
					handleCloseIframe={handleCloseIframe}
					prepareUrl={signPrepareUrl}
					handleSubmitModal={handleSubmitModal}
				/>
			)}
		</div>
	);
};
