import { ChangeEvent, FC, useCallback, useMemo, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';

import { Image, Loader } from '@storybook';
import {
	kycSettingsState,
	loginState,
	whiteLableLogoState,
} from 'global-stores';
import { convertFileToBase64 } from 'utils';
import { useNetwork, useNotification } from 'hooks';
import { API_URL, BRAND_LOGO, message } from 'constant';
import { CompanyDetailsEdit } from './company-details-edit-modal';

import { checkHttpUrl } from 'helpers';

interface ICompanyDetails {
	isUserPermissionWrite?: boolean;
}

export const CompanyDetails: FC<ICompanyDetails> = ({ isUserPermissionWrite = true }) => {
	//globle state
	const [kycSettings, setKycSettings] = useRecoilState(kycSettingsState);
	const userDetail = useRecoilValue(loginState);
	const { linkedInUrl, companyName, websiteUrl } = userDetail;
	const isLogoLoading = useRecoilValue(whiteLableLogoState);

	// hooks
	const { successNotification, errorNotification } = useNotification();

	//local state
	const [isOpenModal, setIsOpenModal] = useState(false);

	const { post: changeSettings, loading, setLoading } = useNetwork();
	const { logo, name, settings } = useMemo(() => kycSettings, [kycSettings]);
	const { brandColor, bodyFont } = settings ?? {};

	const renderCompanyDetailsBottom = useMemo(() => {
		return (
			<>
				<div className="brand-color">
					<div className="brand-color__icon company-details-icon">
						<i
							className="ri-checkbox-blank-circle-fill"
							style={{ color: brandColor ? brandColor : '#0051cc' }}
						/>
					</div>
					<div className="brand-color__text company-details-text">
						Brand Color 
					</div>
					<div className="brand-color__code company-details-code">
						{brandColor !== '' ? (brandColor ?? "").toUpperCase() : '#0051cc'}
					</div>
				</div>
				{/* <div className="font-for-header">
          <div className="font-for-header__icon company-details-icon">
            <img loading="lazy"src={fontForheader} alt="" />
          </div>
          <div className="font-for-header__text company-details-text">
            Font for headers
          </div>
          <div className="font-for-header__code company-details-code">
          {headerFont.family !=="" ? headerFont.family : "Poppins"}
           </div>
        </div> */}
				<div className="font-for-body">
					<div className="font-for-body__icon company-details-icon">
						<Image fileName='font-for-body.svg' />
					</div>
					<div className="font-for-body__text company-details-text">
						Font-Family
					</div>
					<div className="font-for-body__code company-details-code">
						{bodyFont?.family !== '' ? bodyFont?.family : 'Poppins'}
					</div>
				</div>
			</>
		);
	}, [bodyFont, brandColor]);

	const handleEditClick = useCallback(() => {
		setIsOpenModal(true);
	}, []);

	const handleLogoChange = useCallback(
		async (e: ChangeEvent<HTMLInputElement>) => {
			const file = e.target.files?.[0];
			if(!file) return;
			
			if (file && file?.size > 1 * 5000 * 1024) {
				errorNotification(message.FILE_SIZE_ERROR);
				return;
			}
			setLoading(true);
			const fileName: any = await convertFileToBase64(file);
			const logo = fileName.toString();

			changeSettings(`${API_URL.CLIENT_DETAILS}`, { logo })
				.then(resp => {
					if (Object.keys(resp).length !== 0) {
						setKycSettings(prev => ({ ...prev, logo }));
						successNotification(message.UPDATE_SUCCESS_MESSAGE);
					}
				})
				.catch(() => ({}))
                 .finally(()=>{
					setLoading(false)
				 })
		},
		[setLoading, changeSettings, errorNotification, setKycSettings, successNotification]
	);

	return (
		<>
			<div className="company-details-top">
				<div className="company-details">
					<div className="company-details__logo">
						{!isLogoLoading && !loading ? (
							<Image
								loading="lazy"
								url={logo || BRAND_LOGO}
								fileName="companyLogo"
							/>
						) : (
							<Loader type="loader" dimension={20} className="loader-blue" />
						)}
					</div>
					<div>
						<div className="company-details__name">
							{name || companyName || ''}
						</div>
						<div className="company-details__url">
							{!!websiteUrl && (
								<div className="company-details__url_wrapper">
									<i className="ri-global-line company-details__url_website" />
									<a
										className="company-details__url_wrapper__social-link"
										href={checkHttpUrl(websiteUrl)}
										target="_blank"
										rel="noreferrer"
									>
										{websiteUrl}
									</a>
								</div>
							)}
							{!!linkedInUrl && (
								<div className="company-details__url_wrapper">
									<i className="ri-linkedin-box-fill company-details__url_linkedin" />
									<a
										className="company-details__url_wrapper__social-link"
										href={checkHttpUrl(linkedInUrl)}
										target="_blank"
										rel="noreferrer"
									>
										{linkedInUrl}
									</a>
								</div>
							)}
						</div>
					</div>
					{isUserPermissionWrite && (
						<div className="company__upload-logo">
							<label
								htmlFor="upload-logo"
								className="company-logo-container__upload-container__browser-txt"
							>
								Browse 
							</label>
							<input
								type="file"
								id="upload-logo"
								accept="image/png, image/jpeg"
								onChange={handleLogoChange}
								hidden
							/>
						</div>
					)}
				</div>
				{/* this section has been hidden for now because wo do not support change in the general settings for now */}
				{isUserPermissionWrite && (
					<i onClick={handleEditClick} className="ri-edit-line edit-icon" />
				)}
				<CompanyDetailsEdit
					isOpenModal={isOpenModal}
					setIsOpenModal={setIsOpenModal}
				/>
			</div>
			{/* this has been hidden for now as we do not support font color and fontSize change */}

			<div className="company-details-bottom">{renderCompanyDetailsBottom}</div>

			{/* Font change  i will work on this tomorrow */}
			{/* <div>
        Font:
        {BRAND_FONT_SIZE.map((brandFont) => {
          return (
            <span
              style={{ marginLeft: 12 }}
              className="theme_icon"
              onClick={() => handleChangeFont(brandFont.class)}
            >
              {brandFont.font}
            </span>
          );
        })}
      </div> */}
		</>
	);
};
