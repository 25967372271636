export const ESIGN_DRAFT_HEADERS = [
	{
		label: 'Subject',
		key: 'name',
		key2: 'recipients',
		format: 'jsx',
	},
    {
		label: 'Face Auth.',
		key: 'authType',
		format: 'jsx',
	},
	{
		label: 'Created At',
		key: 'updatedAtDate',
		key2: 'updatedAtTime',
		format: 'jsx',
	},
	{
		label: 'Actions',
		key: 'actions',
		format: 'jsx',
	},
];