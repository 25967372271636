import { FC, useCallback, useState } from 'react';
import { v4 } from 'uuid';

type SectionDataValue = string[] | Array<Record<string, string>> | null;
interface IProps {
	childData: SectionDataValue;
}

export const KycTreeSubHeadingChildComponent: FC<IProps> = ({ childData }) => {
	const [showMore, setShowMore] = useState(false);

	const handleShowMore = useCallback(() => {
		setShowMore(prev => !prev);
	}, []);

	return (
		<>
			{childData &&
				Array.isArray(childData) &&
				(childData ?? []).map((val, index) => {
					if (!showMore && index >= 4) {
						return <></>;
					}

					return typeof val === 'string' ? (
						<li
							key={v4()}
							className="kyc-comprohensive-chart__container--sub-heading-item-properties"
						>
							{val ?? '--'}
						</li>
					) : (
						<div
							key={v4()}
							className="kyc-comprohensive-chart__container--sub-heading-item-properties"
						>
							{Object.entries(val ?? {}).map(([key, value]) => (
								<div key={key}>
									<li>
										{key}: {(value ?? '').toString()}
									</li>
								</div>
							))}
						</div>
					);
				})}
			{(childData?.length ?? 0) > 4 && (
				<div
					onClick={() => handleShowMore()}
					className="kyc-comprohensive-chart__container--sub-heading-item-properties kyc-summary-show-more"
				>
					{!showMore ? 'Show more' : 'Show Less'}
				</div>
			)}
		</>
	);
};
