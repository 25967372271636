import { useCallback, useMemo } from 'react'; // Importing React hooks
import { useRecoilValue } from 'recoil'; // Using Recoil to access state
import { ReactResponsiveTable } from '@storybook'; // Importing a component
import { loginState } from 'global-stores'; // Importing login state
import {
	IPacketOrigin,
	PACKET_ORIGIN,
	SignDocPacketsInboxSelector,
	SignDocSentboxHeader,
	SignedDocPacketsState,
} from '../store'; // Importing some store values
import {
	IInboxAction,
	IRecipient,
	IRows,
	ISignedDocInbox,
	InboxActions,
	InboxAuth,
	InboxLastChangeDate,
	InboxStatus,
	InboxSubject,
} from 'views/signed-doc-inbox'; // Importing components from 'views/signed-doc-inbox'
import { SignDocPacketInboxFilter } from './packet-inbox-filter'; // Importing the filter component
import { PERMISSION_SCREEN, useUserRoles } from 'views/routes-children';
import { ESIGN_PURPOSE } from 'views/pipelines/components';

// Arun Kumar : Table Component for Inbox screen
export const SignedDocPacketsInboxTable = () => {
	// Getting data from Recoil state
	const { data } = useRecoilValue(SignDocPacketsInboxSelector);
	const { isLoaded } = useRecoilValue(SignedDocPacketsState);
	const userData = useRecoilValue(loginState);

	const { checkUserWritePermission } = useUserRoles();

	const isUserPermissionWrite = useMemo(
		() => checkUserWritePermission(PERMISSION_SCREEN.Inbox),
		[checkUserWritePermission]
	);

	// Arun Kumar :  Function to count completed recipients
	const completedRecipientsCount = useCallback(
		(inbox: ISignedDocInbox) => {
			let count = 0;
			inbox.recipients.forEach((recipient: IRecipient) => {
				if (recipient.status === 'completed') {
					count += 1;
				}
			});

			const recipient = inbox.recipients.find(
				({ email }) => email === userData.email
			);
			return { count, recipient };
		},
		[userData?.email]
	);

	// Arun Kumar :  Function to determine the action for each inbox item
	const getAction = useCallback(
		(inbox: ISignedDocInbox): IInboxAction => {
			const shouldShowDownloadBtn =
				inbox.status === 'completed' ||
				completedRecipientsCount(inbox).count === inbox.recipients.length;
				const allRecipientsCompleted = inbox.recipients.every((recipient) => recipient.status === "completed");

			if (inbox.status === 'voided') {
				return 'voided';
			}
			if (inbox.status === "configured" && allRecipientsCompleted) {
				return "processing";
			}
			if (shouldShowDownloadBtn) {
				return 'completed';
			}
			if (!inbox.signOrder) {
				if (completedRecipientsCount(inbox)?.recipient?.status === 'pending' && inbox.purpose !== ESIGN_PURPOSE.SIGNAGREEMENT && inbox.purpose !== ESIGN_PURPOSE.MULTISIGNAGREEMENT) {
					return 'needToSign';
				}
				return 'waitingForOthers';
			}
			const nextUser = inbox.recipients.find(
				({ status }) => status !== 'completed'
			);
			if (
				nextUser &&
				nextUser.email.toLowerCase() === userData.email?.toLowerCase() && 
				inbox.purpose !== ESIGN_PURPOSE.SIGNAGREEMENT && 
				inbox.purpose !== ESIGN_PURPOSE.MULTISIGNAGREEMENT
			) {
				return 'needToSign';
			}
			return 'waitingForOthers';
		},
		[completedRecipientsCount, userData.email]
	);

	// Arun Kumar :  Generate rows for the table
	const renderRows = useMemo(() => {		
		const rows: IRows | any = [];
		data.forEach((inbox: ISignedDocInbox) => {
			if (inbox) {
				let row: any = {};
				const recipients: string[] = [];
				let inboxType: 'multiSigner' | 'selfSign' | 'onboarding' | '' = '';
				const actionStatus = getAction(inbox);

				switch (inbox.purpose) {
					case 'workflow':
						inboxType = 'multiSigner';
						break;
					case "selfsign" : 
					    inboxType = "selfSign";
						break;
					default:
						inboxType = 'onboarding';
						break;
				}

				inbox.recipients.forEach(({ name, fullName, email }) =>
					recipients.push(`${name ?? fullName} , ${email}`)
				);

				SignDocSentboxHeader.forEach(
					({ key, format }: { key: string; format: string }) => {
						if (format === 'jsx' && key === 'name') {
							const value = () => <InboxSubject inbox={inbox} />;
							row = {
								...row,
								[key]: value,
							};
						}
						if (format === 'jsx' && key === 'status') {
							const value = () => (
								<InboxStatus inbox={inbox} action={actionStatus} />
							);
							row = {
								...row,
								[key]: value,
							};
						}
						if (format === 'jsx' && key === 'origin') {
							let type: IPacketOrigin = inbox.origin ?? PACKET_ORIGIN.BASIC;
							if (
								inbox.purpose === ESIGN_PURPOSE.SIGNAGREEMENT ||
								inbox.purpose === ESIGN_PURPOSE.ACCREDITATION ||
								inbox.purpose === ESIGN_PURPOSE.MULTISIGNAGREEMENT
							) {
								type = PACKET_ORIGIN.ONBOARDING;
							}
							const value = () => {
								return (
									<div className={`sign-doc-capsule sign-doc-capsule--${type}`}>
										{type}
									</div>
								);
							};
							row = {
								...row,
								[key]: value,
							};
						}
						if (format === 'jsx' && key === 'updatedAtDate') {
							const value = () => <InboxLastChangeDate inbox={inbox} />;
							row = {
								...row,
								[key]: value,
							};
						}
						if(format === "jsx" && key === "authType") {
							const value = () => <InboxAuth type={inbox.authType}/>
							row = {
								...row,
								[key] : value,
							}
						}
						if (format === 'jsx' && key === 'actions') {
							// Arun kumar : hideInboxOptions is for hiding the void and view options in the action list
							const value = () => (
								<InboxActions
									hideInboxOptions={true}
									inbox={inbox}
									action={actionStatus}
									inboxType={inboxType}
									type="inbox"
									isUserPermissionWrite={isUserPermissionWrite}
								/>
							);
							row = {
								...row,
								[key]: value,
							};
						}
					}
				);
				row.createdAt = inbox.createdAt;
				row.updatedAt = inbox.createdAt;
				row.documentName = `${inbox.name ?? ''}`;
				row.recipient = recipients.join(', ');
				row.status1 = inbox.status;
				row.inboxType = inboxType;
				row.actionStatus = actionStatus;
				rows.push(row);
			}
		});
		return rows;
	}, [data, getAction, isUserPermissionWrite]);

	// Determine if the table is loading
	const isLoading = useMemo(() => {
		return !isLoaded;
	}, [isLoaded]);

	// Arun Kumar :  Render the responsive table component
	return (
		<ReactResponsiveTable
			isLoading={isLoading}
			column={SignDocSentboxHeader}
			rows={renderRows}
			showSearch={true}
			filterPage="signDocPacketInbox"
			customFilter={<SignDocPacketInboxFilter />}
			showDateFilter={true}
			EmptyIllustration="empty-aggrement-docs.svg"
			EmptyMessageHeading="No Signed Document Available"
			EmptyMessageDescription="You have not created a signed document yet."
		/>
	);
};
