import { ReactResponsiveTable } from "@storybook";
// import { loginState } from "global-stores";
import { useMemo } from "react";
import { useRecoilValue } from "recoil";
import { EsignDratDocState, InboxActions, InboxAuth, InboxLastChangeDate, InboxSubject, PERMISSION_SCREEN, useUserRoles } from "views";
import { ESIGN_DRAFT_HEADERS } from "views/esign-draft/store";


export const EsignDraftTable = () => {
	const { data, isLoaded } = useRecoilValue(EsignDratDocState);
    

	const { checkUserWritePermission } = useUserRoles();

	const isUserPermissionWrite = useMemo(
		() => checkUserWritePermission(PERMISSION_SCREEN.Draft),
		[checkUserWritePermission]
	);

	const renderRows = useMemo(() => {
		const rows = [];
		data.forEach((inbox) => {
			if (inbox) {
				let row = {};
				const recipients = [];
				inbox.recipients.forEach(({ name, fullName, email }) =>
					recipients.push(`${name ?? fullName} , ${email}`)
				);
				ESIGN_DRAFT_HEADERS.forEach(({ key, format }) => {
					if (format === 'jsx' && key === 'name') {
						const value = () => <InboxSubject inbox={inbox} />;
						row = {
							...row,
							[key]: value,
						};
					}
					if (format === 'jsx' && key === 'updatedAtDate') {
						const value = () => <InboxLastChangeDate inbox={inbox} />;
						row = {
							...row,
							[key]: value,
						};
					}
					if(format === "jsx" && key === "authType") {
						const value = () => <InboxAuth type={inbox.authType}/>
						row = {
							...row,
							[key] : value,
						}
					}
					if (format === 'jsx' && key === 'actions') {
						const value = () => (
							<InboxActions
								inbox={inbox}
								action={'editable'}
								inboxType={'editable'}
								type="editable"
								isUserPermissionWrite={isUserPermissionWrite}
							/>
						);
						row = {
							...row,
							[key]: value,
						};
					}
				});
				row.createdAt = inbox.createdAt;
				row.updatedAt = inbox.createdAt;
				row.documentName = `${inbox.name ?? ''}`;
				row.recipient = recipients.join(', ');
				row.status1 = inbox.status;
				rows.push(row);
			}
		});
		return rows;
	}, [data, isUserPermissionWrite]);

	const isLoading = useMemo(() => {
		return !isLoaded;
	}, [isLoaded]);

    return (
		<ReactResponsiveTable
			isLoading={isLoading}
			column={ESIGN_DRAFT_HEADERS}
			rows={renderRows}
			showSearch={true}
			customFilter={<div/>}
			showDateFilter={true}
			EmptyIllustration="empty-aggrement-docs.svg"
			EmptyMessageHeading="No Draft Document Available"
			EmptyMessageDescription="You have not created draft document yet."
		/>
	);
}