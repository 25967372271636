import type { ITransactionHead } from 'views/transactions/stores';

import { FC, useCallback, useMemo } from 'react';
import { useRecoilValue } from 'recoil';

import { getDateWithTime, useFormatNumber } from 'utils';
import { ViewKyc, useSessionDetails } from 'views/sessions';
import { TransactionIcon, TransactionType, transactionInfo } from 'views/transactions/constants';
import { SelectedTransactionState } from 'views/transactions/stores';
import { TransactionDevice, TransactionUser } from './components';
import './transaction-head.scss';

export const TransactionHead: FC<ITransactionHead> = ({
	details,
	transactionDetails,
	setTransactionType,
	transactionType,
	handleBackModal,
	appleDeviceID,
	transactionStatus,
	senderMatchScore
}) => {
	//globel states
	const selectedTransaction = useRecoilValue(SelectedTransactionState);

	// hooks
	const {
		fetchTransactionSession,
		isLoaded: transactionSessionLoaded,
		isLoading: transactionSessionLoading,
	} = useSessionDetails();
	const { numberDecimal } = useFormatNumber();

	const { senderSessionId, recipientSessionId } = transactionDetails ?? {};

	const {
		userImage,
		recipient_img,
		location,
		userAgent,
		ip,
		senderIP,
		recipientIP,
		recipientOS,
		senderOS,
		amount,
		senderEmail,
		recipientEmail,
		senderPhone,
		recipientPhone,
	} = details ?? {};

	// props for Transaction User component
	const UserProps = {
		fetchTransactionSession,
		setTransactionType,
		userImage,
		recipient_img,
		senderEmail,
		recipientEmail,
		senderPhone,
		recipientPhone,
		senderSessionId: senderSessionId as string,
		recipientSessionId: recipientSessionId as string,
	};

	// props for Transaction Device component
	const DeviceProps = {
		senderIP: ip ?? senderIP,
		recipientIP,
		senderOS,
		recipientOS,
		userOsName: userAgent?.os?.name as string,
		userOsVersion: userAgent?.os?.version as string,
		senderBrowserMajor: userAgent?.browser?.major as string,
		senderBrowserName: userAgent?.browser?.name as string,
	};

	const { sender: senderLocation, recipient: recipientLocation } =
		location ?? {};

	const renderTransactionCards = useCallback(
		(type: TransactionType) => {
			const { latitude, longitude , address  } =
				type === TransactionType.Sender
					? senderLocation ?? {}
					: recipientLocation ?? {};
			return (
				<div className="session-info">
					<div className="TransactionCard--title">
						<div className={`TransactionCard--title__${type}-icon`}>
							<i className={`${TransactionIcon[type] ?? ''}`} />
						</div>
						<div className={`TransactionCard--title__heading-${type}`}>
							{type ?? '--'}
						</div>
					</div>
					<TransactionUser senderMatchScore={senderMatchScore} type={type} {...UserProps} />
					<TransactionDevice
						type={type}
						latitude={latitude as number}
						longitude={longitude as number}
						address={address as {[key: string]: string}}
						appleDeviceID={appleDeviceID}
						{...DeviceProps}
					/>
				</div>
			);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[recipientLocation,appleDeviceID, senderLocation]
	);

	const renderTransactionTopSection = useMemo(
		() => (
			<div className="session-info">
				<div className="TransactionHeader--top">
					<div>
						<div className="TransactionHeader--transactionAmount">
							${numberDecimal(amount as number)}
						</div>
						<div className="TransactionHeader--transactionStatus">
							<i
								className={
									transactionStatus === 'success'
										? 'ri-circle-fill TransactionHeader--transactionStatus__circle'
										: 'ri-circle-fill TransactionHeader--transactionStatus__failed--circle'
								}
							/>
							<span className="TransactionHeader--transactionStatus__label">
								{transactionStatus}
							</span>
						</div>
					</div>
					<div className="TransactionHeader--transactionInfo">
						{(transactionInfo ?? []).map(({ key, lable, dataKey }) => (
							<div key={key} className={`TransactionHeader--${key}`}>
								<div className={`TransactionHeader--${key}__label`}>
									{lable}
								</div>
								<div className="">:</div>
								<div className={`TransactionHeader--${key}__value`}>
									{key === 'transactionDate'
										? selectedTransaction?.[dataKey]
											? getDateWithTime(
													selectedTransaction[dataKey],
													'MMM dd, yyyy hh:mm:ss a'
											  )
											: 'NA'
										: selectedTransaction?.[dataKey] || 'NA'}
								</div>
							</div>
						))}
					</div>
				</div>
			</div>
		),
		[amount, transactionStatus, numberDecimal, selectedTransaction]
	);

	const renderTransactionBodySection = useMemo(() => {
		return (
			<div className="TransactionHeader--cards">
				{Object.values(TransactionType ?? {}).map(type =>
					renderTransactionCards(type)
				)}
			</div>
		);
	}, [renderTransactionCards]);

	return !transactionType ? (
		<div className="TransactionHeader--container">
			{renderTransactionTopSection}
			{renderTransactionBodySection}
		</div>
	) : (
		<ViewKyc
			handleBackModal={handleBackModal}
			isTransactionPage
			sessionDeviceLoaded={transactionSessionLoaded}
			sessionKybLoaded={transactionSessionLoaded}
			sessionKycLoaded={transactionSessionLoaded}
			sessionAmlLoaded={transactionSessionLoaded}
			activityLoaded={transactionSessionLoaded}
			isLoading={transactionSessionLoading}
		/>
	);
};
