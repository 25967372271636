import { IOption } from '@storybook';
import { IOnboardingType } from 'global-stores/invite-investor/type';

export const PipelinesTable = {
	HEADER: [
		{
			label: 'Onboarding Details',
			key: 'name',
			format: 'jsx',
			width: '25%',
		},
		// {
		//   label: "Onboarding Flow Id",
		//   key: "_id",
		//   format: "jsx",
		//   className: "btn__text",
		//   width: "18%",
		// },
		{
			label: 'Steps In Flow',
			key: 'fullStatus',
			format: 'jsx',
			width: '55%',
		},
		// {
		// 	label: 'Creation Date',
		// 	key: 'createdAt',
		// 	format: 'date',
		// 	width: '12%',
		// },
		{
			label: 'Actions',
			key: 'action',
			format: 'jsx',
			className: 'onboarding__actions',
			width: '8%',
		},
	],
	COMPLEX_HEADER: [
		{
			label: 'Name',
			key: 'name',
			format: 'string',
			width: '18%',
		},
		{
			label: 'Onboarding Flow Id',
			key: '_id',
			format: 'jsx',
			className: 'btn__text',
			width: '15%',
		},
		{
			label: 'Creation Date',
			key: 'createdAt',
			format: 'date',
			width: '18%',
		},
		{
			label: ' Steps In Flow',
			key: 'nodes',
			format: 'jsx',
			width: '38%',
		},

		{
			label: 'Actions',
			key: 'action',
			format: 'jsx',
			className: 'onboarding__actions',
			width: '10%',
		},
	],
};

export const actionKeyLabel: any = {
	kyc: 'KYC',
	payIn: 'Fund (Pay In)',
	payOut: 'Fund (Payout)',
	accreditation: 'Accreditation',
	'506c': 'Accreditation(506c)',
	'506b': 'Accreditation(506b)',
	aml: 'AML',
	signAgreement: 'Sign Doc.',
	authentication: 'Auth',
	form: 'Form',
	kyb: 'KYB',
	'kyc(with liveness)': 'KYC',
	simpliciSignAgreement: 'Sign Doc.',
	docusignSignAgreement: 'Sign Doc.',
	signAgreement_esign: 'Sign Doc.',
	signAgreement_docusign: 'Sign Doc.',
	proofReading: 'Document Review',
};

export const proofReadingKey: any = {
	proofReading: 'proofReading',
	proofVerification: 'proofVerification',
	DocumentReview: 'Document Review'
};

export const mendatoryFields = [
	'First Name',
	'Last Name',
	'Country Code',
	'Mobile',
	'Email',
	'Investor First Name',
	'Investor Last Name',
	'Investor Entity',
	'Investor Address',
	'Investor Country Code',
	'Investor Mobile',
	'Investors Email',
	'Number of Shares',
	'Price per Share',
	'Dollars Invested',
	'Share Class',
	'Issue Date',
	'Funding Round',
];

export const accreditationMendatoryFields = [
	'First Name',
	'Last Name',
	'Entity',
	'Address',
	'Country Code',
	'Mobile',
	'Email',
];

export const mandatoryColumms = [
	'First Name',
	'Last Name',
	'Entity',
	'Address',
	'Country Code',
	'Mobile',
	'Email',
	'Number of Shares',
	'Price per Share',
	'Share Class',
	'Issue Date',
	'Funding Round',
];
export const mandatoryColummsAcc = [
	'First Name',
	'Last Name',
	'Country Code',
	'Mobile',
	'Email',
];

export const defaultMapColumn = [
	{
		Email: 'Email',
	},
	{
		'Country Code': 'Country Code',
	},
	{ Mobile: 'Mobile' },
	{ 'First Name': 'First Name' },
	{ 'Last Name': 'Last Name' },
];

export const defaultMapColumnFund = [
	{
		'Dollars Invested': 'Fund Transfer Amount',
	},
	{
		Email: 'Email',
	},
	{
		'Country Code': 'Country Code',
	},
	{ Mobile: 'Mobile' },
	{ 'First Name': 'First Name' },
	{ 'Last Name': 'Last Name' },
];

export const defaultMapColumnFundOut = [
	{
		'Dollars Invested': 'Amount To Pay',
	},
	{
		Email: 'Email',
	},
	{
		'Country Code': 'Country Code',
	},
	{ Mobile: 'Mobile' },
	{ 'First Name': 'First Name' },
	{ 'Last Name': 'Last Name' },
];

export const NaicsTypeOptions: IOption[] = [
	{ label: 'NAICS 11', value: 'NAICS 11' },
	{ label: 'NAICS 23', value: 'NAICS 23' },
	{ label: 'NAICS 31-33', value: 'NAICS 31-33' },
	{ label: 'NAICS 42', value: 'NAICS 42' },
	{ label: 'NAICS 51', value: 'NAICS 51' },
];

export enum KEYLEVEL {
	L0 = 'L0',
	L1 = 'L1',
	L2 = 'L2',
}

export enum ProviderEnum {
	Provider = 'FORTRESS',
}

export enum EscrowType {
	Business = 'business',
	Personal = 'personal',
}

export enum BankType {
	Escrow = 'escrow',
	Bank = 'bank',
}

export enum OnboardingType {
	ReminderSetting = 'reminderSetting',
}

export enum ReminderType {
	Days = 'from-days',
	Calender = 'from-calender',
}

// Gaurav: Complex flow view modal subtitle.
export const advancedDescriptionModal = {
	onboarding:
		'Here’s a quick overview of all the steps in this onboarding flow.',
	session:
		'Here’s a quick overview of all the steps and their current statuses in this onboarding flow.',
};

export const ErrorMESSAGES = {
	ONBOARDING_NAME: 'Onboarding with this name already exist',
};

export const ONBOARDING_TYPE: { [key in Uppercase<IOnboardingType>]: IOnboardingType } = {
	COMPLEX: "complex",
	LINEAR: "linear"
}
export const ErrorSessionsNotWrite = "Your current permissions do not include write access for sessions, please contact the administrator for further assistance."