import type { PaymentAddressError } from 'views/user-identity-flow';

import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil';

import {
	BillingPaymentAddress,
	PaymentAddressDetails,
} from 'views/user-identity-flow';
import { BusinessRegistrationState } from 'global-stores';

interface IBillingInfoForm {
	isShowAutoCheck?: boolean;
}
export const BillingInfoForm: FC<IBillingInfoForm> = () => {
	const [isBillingError, setIsBillingError] = useState<PaymentAddressError>({
		address1: false,
		city: false,
		state: false,
		zip: false,
		address2: false,
	});
	const [billingAddress, setBillingAddress] = useRecoilState(
		BillingPaymentAddress
	);
	const resetBillingAddress = useResetRecoilState(BillingPaymentAddress);
	const [billingChecked, setBillingChecked] = useState(false);
	const businessRegistrationState = useRecoilValue(BusinessRegistrationState);

	const onHandleBillingAddress = useCallback(
		(name: string, value: string) => {
			if (name === 'zip' && value.length < 4) {
				setIsBillingError(prev => ({ ...prev, [name]: true }));
			}
			if (name === 'zip' && value.length > 10) return;
			setBillingAddress(prev => ({
				...prev,
				[name]: value,
				// Reset state and city when country or state changes
				...(name === 'country' && { state: '', city: '' }),
				...(name === 'state' && { city: '' }),
			}));
			if (!value.trim()) {
				setIsBillingError(prev => ({ ...prev, [name]: true }));
				return;
			}
			setIsBillingError(prev => ({ ...prev, [name]: false }));
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	);

	const handleCheckedInput = (billingChecked: boolean) => () => {
		if (billingChecked) resetBillingAddress();
		setBillingChecked(prev => {
			return !prev;
		});
	};

	// Some issue the data is not update --> need to check later
	const { kyc } = useMemo(
		() => businessRegistrationState ?? {},
		[businessRegistrationState]
	);

	useEffect(() => {
		if (billingChecked) {
			const { kyc: kycData } = kyc?.data ?? kyc ?? {};
			const { document } = kycData ?? {};
			const {
				address: newAdd,
				city: newCity,
				country: newCountry,
				state: newState,
				zip: newZip,
			} = kycData?.basicInfo?.newAddress ?? {};

			if (document?.idType?.value?.toLowerCase() === 'passport') {
				setBillingAddress(prev => ({
					...prev,
					zip: newZip?.value,
					city: newCity?.value,
					country: newCountry?.value,
					state: newState?.value,
					address1: newAdd?.value,
				}));
				return;
			} else {
				const { address, city, country, state, zip } = kycData?.basicInfo ?? {};
				setBillingAddress(prev => ({
					...prev,
					zip: zip?.value ?? '',
					city: city?.value ?? '',
					country: country?.value ?? '',
					state: state?.value ?? '',
					address1: address?.value ?? '',
				}));
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [kyc, billingChecked, setBillingAddress]);

	return (
		<div className="BillingForm">
			<div className="BillingForm--checkbox">
				<input
					type="checkbox"
					checked={billingChecked}
					onClick={handleCheckedInput(billingChecked)}
				/>
				<label
					htmlFor="mailing_address"
					onClick={handleCheckedInput(billingChecked)}
				>
					Billing address same as company address
				</label>
			</div>
			<PaymentAddressDetails
				address={billingAddress}
				updateAddress={onHandleBillingAddress}
				addressError={isBillingError}
				label="Street Address"
			/>
		</div>
	);
};
