import { useRecoilValue, useSetRecoilState } from 'recoil';
import {
	ChangeEvent,
	DragEvent,
	FC,
	useCallback,
	useEffect,
	useMemo,
	useState,
} from 'react';
import { Loader } from '@storybook';

import { MESSAGE } from 'constant';
import { useFirebaseInit, useNotification } from 'hooks';
import { UploadedDocsState, loginState } from 'global-stores';
import { IDocProvider } from '../store';
import { documentTypeRegex } from 'utils';
import { IInputChange } from 'types';
import { useFileUpload } from 'hooks/file-upload';
import { onValue, ref } from 'firebase/database';
import { Memory } from 'hooks/file-upload/constant';

interface IProvider {
	provider: IDocProvider;
	handleChange?: any;
}

export const BrowseFile: FC<IProvider> = ({ provider, handleChange }) => {
	const setAddedFiles = useSetRecoilState(UploadedDocsState);
	const [uploadStatus, setUploadStatus] = useState({
		loading: false,
		loaded: false,
	});
	const { accessToken } = useRecoilValue(loginState);

	// Tracks the ID of the uploaded document
	const [uploadedDocId, setUploadedDocId] = useState('');

	const { errorNotification, successNotification } = useNotification();
	const { uploadDoc } = useFileUpload(); // Get the 'uploadDoc' function for handling file uploads.
	const { database } = useFirebaseInit(); // Get the 'database' reference to interact with Firebase.

	useEffect(() => {
		// Only monitor changes if a document ID is provided
		if (!uploadedDocId) return;

		// Create a reference to the document in the Firebase database
		const dataRef = ref(database, uploadedDocId);

		// Real-time listener for document changes
		const unsubscribe = onValue(dataRef, snapshot => {
			const data = snapshot.val();

			// eslint-disable-next-line no-console
			console.info({ uploadedFile: data }); // Debugging info

			// Ignore updates if the timestamp hasn't changed
			if (data?.timeStamp === Memory.getTimeStamp()) return;

			// Update the stored timestamp
			Memory.setTimeStamp(data?.timeStamp);

			// Process the uploaded document data if IDs match
			if (data?._id === uploadedDocId) {
				setUploadedDocId(''); // Reset the uploaded document ID

				const { name, size, _id, createdAt , error } = data ?? {};

				if (name && !error) {
					// Simulate processing delay with a timeout
					setTimeout(() => {
						// Update upload status
						setUploadStatus({ loaded: true, loading: false });

						// Construct payload for the document
						const payload = {
							_id,
							name,
							size,
							createdAt,
							prepareUrl: '',
							configured: false,
							isChecked: true,
							templateId: '',
							documentId: '',
							type: 'newUpload',
							templateName: '',
							provider,
						};

						// Pass payload to the change handler or add to state
						if (handleChange) {
							handleChange(payload);
						} else {
							setAddedFiles(prev => [payload, ...prev]);
						}

						// Notify user of success
						successNotification('File Uploaded.');

						// Reset upload status
						setUploadStatus({ loaded: false, loading: false });
					}, 1000);
				} else {
					// Handle error: missing name in document data
					errorNotification(error ?? MESSAGE.ERROR);
					setUploadStatus({ loaded: false, loading: false });
				}
			}
		});

		// Cleanup the listener on component unmount or dependency change
		return () => unsubscribe();
	}, [
		uploadedDocId,
		database,
		handleChange,
		provider,
		setUploadedDocId,
		setAddedFiles,
		setUploadStatus,
		successNotification,
		errorNotification,
	]);

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const handleImportImage = useCallback(
		async (e: ChangeEvent<HTMLInputElement>, type?: 'drag' | 'browse') => {
			const file: File =
				(type === 'drag'
					? (e as any)
					: (e as IInputChange).target.files?.[0]) ?? ({} as File);
			if (file.size > 26214400 && e?.target?.value) {
				e.target.value = '';
				return errorNotification('File size exceeds the maximum limit.');
			}
			if (!documentTypeRegex.test(file.type) && e?.target?.value) {
				e.target.value = '';
				return errorNotification('Supported file format: Pdf, Doc, Docx.');
			}
			const payload = new FormData();
			payload.append('doc', file);
			if (provider === 'esign') {
				payload.append('type', provider);
				payload.append('extension', file.type);
			}
			if (file) {
				setUploadStatus(prev => ({ ...prev, loading: true }));
				const uploadReps = await uploadDoc(file);

				const { documentId, success , message } = uploadReps ?? {};
				if (success && documentId) {
					setUploadedDocId(uploadReps?.documentId ?? '');
				} else {
					errorNotification(message ?? MESSAGE.ERROR);
					setUploadStatus({ loaded: false, loading: false });
				}
				e.target.value = '';
			}
			return null;
		},

		// eslint-disable-next-line react-hooks/exhaustive-deps
		[accessToken, provider]
	);

	/**
	 * @Manish here we are avoiding the drag over effect when ever we are uploading any document using the drag and upload method
	 * */
	const handleDragOver = useCallback((e: DragEvent<HTMLDivElement>) => {
		e.preventDefault();
		e.stopPropagation();
	}, []);

	/**
	 * @Manish here we are writting the drag and upload function so that user can drop the file to upload the document
	 * */
	const handleDrop = useCallback(
		(e: DragEvent<HTMLDivElement>) => {
			e.preventDefault();
			e.stopPropagation();
			const files = Array.from(e.dataTransfer.files);
			handleImportImage(files?.[0] as any, 'drag');
		},
		[handleImportImage]
	);

	const isDisabled = useMemo(
		() => !uploadStatus.loaded && uploadStatus.loading,
		[uploadStatus.loaded, uploadStatus.loading]
	);

	return (
		<div
			className="browse-file"
			onDragOver={handleDragOver}
			onDrop={handleDrop}
		>
			<label
				htmlFor="browse-file"
				className="browse-file__label-box browse-file__label-box-height"
			>
				<>
					{isDisabled ? (
						<div className="browse-file__upload-loader">
							<Loader className="loader-blue" dimension={60} />
							<div className="browse-file__uploading-text">
								Uploading file...
							</div>
						</div>
					) : (
						<>
							<i className="ri-file-upload-line browse-file__logo" />
							<div className="browse-file__label-text">
								<span className="browse-file__light-text">
									Drag and drop files or{' '}
									<span style={{ color: 'var(--color-primary-light)' }}>
										Browse file
									</span>
								</span>
							</div>
							<div className="browse-file__description">
								Supported file format: Pdf, Doc, Docx
							</div>
							<div className="browse-file__description">
								Maximum upload file size: 25 MB.
							</div>
						</>
					)}
				</>

				<input
					multiple={false}
					accept=".pdf, .doc, .docx"
					id="browse-file"
					type="file"
					onChange={handleImportImage}
					className={`browse-file__input ${
						isDisabled && 'browse-file__input-loading'
					}`}
					disabled={isDisabled}
				/>
			</label>
		</div>
	);
};
