import { datadogLogs } from '@datadog/browser-logs';
import { ALLOWEDDATADOGLOGSURLS, API_URL, companySatschelURL } from 'constant';
import {
	REACT_APP_DATADOG_CLIENT_TOKEN as CLIENT_TOKEN,
	REACT_APP_DATADOG_ENV as ENV,
	REACT_APP_DATADOG_FORWARD_CONSOLE_LOGS as FORWARD_CONSOLE_LOGS,
	REACT_APP_DATADOG_FORWARD_ERROR_LOG as FORWARD_ERROR_LOG,
	REACT_APP_DATADOG_SERVICE as SERVICE,
	REACT_APP_DATADOG_SESSION_SIMPLE_RATE as SESSION_SIMPLE_RATE,
	REACT_APP_DATADOG_SITE as SITE,
	REACT_APP_DATADOG_VERSION as VERSION,
} from 'envs';
import {
	IframePropsState,
	IsOpenInIframeState,
	WebTokenState,
	kycSettingsState,
	loginState,
	webComponentRouteState,
} from 'global-stores';
import { useCookie, useNetwork } from 'hooks';
import { useEffect, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { ROUTES } from 'routes';
import { getDomain } from 'utils';
import { useSignup } from 'views/authentication/signup/hooks';
import { useGeneralSettings } from 'views/settings';
import useDocumentTitle from 'hooks/meta-data/document-title';
import 'tippy.js/dist/tippy.css';

const { PIPELINE, FUNDS, CREDENTIALS, SESSIONS, USER_ROLES, FLOW } = ROUTES;

export const InitialMain = ({
	webComponentProps,
	isWhiteListedDomainVisible,
	isLoading,
}: any) => {
	const setLogin = useSetRecoilState(loginState);
	const { deleteCookie, get: getAccessToken } = useCookie();
	const location = useLocation();
	const { pathname } = useLocation();
	const navigate = useNavigate();
	const setIFrameProps = useSetRecoilState(IframePropsState);
	const setIsIframe = useSetRecoilState(IsOpenInIframeState);
	const [webToken, setWebToken] = useRecoilState(WebTokenState);
	const kycSettings = useRecoilValue(kycSettingsState);
	const [navigateType, setNavigateType] = useRecoilState(
		webComponentRouteState
	);
	const { sandboxStatus } = useRecoilValue(loginState);
	const { post: createToken, data: createdToken } = useNetwork();
	const { fetchUserDetails } = useSignup();
	const { getClientDetails } = useGeneralSettings();

	const { settings, whitelistedDomains, _id } = useMemo(
		() => kycSettings,
		[kycSettings]
	);
	const { brandColor, bodyFont } = settings || {};
	const isIntegratePage = useMemo(() => {
		return /integrate/gi.test(location.pathname);
	}, [location.pathname]);

	useDocumentTitle({title: pathname });

	useEffect(() => {
		const _logs: string = FORWARD_CONSOLE_LOGS;
		if (ALLOWEDDATADOGLOGSURLS.includes(window.location.origin)) {
			datadogLogs.init({
				clientToken: CLIENT_TOKEN,
				env: ENV,
				site: SITE,
				service: SERVICE,
				version: VERSION,
				forwardErrorsToLogs: FORWARD_ERROR_LOG === 'true',
				sessionSampleRate: Number(SESSION_SIMPLE_RATE ?? 100),
				forwardConsoleLogs:
					_logs === 'all' ? _logs : (_logs?.split(',') as any[]),
			});
		}
	}, []);

	const getHost = (url: string) => {
		const newUrl = new URL(url);
		return newUrl.host;
	};

	useEffect(() => {
		if (!isIntegratePage) {
			const themeStyle = document.getElementById('themeStyle');
			const themeFont = document.getElementById('themeFont');

			themeStyle?.remove();
			themeFont?.remove();

			const headTag: any = document.getElementsByTagName('head')[0];
			const brandPrimaryColor = brandColor ? brandColor : '#0051cc';
			const brandPrimaryFont = bodyFont?.family ? bodyFont?.family : 'Poppins';

			const styleTag = document.createElement('style');
			styleTag.id = 'themeStyle';
			styleTag.innerHTML = `
  :root body {
    --color-primary-light: ${brandPrimaryColor};
    --color-input-border-focus-light: ${brandPrimaryColor};
    font-family: ${brandPrimaryFont}, sans-serif;
  }
  `;
			headTag.appendChild(styleTag);

			const fontLink = document.createElement('link');
			fontLink.rel = 'stylesheet';
			fontLink.type = 'text/css';
			fontLink.id = 'themeFont';
			fontLink.href = `https://fonts.googleapis.com/css2?family=${bodyFont?.family}:wght@400;500;600;700;800&display=swap`;

			headTag.appendChild(fontLink);
		}
	}, [bodyFont?.family, brandColor, isIntegratePage]);

	useEffect(() => {
		if (!webComponentProps.type) {
			const validOnboardingFlow = /flow/gi.test(pathname);
			const validOnboarding = /onboarding/gi.test(pathname);
			const validFunds = /funds/gi.test(pathname);
			const validSessions = /sessions/gi.test(pathname);
			const validCredentials = /credentials/gi.test(pathname);
			const validUserRole = /user-roles/gi.test(pathname);
			if (validOnboardingFlow) {
				return setNavigateType(`${PIPELINE}${FLOW}`);
			}
			if (validOnboarding) {
				setNavigateType(PIPELINE);
			}
			if (validFunds) {
				setNavigateType(FUNDS);
			}
			if (validSessions) {
				setNavigateType(SESSIONS);
			}
			if (validCredentials) {
				setNavigateType(CREDENTIALS);
			}
			if (validUserRole) {
				setNavigateType(USER_ROLES);
			}
		}
		// eslint-disable-next-line
	}, [pathname, webComponentProps.type]);

	useEffect(() => {
		if (createdToken?.accessToken) {
			setWebToken(createdToken.accessToken);
			fetchUserDetails(createdToken.accessToken, true, sandboxStatus ? 'sandbox' : 'live');
			if (webComponentProps.type) {
				setNavigateType(`/${webComponentProps.type}`);
			}
		}
		// eslint-disable-next-line
	}, [createdToken, webComponentProps.type]);

	useEffect(() => {
		const cookies = getAccessToken('user');
		if (
			webComponentProps.admin !== 'true' &&
			(!webComponentProps.clientid || !webComponentProps.clientsecret) &&
			cookies.accessToken
		) {
			fetchUserDetails(cookies.accessToken, true, sandboxStatus ? 'sandbox' : 'live');
			if (webComponentProps.type) {
				setNavigateType(`/${webComponentProps.type}`);
			}
			isLoading(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [webComponentProps]);

	useEffect(() => {
		const {
			bgcolor: background,
			search: isSearch,
			theadcolor: theadColor,
			clientid: clientId,
			clientsecret: clientSecret,
			btnprimary: btnPrimary,
			btnsecondary: btnSecondary,
			notification,
			admin,
			sidebar,
			isinsideadmin,
			clientlogosmall,
			clientlogo,
			companyname,
			companyemail,
			type, // Represents the type of web-component (e.g., onboarding, onboarding/flow)
		} = webComponentProps ?? {};

		if (
			background ||
			theadColor ||
			isSearch ||
			clientId ||
			clientSecret ||
			btnPrimary ||
			btnSecondary ||
			admin ||
			sidebar ||
			clientlogo ||
			companyname ||
			type 
		) {
			setIFrameProps((prev: any) => ({
				...prev,
				background: background ?? prev.background,
				theadColor: theadColor ?? prev.theadColor,
				isSearch: isSearch ?? prev.isSearch,
				clientId,
				clientSecret,
				btnPrimary: btnPrimary ?? prev.btnPrimary,
				btnSecondary: btnSecondary ?? prev.btnSecondary,
				notification: notification ? notification === 'true' : true,
				webAdminType: admin ?? '',
				sidebar: sidebar ? sidebar === 'true' : true,
				isInSideAdmin: !!isinsideadmin,
				clientlogosmall,
				clientlogo,
				companyname,
				companyemail,
				type, // Assign the type of web-component (e.g., onboarding, onboarding/flow)
			}));
		}

		if (
			webToken?.length === 0 &&
			clientId &&
			clientSecret &&
			admin !== 'true'
		) {
			setIsIframe(true);

			if (clientId !== 'undefined' && clientSecret !== 'undefined') {
				isLoading(true);
				createToken(API_URL.CLIENT_TOKENS, {
					clientId: clientId,
					clientSecret: clientSecret,
				})
					.then(resp => {
						if (!resp?.accessToken) {
							setLogin(prev => {
								const newObj = structuredClone(prev);
								newObj.loggedIn = false;
								newObj.accessToken = '';
								return newObj;
							});
							navigate('/login');
						}
					})
					.finally(() => {
						setTimeout(() => {
							isLoading(false);
						}, 500);
					});
			} else {
				deleteCookie('user');
				deleteCookie('userEmail');
				navigate('/login');

				setTimeout(() => {
					isLoading(false);
				}, 500);
			}
		}

		// eslint-disable-next-line
	}, [webComponentProps]);

	const getWhiteListedDomainValid = () => {
		const url =
			window.location != window.parent.location
				? document.referrer
				: document.location.href;

		if (
			companySatschelURL.includes(url) ||
			companySatschelURL.includes(window.location.origin) ||
			window.location.origin.includes('http://localhost')
		) {
			isWhiteListedDomainVisible(true);
		} else {
			const isValidUrl = !!whitelistedDomains?.find(
				items => getHost(items.url) === getHost(url)
			);
			isWhiteListedDomainVisible(isValidUrl);

			//Gaurav: https://*.domain then whitelist is enabled for all url for this domain.
			if (!isValidUrl) {
				const filteredWildCardDomain = whitelistedDomains?.filter(
					item =>
						item?.url?.includes('https://*.') ||
						item?.url?.includes('http://*.')
				);
				const isValidDomain = !!filteredWildCardDomain?.find(
					item => getDomain(item.url) === getDomain(url)
				);
				isWhiteListedDomainVisible(isValidDomain);
			}
		}
	};

	useEffect(() => {
		if (pathname !== navigateType && navigateType.length > 1) {
			setTimeout(() => {
				navigate(navigateType);
			}, 500);
		}
		// eslint-disable-next-line
	}, [navigateType]);
	useEffect(() => {
		//@avinash: call getClientDetails to retrive whitelistedDomain for webcomponent
		if (webToken && !_id) {
			getClientDetails();
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [webToken, whitelistedDomains]);

	useEffect(() => {
		//@Avinash : call it only when _Id is present it indicate that your client details has called
		if (_id) getWhiteListedDomainValid();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [_id]);

	return <></>;
};
