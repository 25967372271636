import { FC, useCallback, useMemo, useState } from 'react';
import { useRecoilValue } from 'recoil';
import classNames from 'classnames';
import Tippy from '@tippyjs/react';
import { Loader } from '@storybook';
import Modal from '@storybook/new-modal/modal';

import { SimpliciSignIframe } from 'shared-components';
import { MultidocDownloadModal } from 'components/multidoc-download-modal';
import { API_URL, environmentUrl, sandboxEnvHost } from 'constant';
import { loginState } from 'global-stores';
import { envHost } from 'helpers';
import { useNetwork, useNotification } from 'hooks';
import { SignDocHistory, useSignDocHistory } from 'views/sign-doc-history';
import { EditRecipient } from 'views/edit-recipient';
import { IInboxAction, ISignedDocInbox, useSignedDocsInbox } from '../store';
import { VoidCommentBox } from './comment-box';
import { InboxDropdown } from './inbox-dropdown';
import { NavigateToBackConfirmation } from 'components/esign-back-modal';
import { ESIGN_PURPOSE } from 'views/pipelines/components';
import { InboxSignModal } from './inbox-sign-modal';

interface IProps {
	inbox: ISignedDocInbox;
	action: IInboxAction;
	hideInboxOptions?: boolean;
	inboxType?: 'multiSigner' | 'selfSign' | 'onboarding' | '';
	type: 'inbox' | 'sentBox';
	isUserPermissionWrite?: boolean;
}
export const InboxActions: FC<IProps> = ({
	hideInboxOptions,
	inbox,
	action,
	inboxType = '',
	type,
	isUserPermissionWrite = true,
}) => {
	const userData = useRecoilValue(loginState);
	const { sandboxStatus } = useRecoilValue(loginState);
	const { signDocInbox, resendSignDocInbox, getSignedDocsInbox } =
		useSignedDocsInbox();
	const [prepareUrl, setPrepareUrl] = useState<{ env: string; url: string }>({
		env: '',
		url: '',
	});
	const [modalVisibility, setModalVisibility] = useState(false);
	const [isShowHistoryModal, setIsShowHistoryModal] = useState(false);
	const [isShowEditRecipientModal, setIsShowEditRecipientModal] =
		useState(false);
	const [isShowViewModal, setIsShowViewModal] = useState(false);
	const [isShowCorrectModal, setIsShowCorrectModal] = useState(false);
	const { successNotification } = useNotification();
	const [actionLoader, setActionLoader] = useState(false);
	const [isVoidModalOpen, setIsVoidModalOpen] = useState(false);
	const [showDownloadModal, setShowDownloadModal] = useState(false);

	// local states
	const [isLoaderIframe, setIsLoaderIframe] = useState(true);

	const { post } = useNetwork();
	const { resetHistory } = useSignDocHistory();
	const fileCount = inbox?.documents?.length;

	const recipient = useMemo(() => {
		return inbox.recipients.find(({ email }) => email === userData.email);
	}, [inbox, userData]);

	const handleCloseIframe = useCallback(async () => {
		setIsShowCorrectModal(false);
		setModalVisibility(false);
		setPrepareUrl({ env: '', url: '' });
	}, []);

	const handleSubmitModal = useCallback(() => {
		handleCloseIframe();
		setTimeout(() => {
			successNotification('Document Configured successfully.');
			getSignedDocsInbox(false, inbox.origin === 'editable'? 'draft':'');
		}, 1000);
	}, [getSignedDocsInbox, handleCloseIframe, inbox.origin, successNotification]);

	const handleEditDraft = useCallback(() => {
		setPrepareUrl({ url: `/prepare/envelope/${inbox._id}`, env: envHost === 'pp'? 'preprod': envHost });
		setModalVisibility(true);
		setActionLoader(false);
	}, [inbox._id])

	/**
	 * @Manish this function is modified for voided status of the envelope
	 **/

	const actionClickHandler = useCallback(async () => {
		setActionLoader(true);
		const recId = recipient?._id;
		const envId = inbox._id;
		if (action === 'needToSign' && recId && envId) {
			const response = await signDocInbox(
				{
					envelopeId: envId,
					recipientId: recId,
				},
				setActionLoader
			);
			const { url, env } = response ?? {};
			if (url && env) {
				setPrepareUrl({ url, env });
				setModalVisibility(true);
			}
		}
		if (action === 'waitingForOthers') {
			resendSignDocInbox(inbox._id, setActionLoader);
		}
		if (action === 'completed' || action === 'voided') {
			setActionLoader(false);
			setShowDownloadModal(true);
		}
		if(action === 'editable'){
			handleEditDraft()
		}
	}, [recipient?._id, inbox, action, signDocInbox, resendSignDocInbox, handleEditDraft]);

	// Calculate the label for the action button based on the 'action', 'hideInboxOptions', and 'inboxType'.
	const actionButtonLabel = useMemo(() => {
		// Use a switch statement to determine the label based on the 'action'.
		switch (action) {
			case 'completed':
			case 'voided':
			case 'processing':
				// When 'action' is 'completed', 'voided' or processing, the label is 'Download'.
				return 'Download';

			case 'needToSign':
				// When 'action' is 'needToSign', the label depends on 'inboxType'.
				return 'Sign';

			case 'waitingForOthers':
				return hideInboxOptions ? 'Certificates' : 'Resend';
			case 'editable':
				return 'Edit And Send';
			default:
				// For all other cases, the label depends on 'hideInboxOptions' and 'inboxType'.
				return 'Resend';
		}
	}, [action, hideInboxOptions]);

	const handleOpenHistory = useCallback(() => {
		setIsShowHistoryModal(true);
	}, []);

	const handleEditRecipient = useCallback(() => {
		setIsShowEditRecipientModal(true);
	}, []);

	/**
	 * @Manish this function will manage the patch api call for the void document
	 * */

	const handleVoidPacket = useCallback(() => {
		setIsVoidModalOpen(true);
	}, []);

	const handleCloseActivityLogs = useCallback(() => {
		setIsShowHistoryModal(false);
		resetHistory();
	}, [resetHistory]);

	const handleCloseEditRecipientModal = useCallback(() => {
		setIsShowEditRecipientModal(false);
	}, []);

	const renderViewScreen = useCallback(() => {
		setIsShowViewModal(true);
	}, []);

	const renderViewModalBody = useMemo(() => {
		const host = !sandboxStatus
			? environmentUrl[envHost]
			: environmentUrl[sandboxEnvHost[envHost]];
		return (
			<SimpliciSignIframe
				signUrl={`${host}view-doc?envelopeId=${inbox._id}&view=true`}
				className="viewModal__iframe"
				id="view-modal-Iframe-inbox"
				handleSubmitModal={() => ({})}
			/>
		);
	}, [inbox._id, sandboxStatus]);

	// Define a callback function for handling the primary button click.
	const handleClickPrimaryButton = useCallback(() => {
		if (action === 'processing') {
			return;
		}
		if (
			!isUserPermissionWrite &&
			(actionButtonLabel === 'Sign' || actionButtonLabel === 'Resend' || actionButtonLabel === 'Edit And Send')
		) {
			return;
		}
		// Check the current 'inboxType' and 'action' for specific conditions.

		// If 'inboxType' is 'onboarding' and 'action' is not 'completed',
		// open the history (handleOpenHistory).
		// if (inboxType === 'onboarding' && action !== 'completed') {
		// 	return handleOpenHistory();
		// }

		// If 'hideInboxOptions' is true and 'action' is 'waitingForOthers',
		// open the history (handleOpenHistory).
		else if (hideInboxOptions && action === 'waitingForOthers') {
			return handleOpenHistory();
		}

		// For all other cases, invoke the 'actionClickHandler' function.
		return actionClickHandler();
	}, [
		action,
		isUserPermissionWrite,
		actionButtonLabel,
		hideInboxOptions,
		actionClickHandler,
		handleOpenHistory,
	]);

	const handleClose = useCallback(() => {
		setShowDownloadModal(false);
	}, []);

	const renderCorrectScreen = useCallback(async () => {
		setIsShowCorrectModal(true);
		const payload = {
			packetId: inbox._id,
		};
		const resp = await post(`${API_URL.PACKET_EDIT}`, payload);
		const { prepareUrl: url, env } = resp ?? {};
		setPrepareUrl({ url, env });
		//eslint-disable-next-line
	}, [inbox._id]);

	const renderCorrectModalBody = useMemo(() => {
		return (
			prepareUrl && (
				<SimpliciSignIframe
					signUrl={prepareUrl.url}
					className="viewModal__iframe"
					id="correct-modal-Iframe"
					onLoad={() => setIsLoaderIframe(false)}
					handleSubmitModal={handleSubmitModal}
				/>
			)
		);
	}, [handleSubmitModal, prepareUrl]);

	const handleCorrectModalClose = useCallback(() => {
		setIsShowCorrectModal(false);
		setPrepareUrl({ env: '', url: '' });
		setIsLoaderIframe(true);
	}, []);

	const checkDisabled = useMemo(
		() =>
			(!isUserPermissionWrite &&
				(actionButtonLabel === 'Sign' || actionButtonLabel === 'Resend')) ||
			action === 'processing',
		[action, actionButtonLabel, isUserPermissionWrite]
	);

	const classes = classNames(`InboxActions__action-btn inbox-btn`, {
		'button--disabled': checkDisabled,
	});

	const renderEditRecipientModal = useMemo(() => {
		return (
			<EditRecipient inbox={inbox} closeModal={handleCloseEditRecipientModal} />
		);
	}, [inbox.recipients]); // eslint-disable-line

	const actionButtonTooltipMessage = useMemo(() => {
		if (action === 'processing') {
			return 'Once the document is prepared, it will be available for download.';
		}
		return `You are not permitted to ${actionButtonLabel}`;
	}, [action, actionButtonLabel]);

	return (
		/**
		 * @Manish action button ui changed
		 * */
		<>
			<div className="InboxActions__action-btn-wrapper">
				<div className="inbox-dropdown__wrapper">
					<Tippy disabled={!checkDisabled} content={actionButtonTooltipMessage}>
						<div className={classes} onClick={handleClickPrimaryButton}>
							{actionLoader ? (
								<div className="loader-wrapper">
									<Loader
										className="loader-white"
										dimension={20}
										type="loader"
									/>
								</div>
							) : (
								actionButtonLabel
							)}
						</div>
					</Tippy>

					<div className="inbox-dropdown__render">
						<InboxDropdown
							action={action}
							isHideInboxOptions={hideInboxOptions ?? false}
							historyCallback={handleOpenHistory}
							voidCallback={handleVoidPacket}
							showVoided={
								isUserPermissionWrite &&
								inbox.status !== 'voided' &&
								[ESIGN_PURPOSE.WORKFLOW, ESIGN_PURPOSE.SELFSIGN].includes(
									inbox.purpose
								)
							}
							viewDocCallback={renderViewScreen}
							correctCallback={renderCorrectScreen}
							editRecipientCallback={handleEditRecipient}
							showView={inbox.status !== 'voided'}
							showCorrect={
								inbox.status !== 'completed' &&
								inbox.status !== 'voided' &&
								inbox.purpose !== 'accreditation' &&
								inbox.purpose !== 'signAgreement' &&
								type === 'sentBox' &&
								inbox.origin !== 'editable' &&
								isUserPermissionWrite
							}
							inboxType={inboxType}
						/>
					</div>
				</div>
			</div>
			{isShowHistoryModal && (
				<Modal
					isOpen={isShowHistoryModal}
					modalName="Envelope History"
					className="sign-doc-history-modal"
					closeModal={handleCloseActivityLogs}
					showCloseBtn
					closeOnEscBtn
				>
					<SignDocHistory inbox={inbox} />
				</Modal>
			)}
			{isShowEditRecipientModal && (
				<Modal
					isOpen={isShowEditRecipientModal}
					modalName="Edit recipients information"
					className="edit-recipient-modal"
					closeModal={handleCloseEditRecipientModal}
					showCloseBtn
					closeOnEscBtn
				>
					{renderEditRecipientModal}
				</Modal>
			)}
			{modalVisibility && (
				<InboxSignModal
					modalVisibility={modalVisibility}
					handleCloseIframe={handleCloseIframe}
					prepareUrl={prepareUrl}
					handleSubmitModal={handleSubmitModal}
					isDraftScreen={action === 'editable'}
				/>
			)}
			{isShowViewModal && (
				<Modal
					isOpen={isShowViewModal}
					modalName="View-Doc"
					className="view-doc-modal"
					closeModal={() => setIsShowViewModal(false)}
					isStopOutsideClick={false}
				>
					{renderViewModalBody}
				</Modal>
			)}

			{isVoidModalOpen && (
				<VoidCommentBox setIsOpen={setIsVoidModalOpen} envelopeId={inbox._id} />
			)}

			{isShowCorrectModal && (
				<Modal
					isOpen={isShowCorrectModal}
					modalName="correct-packet-modal"
					className="correct-packet-modal"
					closeModal={handleCorrectModalClose}
					showCloseBtn={true}
					closeOnEscBtn={false}
					isStopOutsideClick={false}
				>
					{isLoaderIframe && (
						<div className="correct-packet-modal__loader">
							<Loader className="loader-blue" dimension={60} />
						</div>
					)}
					{renderCorrectModalBody}
				</Modal>
			)}
			<MultidocDownloadModal
				visible={showDownloadModal}
				envelopeId={inbox._id}
				fileCount={fileCount}
				handleClose={handleClose}
				inboxStatus={inbox.status}
			/>
			{isShowCorrectModal && (
				<NavigateToBackConfirmation
					isModalOpen={isShowCorrectModal}
					modalCloseHandler={handleCorrectModalClose}
				/>
			)}
			{modalVisibility && (
				<NavigateToBackConfirmation
					isModalOpen={modalVisibility}
					modalCloseHandler={handleCloseIframe}
				/>
			)}
		</>
	);
};
