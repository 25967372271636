import { useCallback, useMemo } from 'react';
import {
	CategoriesResult,
	KeyValuePair,
	KYBRiskView,
	OFACResults,
} from './components';
import { formateDate } from 'helpers';
import {
	AmlAdverseMediaSection,
	AmlRiskAnalysis,
	AmlWatcherBasicSection,
	AmlWatcherViewMoreHOC,
} from 'views/sessions/components/background-check/components/aml-watcher-details/components';
import { KybInfoHeader, OFACSectionHOC } from 'views';
import { AML_APPEARS_CATEGORIES } from 'views/sessions/components/background-check/constants';
import { v4 } from 'uuid';

import './kyb-ofac-details.scss';

interface ILabelValuePair {
	label: string;
	value: number;
}

export const KYBOfacDetails = ({ kybOfacDetails }: any) => {
	const kybAMLData = useMemo(
		() => kybOfacDetails?.report ?? {},
		[kybOfacDetails?.report]
	);

	const countCategories = useCallback(
		(
			categoriesList: string[],
			data: { categories: string[] }[]
		): ILabelValuePair[] => {
			return categoriesList?.map(category => {
				const count = data?.reduce((acc, item) => {
					return acc + (item?.categories?.includes(category) ? 1 : 0);
				}, 0);

				return { label: category, value: count };
			});
		},
		[]
	);
	const generalInformation = useMemo(() => {
		return [
			{
				label: 'Is monitored',
				value: kybAMLData?.payload?.onGoing ?? false,
			},
			{
				label: 'Match status',
				value: kybAMLData?.data?.matchStatus ?? '',
			},
		];
	}, [kybAMLData]);

	const { resultsData } = useMemo(
		() => ({
			resultsData: kybAMLData?.data?.results ?? [],
		}),
		[kybAMLData?.data]
	);
	const renderResults = useMemo(() => {
		return (
			<OFACSectionHOC
				label="Results"
				matches={resultsData?.length}
				showViewMoreBtn={false}
				title="Total Result"
			>
				<div className="KYBOfacDetails">
					{resultsData?.map((result: any, index: number) => {
						const birthIncorporationDate = result?.birthIncorporationDate ?? [];
						const adverseMedia = result?.adverseMedia ?? [];
						const categories = result?.categories ?? [];
						const countries = result?.countries ?? [];
						const createdAt = result?.createdAt ?? '';
						const linkedEntities = result?.data?.linkedEntities ?? [];
						const sanctionDetails = result?.data?.sanctionDetails ?? [];
						const summary = result?.data?.summary ?? [];
						const sourceDetails = result?.sourceDetails ?? [];
						const matchedNames = result?.matchedNames ?? [];
						const riskAudit = result?.riskAudit ?? {};
						const entity = result?.entityTypes ?? [];
						const Hio =
							result?.hio === undefined ? [] : [result.hio ? 'true' : 'false'];
						const Id = result?.id ?? '';
						const relevanceStatus: any = Object.entries(
							result?.relevanceStatus ?? {}
						).map(([key, value]) => ({
							[key]: [value],
						}));
						const riskView = result?.riskView ?? {};
						const transformedCategoies = [
							{
								label: '',
								value: categories,
							},
						];
						const transformedCounntry = [
							{
								label: 'Country',
								value: countries,
							},
							{
								label: 'Created At',
								value: [createdAt],
							},
						];

						const EntityTypes = [
							{
								label: 'Entity',
								value: entity,
							},
							{
								label: 'Company',
								value: ['NA'],
							},
							{
								label: 'Hio',
								value: Hio,
							},
							{
								label: 'Id',
								value: [Id],
							},
						];

						return (
							<OFACResults
								key={v4()}
								count={index + 1}
								title="Result"
								label="Birth incorporation date"
								value={
									birthIncorporationDate?.length
										? formateDate(birthIncorporationDate[0])
										: 'NA'
								}
							>
								<div className="KYBOfacDetails--results">
									<AmlWatcherViewMoreHOC
										Component={AmlAdverseMediaSection}
										componentProp={adverseMedia ?? []}
										heading="Adverse media"
										showViewMoreHeading={true}
									/>
									<AmlWatcherViewMoreHOC
										Component={CategoriesResult}
										componentProp={transformedCategoies}
										heading="Categories"
										isLineBetween={true}
										showViewMoreHeading={true}
									/>
									<AmlWatcherViewMoreHOC
										Component={CategoriesResult}
										componentProp={transformedCounntry}
										heading="Country"
										showViewMoreHeading={true}
									/>

									<AmlWatcherViewMoreHOC
										Component={AmlWatcherBasicSection}
										componentProp={linkedEntities}
										heading="Linked Entities"
										isLineBetween={true}
									/>
									<AmlWatcherViewMoreHOC
										Component={AmlWatcherBasicSection}
										componentProp={sanctionDetails}
										heading="Sanction details"
										isLineBetween={true}
									/>
									<AmlWatcherViewMoreHOC
										Component={AmlWatcherBasicSection}
										componentProp={summary}
										heading="Summary"
										isdefaultShowAllinfo={true}
									/>
									<AmlWatcherViewMoreHOC
										Component={CategoriesResult}
										componentProp={EntityTypes}
										heading="Entity type"
										isdefaultShowAllinfo={true}
									/>
									<AmlWatcherViewMoreHOC
										Component={AmlWatcherBasicSection}
										componentProp={matchedNames}
										heading="Matched names"
										isdefaultShowAllinfo={true}
									/>
									<AmlWatcherViewMoreHOC
										Component={AmlWatcherBasicSection}
										componentProp={relevanceStatus}
										heading="Relevance status"
										isdefaultShowAllinfo={true}
									/>
									<KYBRiskView
										riskData={riskAudit}
										result={result}
										type="Risk audit"
									/>
									<AmlRiskAnalysis riskData={riskView} isFromKYBOfac={true} />
									<AmlWatcherViewMoreHOC
										Component={AmlAdverseMediaSection}
										componentProp={sourceDetails ?? []}
										heading="Sources"
										showViewMoreHeading={true}
									/>
								</div>
							</OFACResults>
						);
					})}
				</div>
			</OFACSectionHOC>
		);
	}, [resultsData]);

	const getCategoriesDetails = useMemo(() => {
		const categoryCounts = countCategories(
			AML_APPEARS_CATEGORIES,
			kybAMLData?.data?.results
		);
		return (
			<div className="KYBOfacDetails__general-info">
				<KybInfoHeader type={'Categories'} />
				<div className="KYBOfacDetails__general-info--data">
					{categoryCounts?.map((item, index) => {
						return (
							<KeyValuePair
								value={item?.value || '0'}
								label={item?.label}
								key={index?.toString()}
							/>
						);
					})}
				</div>
			</div>
		);
	}, [countCategories, kybAMLData?.data?.results]);

	const getGeneralInfo = useMemo(() => {
		return (
			<div className="KYBOfacDetails__general-info">
				<KybInfoHeader type={'General info'} />
				<div className="KYBOfacDetails__general-info--data">
					{generalInformation?.map((item, index) => {
						return (
							<KeyValuePair
								value={item?.value?.toString()}
								label={item?.label}
								key={index?.toString()}
							/>
						);
					})}
				</div>
			</div>
		);
	}, [generalInformation]);
	return (
		<div className="KYBOfacDetails">
			<div>{getCategoriesDetails}</div>
			<div>{getGeneralInfo}</div>
			<div>{resultsData?.length ? renderResults : ''}</div>
		</div>
	);
};
