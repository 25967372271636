import { Loader } from '@storybook';
import Modal from '@storybook/new-modal/modal';
import { environmentUrl } from 'constant';
import { FC, useMemo } from 'react';
import { SimpliciSignWebComponent } from 'shared-components';
import { EnvType } from 'types';

interface IInboxSignModal {
	modalVisibility: boolean;
	handleCloseIframe: () => void;
	prepareUrl: { env: string; url: string };
	handleSubmitModal: () => void;
	isDraftScreen?: boolean
}
export const InboxSignModal: FC<IInboxSignModal> = ({
	modalVisibility,
	handleCloseIframe,
	prepareUrl,
	handleSubmitModal,
	isDraftScreen
}) => {
	const webComponent = useMemo(() => {
		const { url, env } = prepareUrl ?? {};
		const signUrl = url?.replace(environmentUrl[env], '');
		return signUrl?.trim() ? (
			<SimpliciSignWebComponent
				signUrl={signUrl}
				env={env as EnvType}
				handleSubmitModal={handleSubmitModal}
			/>
		) : (
			<Loader />
		);
	}, [handleSubmitModal, prepareUrl]);

	const renderBoldSignIframe = useMemo(() => {
		return (
			<div className="iframe-container">
				{prepareUrl ? (
					<div
						className="iframe-container__loader-wrapper"
						id="simplici-sign-dashboard-screen"
					>
						{webComponent}
						<button onClick={handleCloseIframe} className="close-btn">
							<i className="ri-close-line" />
						</button>
					</div>
				) : (
					<Loader className="loader-blue" dimension={60} />
				)}
			</div>
		);
	}, [handleCloseIframe, prepareUrl, webComponent]);

	const renderModalBody = useMemo(
		() =>
			modalVisibility ? (
				renderBoldSignIframe
			) : (
				<Loader className="loader-blue" dimension={60} />
			),
		[modalVisibility, renderBoldSignIframe]
	);
	return (
		<Modal
			isOpen={modalVisibility}
			modalName=""
			className={isDraftScreen ? "esign-draft-modal sign-doc-sign-modal" : "sign-doc-sign-modal"}
			closeOnEscBtn={false}
			showCloseBtn={false}
			closeModal={handleCloseIframe}
			isStopOutsideClick={false}
		>
			{renderModalBody}
		</Modal>
	);
};
