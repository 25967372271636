import React, {
	FC,
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import { AsYouType, CountryCode as CountryType,parsePhoneNumberFromString } from 'libphonenumber-js';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import {
	Button,
	Image,
	Input,
	Loader,
	fetchCountryCodeFromPhone,
} from '@storybook';
import { useCookie, useNetwork, useNotification } from 'hooks';
import { IsOpenInIframeState, NewGmailState } from 'global-stores';

import { API_URL, MESSAGE, isMobileView, isValidSubdomainEmail, validateEmail } from 'constant';
import {
	AcountLiveStatus,
	CountryCode,
	LoginCredsState,
	LoginMaskPhoneCredsState,
	LoginStep,
	useLogin,
} from '../../store';
import { ChooseMethod } from '../choose-method';
import { Messages } from './constant';
import countries from 'json/country-codes.json';

import style from './signin-form.module.sass';
import './signin-form.scss';
import { SessionInfoChild } from 'views/sessions';
import { VerifyModal, useSignup } from 'views/authentication';

type ISignInForm = {
	input_type: string;
	methods: any;
	locationInfo: any;
	ipAddress: any;
	send_otp_api: any;
	props: any;
};

const MAX_PHONE_NUMBER = 11;
const { origin } = window.location;
const { ancestorOrigins = [] } = window.location;

const hasAncestorOrigins = ancestorOrigins?.length ?? 0 > 0;
const isSameOrigin =
	hasAncestorOrigins && origin === ancestorOrigins[ancestorOrigins.length - 1];

const host = isSameOrigin
	? ancestorOrigins[ancestorOrigins.length - 1]
	: `${origin}`;

export const SignInForm: FC<ISignInForm> = ({
	methods,
	locationInfo,
	ipAddress,
	send_otp_api,
	props,
}) => {
	//globle states
	const setNewGmail = useSetRecoilState(NewGmailState);
	const [loginCreds, setLoginCreds] = useRecoilState(LoginCredsState);
	const setMaskPhoneCreds = useSetRecoilState(LoginMaskPhoneCredsState);
	const [country, setCountry] = useRecoilState(CountryCode);
	const [step, setStep] = useRecoilState(LoginStep);
	const isIframe = useRecoilValue(IsOpenInIframeState);
	const { deleteCookie } = useCookie();
	const [temporaryPhoneNumber, setTemporaryPhoneNumber] = useState('');

	//local states
	const [emailCode, setEmailCode] = useState('');
	const [invalidPhoneMessage, setInvalidPhoneMessage] = useState('');
	const [isLargeErrorMsg, setIsLargeErrorMsg] = useState('');
	const [invalidEmailCodeMessage, setInvalidEmailCodeMessage] = useState('');
	const [acountLiveStatus, setAcountLiveStatus] =
		useRecoilState(AcountLiveStatus);
	const [signInErrorType, setSignInErrorType] = useState({
		type: '',
		loading: false,
	});

	// Params
	const { error, countryCode, mobile } = useMemo(() => {
		const params = new URLSearchParams(window.location.search);
		const error = params.get('error');
		const mobile = params.get('phone');
		const countryCode = params.get('countryCode');
		return {
			error,
			countryCode,
			mobile,
		};
	}, []);

	// Refs
	const nextDivRef = useRef<HTMLDivElement>(null);

	const [isVerifyModalOpen, setIsVerifyModalOpen] = useState(false);

	//hooks
	const {
		loginWithEmail,
		verifyEmailCode,
		loginWithSocial,
		isLoaded,
		isSocialLogin,
		isSocialDisable,
	} = useLogin();
	const { errorNotification, successNotification } = useNotification();
	const navigate = useNavigate();
	const { userVerfication } = useSignup();

	const { get: reinviteUser } = useNetwork();

	const { onSuccess, onFailed } = props;

	// Pradeep Chaurasia : regex for Mobile number with countryCode
	const hasCountryCode = (value: string) =>
		/^\+[1-9]{1}[0-9]{5,11}$/.test(value);

	const inputType = useMemo((): 'email' | 'phone' => {
		const isPureNumber = /^[0-9 ()-]+$/.test(loginCreds);
		if (
			(isPureNumber && loginCreds.length >= 3) ||
			hasCountryCode(loginCreds)
		) {
			return 'phone';
		}
		return 'email';
	}, [loginCreds]);

	const inputLabel = useMemo(() => {
		if (validateEmail(loginCreds)) {
			return 'Business Email Address';
		}
		return 'Business Email / Phone Number';
	}, [loginCreds]);

	const clearEmailVerificationLink = useCallback(() => {
		setSignInErrorType({
			loading: false,
			type: '',
		});
	}, []);

	useEffect(() => {
		if (signInErrorType.type) {
			clearEmailVerificationLink();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [loginCreds]);

	const sendVerificationCode = async (event: React.MouseEvent<HTMLElement>) => {
		event.preventDefault();
		if (signInErrorType.type === 'mobile' || signInErrorType.type === 'email') {
			// call the api to send verification to mobile
			const { type } = signInErrorType;
			const verificationType =
				signInErrorType.type === 'mobile' ? 'sendSMS' : 'sendEmail';
			try {
				const response = await reinviteUser(
					`${API_URL.USER_ROLES}?email=${encodeURIComponent(
						loginCreds
					)}&${verificationType}=true`
				);
				if (response) {
					const {
						data: { success },
					} = response;
					if (success) {
						successNotification(
							`A verification link has been sent to your ${
								type === 'mobile' ? 'mobile' : 'email'
							}`
						);
						setSignInErrorType({
							type: '',
							loading: false,
						});
					} else {
						errorNotification(Messages.UnExpectedError);
						setSignInErrorType({
							...signInErrorType,
							loading: false,
						});
					}
				}
			} catch (error) {
				errorNotification(Messages.UnExpectedError);
			}
		}
		return;
	};

	const renderVerificationSendLink = () => {
		const linkDisabled = signInErrorType.loading;
		if (signInErrorType.type === 'mobile') {
			return (
				<div style={{ margin: '10px 0 0 0' }}>
					<a
						style={{
							textDecoration: 'underline',
							cursor: 'pointer',
							fontSize: '14px',
							pointerEvents: linkDisabled ? 'none' : 'auto',
						}}
						onClick={sendVerificationCode}
					>
						Resend a verification code to mobile
					</a>
				</div>
			);
		} else if (signInErrorType.type === 'email') {
			return (
				<div style={{ margin: '10px 0 0 0' }}>
					<a
						style={{
							textDecoration: 'underline',
							cursor: 'pointer',
							fontSize: '14px',
							pointerEvents: linkDisabled ? 'none' : 'auto',
						}}
						onClick={sendVerificationCode}
					>
						Resend a verification Link to Email
					</a>
				</div>
			);
		} else {
			return <></>;
		}
	};

	const extractCountryCodeAndNumber = (inputNumber: string) => {
		if (inputType === 'phone') {
			const _country = countries.find(country =>
				inputNumber.startsWith(country.label)
			);

			if (_country) {
				const _countryCode = _country.label;
				const _mobile = inputNumber.slice(_country.label.length);
				return {
					countryCode: _countryCode,
					mobile: _mobile,
				};
			}
		}
		return null;
	};

	const handleSubmitCreds = useCallback(async () => {
		const isValidEmail = isValidSubdomainEmail(loginCreds);
		const validMobileNumber = extractCountryCodeAndNumber(loginCreds);
		let countryLabel: CountryType = 'US';
		const countryObj = countries.find(item => item.label === country);
		if (countryObj) {
			countryLabel = countryObj.code as CountryType;
		}
		const phoneNumber = parsePhoneNumberFromString(loginCreds, countryLabel);
		const isPhoneNumberValid = phoneNumber ? phoneNumber.isValid() : false;
	
		if (inputType === 'email' && !validMobileNumber) {
			if (isValidEmail) {
				const payload = {
					type: 'email',
					email: loginCreds.toLowerCase().trim(),
				};
				const response = await loginWithEmail(payload);
				if (response) {
					const {
						message,
						success,
						token,
						liveAccount,
						countryCode,
						phone,
						errorCode,
						errorData,
					} = response;
					if (errorCode === 400) {
						if (errorData.message === Messages.PhoneError) {
							setSignInErrorType({
								...signInErrorType,
								type: 'mobile',
							});
							errorNotification(`${Messages.PhoneErrorMessage}`);
						} else if (errorData.message === Messages.AccountLocked) {
							errorNotification(`${Messages.AccountLockedErrorMessage}`);
						} else if (errorData.message === Messages.EmailError) {
							setSignInErrorType({
								...signInErrorType,
								type: 'email',
							});
							errorNotification(`${Messages.EmailErrorMesssage}`);
						} else {
							setNewGmail(loginCreds);
							navigate('/signup');
						}
						return;
					}
					if (errorCode) {
						onFailed({
							...(errorData.message ?? MESSAGE.ERROR),
							type: 'email',
						});
						setIsLargeErrorMsg(errorData.message);
						return;
					}
					if (liveAccount) {
						setAcountLiveStatus(liveAccount);
						setCountry(`${countryCode.trim()}`);
						setMaskPhoneCreds(phone);
					} else {
						setAcountLiveStatus(false);
					}
					if (success) {
						if (token) {
							onSuccess({ ...response, type: 'email' });
							return;
						}
						setStep('VERIFY');
						return;
					}
					setIsLargeErrorMsg(message);
					return;
				}
				onFailed({ ...(response ?? MESSAGE.ERROR), type: 'email' });
				setIsLargeErrorMsg(Messages.loginfaildeMessage);
				return;
			}
			// Arun kumar : Giving gap to next btn wrapper
			if (nextDivRef.current) {
				nextDivRef.current.style.marginTop = '0px';
			}
			setIsLargeErrorMsg(Messages.InvalidEmail);
			return;
		} else if (inputType === 'phone') {
			// Handle phone number case
			if (!isPhoneNumberValid) {
				// Set error message for invalid phone number
				if (countryObj?.value && countryObj?.value.length > 0) {
					setInvalidPhoneMessage(`Mobile number is invalid for ${countryObj?.value}`);
				} else {
					setInvalidPhoneMessage(`Invalid mobile number`);
				}
				return;
			}
			const payload = {
				type: 'phoneApproveStatus',
				phone: validMobileNumber?.mobile ?? loginCreds.toLowerCase().trim(),
				countryCode: validMobileNumber?.countryCode ?? country,
			};
			setMaskPhoneCreds(loginCreds);
			const response = await loginWithEmail(payload);
			if (response) {
				const { isVerifiedPhone, message, errorCode, errorData } = response;
				if (errorCode === 400) {
					if (errorData.message === Messages.PhoneError) {
						setSignInErrorType({
							...signInErrorType,
							type: 'mobile',
						});
						errorNotification(`${Messages.PhoneErrorMessage}`);
					} else if (errorData.message === Messages.AccountLocked) {
						errorNotification(`${Messages.AccountLockedErrorMessage}`);
					} else {
						setNewGmail('');
						navigate('/signup');
					}
					return;
				}
				if (errorCode) {
					onFailed({
						...(errorData.message ?? MESSAGE.ERROR),
						type: 'email',
					});
					setIsLargeErrorMsg(errorData.message);
					return;
				}
				if (isVerifiedPhone) {
					setStep('VERIFY');
					return;
				}
				if (isVerifiedPhone === false) {
					setIsVerifyModalOpen(true);
					errorNotification(Messages.NotAuth);
					const payload = {
						phone: loginCreds.toLowerCase().trim(),
						countryCode: country,
						sendSMS: true,
					};
					await userVerfication(payload);
				}
				setInvalidPhoneMessage(message);
				return;
			}
			setInvalidPhoneMessage(Messages.InvalidPhone);
			return;
		}
		setInvalidPhoneMessage(Messages.InvalidPhone);
		return;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		country,
		inputType,
		loginCreds,
		onFailed,
		onSuccess,
		signInErrorType,
		isVerifyModalOpen,
		userVerfication,
	]);

	const handleVerifyUsers = useCallback(async () => {
		const payload = {
			type: 'emailCode',
			email: loginCreds.toLowerCase().trim(),
			code: emailCode.trim(),
		};
		const response = await verifyEmailCode(payload);
		if (response) {
			const { token, message } = response;
			if (response.errorData?.message) {
				errorNotification(response.errorData.message);
			}
			onSuccess({ ...response, type: 'emailCode' });
			if (!token) {
				setInvalidEmailCodeMessage(message);
			}
		} else {
			onFailed({ ...(response ?? MESSAGE.ERROR), type: 'emailCode' });
			setInvalidEmailCodeMessage(MESSAGE.ERROR_TRY);
		}
	}, [
		emailCode,
		errorNotification,
		loginCreds,
		onFailed,
		onSuccess,
		verifyEmailCode,
	]);

	const handleOnClick = useCallback(
		(e: any) => {
			e.preventDefault();
			deleteCookie('user');
			deleteCookie('userEmail');
			localStorage.clear();
			if (step === 'CREDS') {
				handleSubmitCreds();

				return;
			}
			if (step === 'VERIFY') {
				handleVerifyUsers();
				return;
			}
		},
		[deleteCookie, step, handleSubmitCreds, handleVerifyUsers]
	);

	const isDisabled = useMemo(() => {
		if (step === 'VERIFY') {
			return !emailCode?.trim();
		}
		if (step === 'CREDS') {
			return !loginCreds?.trim() || !!invalidPhoneMessage;
		}
		return true;
	}, [emailCode, invalidPhoneMessage, loginCreds, step]);

	const handleOnChange = useCallback(
		(
			e: React.ChangeEvent<HTMLInputElement> | { target: { value: string } }
		) => {
			// Pradeep Chaurasia : condition add for value with countryCode
			const { value } = e.target;

			const phone = value.replace(/\D/g, '');

			if (
				value?.trim()?.startsWith('+') &&
				value?.length > 5 &&
				/^[\d ()+\\-]+$/.test(value)
			) {
				const code = fetchCountryCodeFromPhone(value);
				if (inputType === 'phone' && phone.length > MAX_PHONE_NUMBER) {
					setInvalidPhoneMessage(
						'Phone number length must be less than 11 digit'
					);
				}

				if (inputType === 'phone') {
					setLoginCreds(phone);
				} else {
					setLoginCreds(value);
				}

				if (phone.length < MAX_PHONE_NUMBER) {
					if (value.length < 6) {
						setTemporaryPhoneNumber(phone);
					} else {
						setTemporaryPhoneNumber(value);
					}
				}

				if (code) {
					const newPhone = value.replace(code, '');
					setCountry(code);
					if (newPhone) {
						setLoginCreds(newPhone.replace(/\D/g, ''));
						setTemporaryPhoneNumber(newPhone);
					}
				} else {
					setLoginCreds(phone);
					setTemporaryPhoneNumber(phone);
				}
			} else {
				if (
					inputType === 'phone' &&
					value.replace(/\D/g, '').length > MAX_PHONE_NUMBER
				) {
					setInvalidPhoneMessage(
						'Phone number length must be less than 11 digit'
					);
					return;
				}

				if (inputType === 'phone') {
					setLoginCreds(phone);
				} else {
					setLoginCreds(value);
				}

				if (value.length < 6) {
					setTemporaryPhoneNumber(phone);
				} else {
					setTemporaryPhoneNumber(value);
				}
			}

			setIsLargeErrorMsg('');
			setInvalidPhoneMessage('');
			setEmailCode('');
			setInvalidEmailCodeMessage('');
			setStep('CREDS');
			// Arun kumar : Giving gap to next btn wrapper
			if (nextDivRef.current) {
				nextDivRef.current.style.marginTop = '0px';
			}
		},
		[inputType, setCountry, setLoginCreds, setStep]
	);

	const getPhoneNumber = useMemo(() => {
		let countryLabel: CountryType = 'US';

		/*  
		If `temporaryPhoneNumber` is empty, then use `phoneNumber`. There is one error: if the user is not registered, 
		they will be redirected to the signup page. If the user returns to the login page afterward, 
		temporaryPhoneNumber will be empty, and `logincreds` will contain the previous number.
		Consequently, the signin is enabled, but it should be disabled in this case.
		*/
		const phoneNumberInput = temporaryPhoneNumber ? temporaryPhoneNumber : loginCreds;

		const countryObj = countries.find(item => item.label === country);
		if (countryObj) {
			countryLabel = countryObj.code as CountryType;
		}
	
		let formattedNumber = '';
		const phoneNumber = parsePhoneNumberFromString(phoneNumberInput, countryLabel);
		if (phoneNumberInput?.length > 6) {
			formattedNumber = new AsYouType(countryLabel).input(phoneNumberInput);
			if (phoneNumber && phoneNumber.isValid()) {
				formattedNumber = phoneNumber.formatNational();
				// Remove leading zero if it exists
				if (formattedNumber.startsWith('0')) {
					formattedNumber = formattedNumber.slice(1);
				}
				return formattedNumber.replace(`+${phoneNumber.countryCallingCode} `, '');
			}
		} else {
			formattedNumber = phoneNumberInput;
		}
		return formattedNumber;
	}, [temporaryPhoneNumber, loginCreds, country]);

	useEffect(() => {
		// Clear the invalid phone message when the country code changes
		setInvalidPhoneMessage('');
	}, [country]);
	
	const renderInputs = useMemo(() => {
		switch (step) {
			case 'VERIFY':
				return (
					<ChooseMethod
						methods={methods}
						{...props}
						locationInfo={locationInfo}
						ipAddress={ipAddress}
						loginPhoneNumber={loginCreds}
						send_otp_api={send_otp_api}
					/>
				);
			case 'CREDS':
				return (
					<>
						{inputType === 'phone' ? (
							<>
								<Input
									handleChange={handleOnChange}
									inputType="text" //keep type text as number is not giving suggestions for auto fill will use regex to accept number
									value={getPhoneNumber}
									placeholder={``}
									label="Mobile Number"
									handleChangeCountry={e => {
										setCountry(e.label);
									}}
									isCountryCodeVisible={true}
									countryCode={country}
									isError={!!invalidPhoneMessage}
									errorMessage={invalidPhoneMessage}
									autoComplete="tel"
									isRequired
								/>

								{signInErrorType && renderVerificationSendLink()}
							</>
						) : (
							<>
								<Input
									inputType="text"
									handleChange={handleOnChange}
									value={loginCreds}
									placeholder=""
									label={inputLabel}
									isError={!!isLargeErrorMsg}
									errorMessage={
										isMobileView ? Messages.isSmallErrorMsg : isLargeErrorMsg
									}
									isRequired={true}
									autoFocus={true}
								/>
								{signInErrorType && renderVerificationSendLink()}
							</>
						)}
					</>
				);
			default:
				return <></>;
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		step,
		inputType,
		acountLiveStatus,
		loginCreds,
		emailCode,
		invalidEmailCodeMessage,
		methods,
		props,
		locationInfo,
		ipAddress,
		send_otp_api,
		country,
		invalidPhoneMessage,
		signInErrorType,
		isLargeErrorMsg,
		getPhoneNumber,
	]);

	const goToSingUp = useCallback(() => {
		navigate('/signup');
	}, [navigate]);

	const isDisableButton = useMemo(
		() => !isLoaded || isSocialDisable || isDisabled,
		[isLoaded, isDisabled, isSocialDisable]
	);

	const manageButtonLabel = useMemo((): string | JSX.Element => {
		if (!isLoaded) {
			return <Loader type="loader" className="loader-white" dimension={18} />;
		}
		return 'Next';
	}, [isLoaded]);

	const renderForm = useMemo(() => {
		return (
			<form className={style.form_wrapper} autoComplete="on">
				<div className={`${style.input_wrapper} input-wrapper`}>
					{renderInputs}
				</div>
				{step !== 'VERIFY' && (
					<div ref={nextDivRef} className="input-wrapper__next-wrapper">
						<Button
							type={'button__filled--primary button__large button__block'}
							handleClick={handleOnClick}
							label={manageButtonLabel}
							disabled={isDisableButton}
						/>
					</div>
				)}
			</form>
		);
	}, [renderInputs, step, handleOnClick, manageButtonLabel, isDisableButton]);

	useEffect(() => {
		if (error) {
			errorNotification(decodeURIComponent(error));
		}
		if (mobile && countryCode) {
			setCountry(`+${countryCode.trim()}`);
			setLoginCreds(mobile);
			setMaskPhoneCreds(mobile);
			setStep('VERIFY');
			handleSubmitCreds();
		}
		// eslint-disable-next-line
	}, []);

	const [isImageLoad, setImageLoad] = useState(false);

	const showSkeltonLoader = useMemo(() => {
		if (!isImageLoad) {
			return (
				<div className="social-icon">
					<SessionInfoChild height={45} width={100} borderRadius={8} />
				</div>
			);
		}
		return <></>;
	}, [isImageLoad]);

	const hideIconOnLoader = classNames('social-icon', {
		'Social--Icon__hide': !isImageLoad,
	});

	const renderVerifyModal = useMemo(() => {
		return (
			isVerifyModalOpen && (
				<VerifyModal
					visible={isVerifyModalOpen}
					handleModal={() => setIsVerifyModalOpen(false)}
					regEmail={loginCreds.toLowerCase().trim()}
				/>
			)
		);
	}, [isVerifyModalOpen, loginCreds]);

	return (
		<div className={style.wrapper}>
			<div className={style.inner_wrapper}>
				<div className={style.inner}>
					{!(step === 'VERIFY') && (
						<div>
							<div className={style.title}>Log in</div>
							<div className={style.sub_title}>
								Please log in to your account.
							</div>
							<div className="account-wrapper">
								<div className="social-login-wrapper">
									<div className="social-login-wrapper__icon">
										{/*Anuj : Commented for patch release because from backend facebook login is not fixed */}
										{/* <div onClick={() => loginWithSocial('facebook')}>
											{showSkeltonLoader}
											<Image
												className={hideIconOnLoader}
												fileName="facebook.svg"
												onLoad={() => setImageLoad(true)}
											/>
										</div> */}
										{!isSocialLogin.google ? (
											<button
												disabled={isSocialDisable}
												onClick={() => loginWithSocial('google', host)}
											>
												{showSkeltonLoader}

												<Image
													className={hideIconOnLoader}
													fileName="google.svg"
													onLoad={() => setImageLoad(true)}
												/>
											</button>
										) : (
											<div className="social-login-wrapper--icon-loader">
												<Loader dimension={20} type="loader" />
											</div>
										)}
										{!isIframe && (
											<>
												{!isSocialLogin.apple ? (
													<button
														disabled={isSocialDisable}
														onClick={() => loginWithSocial('apple', host)}
													>
														{showSkeltonLoader}
														<Image
															className={hideIconOnLoader}
															fileName="apple.svg"
															onLoad={() => setImageLoad(true)}
														/>
													</button>
												) : (
													<div className="social-login-wrapper--icon-loader">
														<Loader dimension={20} type="loader" />
													</div>
												)}
												{!isSocialLogin.microsoft ? (
													<button
														disabled={isSocialDisable}
														onClick={() => loginWithSocial('microsoft', host)}
													>
														{showSkeltonLoader}
														<Image
															className={hideIconOnLoader}
															fileName="microsoft.svg"
															onLoad={() => setImageLoad(true)}
														/>
													</button>
												) : (
													<div className="social-login-wrapper--icon-loader">
														<Loader dimension={20} type="loader" />
													</div>
												)}
											</>
										)}
									</div>
								</div>
								<div className="login-divider">
									<span>or</span>
								</div>
							</div>
						</div>
					)}
					{renderForm}
					{!(step === 'VERIFY') && (
						<div className="create-account">
							<span>Don’t have an account?</span>
							<div className="go-to-signup" onClick={goToSingUp}>
								Sign Up
							</div>
						</div>
					)}
				</div>
			</div>
			{renderVerifyModal}
		</div>
	);
};
