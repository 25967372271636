import { createElement, useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import { ComponentCollection, ElementFactory, Question, Serializer, SvgRegistry } from 'survey-core';

import { surveyJSON } from 'components/survey-form-builder/constants';
import { SurveyBuilder } from 'components/survey-form-builder';
import { useAddDollarCurrencySurvey } from 'hooks/use-add-dollar-currency';
import { ComplexSettingFormState } from 'views/pipeline';
import { ConfigureSurvayFormState } from 'views/onboarding-flow';
import { ReactQuestionFactory } from 'survey-react-ui';
import { SurveyQuestionColorPicker } from 'components/investor-questionnair/investor-questionnair';
import { PropertyGridEditorCollection, localization } from 'survey-creator-core';
import { CustomCountryStateQuestion } from 'components/survey-form-builder/custom-questions/custom-country-state-question/custom-country-state-question';
import { CustomAddressPicker } from 'components/survey-form-builder/custom-questions/custom-address-picker';
import { CustomCountryQuestion } from 'components/survey-form-builder/custom-questions/custom-country-question';
import { QuantitySelectorQuestion } from 'components/survey-form-builder/custom-questions/quantity-question';
import { CustomDatePicker } from '../custom-date-picker/custom-date-picker';

interface IFormProps {
	creator: any;
	nodeId?: string;
}

const DATE_PICKER = 'datePicker';

class QuestionDatePicker extends Question {
	getType() {
		return DATE_PICKER;
	}
}

Serializer.addClass(
	DATE_PICKER,
	[
		{
			name: 'dateFormat',
			default: 'mm/dd/yyyy',
			type: 'dropdown',
			choices: ['mm/dd/yyyy', 'dd/mm/yyyy', 'yyyy/mm/dd'],
			category: 'general',
		},
	],
	function () {
		return new QuestionDatePicker('');
	},
	'question'
);

ElementFactory.Instance.registerElement(DATE_PICKER, name => {
	return new QuestionDatePicker(name);
});

ReactQuestionFactory.Instance.registerQuestion(DATE_PICKER, props => {
	return createElement(CustomDatePicker, props);
});

SvgRegistry.registerIconFromSvg(
	DATE_PICKER,
	'<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"><path fill="none" d="M0 0h24v24H0z"></path><path d="M9 1V3H15V1H17V3H21C21.5523 3 22 3.44772 22 4V20C22 20.5523 21.5523 21 21 21H3C2.44772 21 2 20.5523 2 20V4C2 3.44772 2.44772 3 3 3H7V1H9ZM20 11H4V19H20V11ZM7 5H4V9H20V5H17V7H15V5H9V7H7V5Z"></path></svg>'
);

ComponentCollection.Instance.add({
	name: 'mcc',
	title: 'MCC',
	questionJSON: {
		type: 'dropdown',
		title: 'Select mcc...',
		choicesByUrl: {
			url: 'https://rhiegx7hc6esd2ssihgil2nf2q0ncgvj.lambda-url.us-east-1.on.aws/',
		},
	},
});

const CUSTOM_TYPE = "investors-detail";

// QuestionAddressPicker
class QuestionAddressPicker extends Question {
	getType() {
		return 'addressPicker';
	}
}

Serializer.addClass(
	'addressPicker',
	[
		{
			name: 'addressPicker:boolean',
			dependsOn: 'addressPicker',
			visibleIf: () => false,
			category: 'general',
		},
	],
	function () {
		return new QuestionAddressPicker('');
	},
	'question'
);
ElementFactory.Instance.registerElement('addressPicker', name => {
	return new QuestionAddressPicker(name);
});

ReactQuestionFactory.Instance.registerQuestion(
	'addressPicker',
	props => {
		return createElement(CustomAddressPicker, props);
	}
);

SvgRegistry.registerIconFromSvg(
	'addressPicker',
	'<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"><path d="M17.0839 15.812C19.6827 13.0691 19.6379 8.73845 16.9497 6.05025C14.2161 3.31658 9.78392 3.31658 7.05025 6.05025C4.36205 8.73845 4.31734 13.0691 6.91612 15.812C7.97763 14.1228 9.8577 13 12 13C14.1423 13 16.0224 14.1228 17.0839 15.812ZM8.38535 17.2848L12 20.8995L15.6147 17.2848C14.9725 15.9339 13.5953 15 12 15C10.4047 15 9.0275 15.9339 8.38535 17.2848ZM12 23.7279L5.63604 17.364C2.12132 13.8492 2.12132 8.15076 5.63604 4.63604C9.15076 1.12132 14.8492 1.12132 18.364 4.63604C21.8787 8.15076 21.8787 13.8492 18.364 17.364L12 23.7279ZM12 10C12.5523 10 13 9.55228 13 9C13 8.44772 12.5523 8 12 8C11.4477 8 11 8.44772 11 9C11 9.55228 11.4477 10 12 10ZM12 12C10.3431 12 9 10.6569 9 9C9 7.34315 10.3431 6 12 6C13.6569 6 15 7.34315 15 9C15 10.6569 13.6569 12 12 12Z"></path></svg>'
);

class QuestionCountryStateModel extends Question {
	getType() {
		return 'countryStateSelector';
	}
}

Serializer.addClass(
	'countryStateSelector',
	[
		{
			name: "countryStateSelector:boolean",
			dependsOn: "countryStateSelector",
			visibleIf: () => false,
			category: "general",
		},
	],
	function () {
		return new QuestionCountryStateModel("");
	},
	"question"
);
ElementFactory.Instance.registerElement('countryStateSelector', (name) => {
	return new QuestionCountryStateModel(name);
});


ReactQuestionFactory.Instance.registerQuestion('countryStateSelector', (props) => {
	return createElement(CustomCountryStateQuestion, props);
});

SvgRegistry.registerIconFromSvg(
	'countryStateSelector',
	'<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"><path d="M12.382 3C12.7607 3 13.107 3.214 13.2764 3.55279L14 5H20C20.5523 5 21 5.44772 21 6V17C21 17.5523 20.5523 18 20 18H13.618C13.2393 18 12.893 17.786 12.7236 17.4472L12 16H5V22H3V3H12.382ZM11.7639 5H5V14H13.2361L14.2361 16H19V7H12.7639L11.7639 5Z"></path></svg>'
);

//QuantitySelectorQuestion
class QuantitySelectorQuestionModel extends Question {
	getType() {
		return 'fundInvestmentAmount';
	}
}
Serializer.addClass(
	'fundInvestmentAmount',
	[
		{
			name: "fundInvestmentAmount:boolean",
			dependsOn: "fundInvestmentAmount",
			visibleIf: () => false,
			category: "general",
		},
	],
	function () {
		return new QuantitySelectorQuestionModel("");
	},
	"question"
);
ElementFactory.Instance.registerElement('fundInvestmentAmount', (name) => {
	return new QuantitySelectorQuestionModel(name);
});


ReactQuestionFactory.Instance.registerQuestion('fundInvestmentAmount', (props) => {
	return createElement(QuantitySelectorQuestion, props);
});

SvgRegistry.registerIconFromSvg(
	'fundInvestmentAmount', '<svg class="sv-svg-icon" role="img" style="width: 24px; height: 24px;"><use xlink:href="#icon-matrix"></use><title>Single-Select Matrix</title></svg>');

//Country
class QuestionCountryModel extends Question {
	getType() {
		return 'CountrySelector';
	}
}

Serializer.addClass(
	'CountrySelector',
	[
		{
			name: "CountrySelector:boolean",
			dependsOn: "CountrySelector",
			visibleIf: () => false,
			category: "general",
		},
	],
	function () {
		return new QuestionCountryModel("");
	},
	"question"
);
ElementFactory.Instance.registerElement('CountrySelector', (name) => {
	return new QuestionCountryModel(name);
});


ReactQuestionFactory.Instance.registerQuestion('CountrySelector', (props) => {
	return createElement(CustomCountryQuestion, props);
});

SvgRegistry.registerIconFromSvg(
	'CountrySelector',
	'<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"><path d="M12.382 3C12.7607 3 13.107 3.214 13.2764 3.55279L14 5H20C20.5523 5 21 5.44772 21 6V17C21 17.5523 20.5523 18 20 18H13.618C13.2393 18 12.893 17.786 12.7236 17.4472L12 16H5V22H3V3H12.382ZM11.7639 5H5V14H13.2361L14.2361 16H19V7H12.7639L11.7639 5Z"></path></svg>'
);

// Color Picker
export class QuestionColorPickerModel extends Question {
	getType() {
		return CUSTOM_TYPE;
	}

	get disableAlpha() {
		return this.getPropertyValue("investors-detail");
	}
	set disableAlpha(val) {
		this.setPropertyValue("investors-detail", val);
	}
}

Serializer.addClass(
	CUSTOM_TYPE,
	[
		// {
		//   name: "colorPickerType",
		//   default: "Slider",
		//   choices: ["Slider", "Sketch", "Compact"],
		//   category: "general",
		//   visibleIndex: 2, // After the Name and Title
		// },
		/**
		 * sidebar editor configurations will be added in here...
		 *
		 * */
		{
			name: "investors-detail:boolean",
			dependsOn: "investors-detail",
			visibleIf: () => false,
			category: "general",
			// visibleIndex: 3, // After the Name, Title, and Color Picker type
		},
	],
	function () {
		return new QuestionColorPickerModel("");
	},
	"question"
);

ElementFactory.Instance.registerElement(CUSTOM_TYPE, (name) => {
	return new QuestionColorPickerModel(name);
});


ReactQuestionFactory.Instance.registerQuestion(CUSTOM_TYPE, (props) => {
	return createElement(SurveyQuestionColorPicker, props);
});

const locale = localization.getLocale("");
locale.qt[CUSTOM_TYPE] = "Shareholders Details";
locale.qt['countryStateSelector'] = "Country And State";
locale.qt['countryelector'] = "Country";
locale.qt['fundInvestmentAmount'] = "Fund Investment Amount";
locale.qt['addressPicker'] = "Address Picker";
locale.pe.colorPickerType = "Shareholders Details type";
locale.pe.disableAlpha = "Disable alpha channel";
locale.qt[DATE_PICKER] = 'Date Picker';

SvgRegistry.registerIconFromSvg(
	CUSTOM_TYPE,
	'<svg class="sv-svg-icon sv-list__item-icon" role="img" style="width: 24px; height: 24px;"><use xlink:href="#icon-matrix"></use></svg>'
);

PropertyGridEditorCollection.register({
	fit: function (prop) {
		// return prop.type === "color";
		return prop.type === "investors";
	},
	getJSON: function () {
		return {
			type: CUSTOM_TYPE,
			// colorPickerType: "Compact",
		};
	},
});



export const FormBuilder: React.FC<IFormProps> = ({ creator, nodeId }) => {
	//globle state
	const configFormValue = useRecoilValue(ConfigureSurvayFormState);
	const complexSettingFormState = useRecoilValue(ComplexSettingFormState);

	const { modifyPagesResponse } = useAddDollarCurrencySurvey();

	useEffect(() => {
		creator.onElementAllowOperations.add((_: any, options: any) => {
			if (options.obj?.getType() === "investors-detail") {
				options.allowCopy = false;
				//   options.allowChangeRequired = false;
			}
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	useEffect(() => {
		creator.JSON = surveyJSON;
		creator.showSidebar = false;
		creator.toolbox.actions.forEach((item: any, index: number) => {
			if (item?.json?.type === 'mcc') {
				const removed = creator.toolbox.actions.splice(index, 1);
				creator.toolbox.actions.splice(2, 0, removed[0]);
			}
			// Check if the item's type is equal to DATE_PICKER
			if (item?.json?.type === DATE_PICKER) {
				// Update the title of the item to 'Date Picker'
				item.title = 'Date Picker';

				// Update the item's json properties to set the date picker type and default properties
				item.json = {
					type: DATE_PICKER, // Set the item type as DATE_PICKER
					title: 'Select Date', // Set the title for the date picker
					dateFormat: 'mm/dd/yyyy', // Set the default date format
				};

				// Remove the item from its current position in the toolbox actions array
				const removed = creator.toolbox.actions.splice(index, 1);

				// Insert the removed item at position 7 in the toolbox actions array
				creator.toolbox.actions.splice(7, 0, removed[0]);
			}
		});
		// Shahbaaz: seperated state form complex and liner
		if (nodeId && complexSettingFormState[nodeId]) {
			const resultPage = complexSettingFormState[nodeId];
			creator.JSON = {
				...resultPage,
				pages: modifyPagesResponse(resultPage?.pages ?? []),
			};
		} else {
			if (configFormValue) {
				creator.JSON = {
					...configFormValue,
					pages: modifyPagesResponse(configFormValue?.pages ?? []),
				};
			}
		}
	}, [
		creator,
		configFormValue,
		nodeId,
		complexSettingFormState,
		modifyPagesResponse,
	]);

	return <SurveyBuilder creator={creator} />;
};