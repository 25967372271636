import { FC } from 'react';
import './categories-results.scss';

interface ICategoryItem {
	label?: string;
	value: string[];
}

export const CategoriesResult: FC<{ items: ICategoryItem[] }> = ({ items }) => {
	return (
		<div className="CategoriesResult">
			{items?.map((item, index) => (
				<div key={index?.toString()} className="CategoriesResult__row">
					{item?.label && (
						<div className="CategoriesResult__label">
							<div>{item.label}</div>
						</div>
					)}
					<div className="CategoriesResult__value">
						{item?.label && <span>:</span>}
						<div className="CategoriesResult__value-list">
							{item?.value?.length
								? item?.value?.map((val, valIndex) => (
										<div
											key={valIndex?.toString()}
											className={`CategoriesResult__value-item ${
												!item?.label ? 'CategoriesResult__labelItem' : ''
											}`}
										>
											{val ?? 'NA'}
										</div>
								  ))
								: 'NA'}
						</div>
					</div>
				</div>
			))}
		</div>
	);
};
