import React, { useCallback, useMemo } from 'react';
import './assigndropdown.scss';
import { useRecoilValue } from 'recoil';
import {
	isAssigneeCheckLoadingState,
	selectedReviewersState,
} from 'global-stores';
import { Loader } from '@storybook';

export interface IReviewer {
	id: string | null;
	name: string;
	image: string | null;
}
export interface Assignee {
	_id: string;
	firstName: string;
	image: string;
	lastName: string;
}
interface ReviewerDropdownProps {
	reviewers: IReviewer[];
	searchTerm: string;
	setSearchTerm: React.Dispatch<React.SetStateAction<string>>;
	handleReviewerSelect: (item: IReviewer) => void;
	isFiltter: boolean;
	isSearch: boolean;
}

const ReviewerDropdown: React.FC<ReviewerDropdownProps> = ({
	reviewers,
	searchTerm,
	setSearchTerm,
	handleReviewerSelect,
	isFiltter,
	isSearch,
}) => {
	const selectedReviewers = useRecoilValue(selectedReviewersState);
	const isAssigneeCheckLoading = useRecoilValue(isAssigneeCheckLoadingState);
	const filteredReviewers = useMemo(
		() =>
			reviewers.filter(reviewer =>
				reviewer.name.toLowerCase().includes(searchTerm.toLowerCase())
			),
		[reviewers, searchTerm]
	);

	const handleSearchChange = useCallback(
		(e: React.ChangeEvent<HTMLInputElement>) => {
			setSearchTerm(e.target.value.toLowerCase());
		},
		[setSearchTerm]
	);

	const isSelected = useCallback(
		(reviewer: { id: string | null }) => {
			return selectedReviewers.some(
				(assignee: { id: string | null }) => assignee.id === reviewer.id
			);
		},
		[selectedReviewers]
	);

	return (
		<div className="reviewer-assigne">
			<div className="search-bar">
				{isSearch && (
					<input
						type="text"
						placeholder="Search..."
						className="search-input"
						value={searchTerm}
						onChange={handleSearchChange}
					/>
				)}
			</div>

			<ul className="reviewer-list">
				{filteredReviewers.length > 0 ? (
					filteredReviewers.map(reviewer => (
						<li
							className={`reviewer-item ${
								isSelected(reviewer) ? 'selected' : ''
							}`}
							key={reviewer.id}
							onClick={() => handleReviewerSelect(reviewer)}
						>
							<div className="reviewer-content">
								{isFiltter &&
									(isAssigneeCheckLoading === reviewer.id ? (
										<div style={{ width: 20, height: 18 }}>
											<Loader type="loader" dimension={18} />
										</div>
									) : (
										<input
											type="checkbox"
											id={reviewer.id || ''}
											checked={isSelected(reviewer)}
											readOnly
											className="reviewer-checkbox"
										/>
									))}
								{reviewer.image ? (
									reviewer.image === 'null' ? (
										<div className="Unassign-image">
											<i className="ri-user-line" />
										</div>
									) : (
										<img
											src={reviewer.image || ''}
											alt={reviewer.name}
											className="reviewer-image"
										/>
									)
								) : (
									<div className="Unassign-image" />
								)}

								<span>{reviewer.name}</span>
							</div>
						</li>
					))
				) : (
					<p className="no-results">No reviewers found</p>
				)}
			</ul>
		</div>
	);
};

export default ReviewerDropdown;
