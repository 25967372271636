import { useSetRecoilState } from "recoil";
import { AssigneeSessionState, IsAssigneeSessionLoadingState } from "../store";
import { useNetwork } from "hooks";
import { toast } from "react-toastify";
import { API_URL } from "constant";
import { useCallback } from "react";



export const useSessionAssign = () => {
    const setSessionAssignee = useSetRecoilState(AssigneeSessionState);
	const setIsAssigneeSessionLoading = useSetRecoilState(IsAssigneeSessionLoadingState);

    const {  get,post } = useNetwork();


	const AssignReviewer = useCallback(() => {
		get(API_URL.ASSIGNEE_USERS)
			.then((res) => {
				setIsAssigneeSessionLoading(false);
				setSessionAssignee(res?.data);
			})
			.catch((error) => {
				setIsAssigneeSessionLoading(false);
				toast.error(error);
			});
	}, [get, setIsAssigneeSessionLoading, setSessionAssignee]);
	
	const SessionAssignToReviewer = useCallback(async (_assignee: string, _sessionIds: Array<string>,comments:string): Promise<boolean> => {
		return await post(API_URL.SESSION_ASSIGNEE, {
			assignee: _assignee,
			sessionIds: _sessionIds,
			comment:comments,
		}).then(res => {
			return res.success; 
		}).catch(error=>{
			return error
		});
		
	},[post]);
	

    return {AssignReviewer,SessionAssignToReviewer};
}