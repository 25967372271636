import type { ISignedDocInbox, IInboxAction } from '../store';

import Progress from '@storybook/progress';
import { FC, useMemo, useState } from 'react';

import { STATUS_HEADER } from '../store';
import { SignedDocMenuTab } from './menu-tab';

interface IProps {
	inbox: ISignedDocInbox;
	action: IInboxAction;
}

interface IStatusObj {
	needToSign: string;
	waitingForOthers: string;
	processing: string;
	voided: string;
	completed: string;
	editable?: string;
}

const statusObj: IStatusObj  = {
	needToSign : 'Need to sign',
	waitingForOthers: 'Waiting for others',
	processing: 'Processing Document',
	voided: 'Voided',
	completed: 'Completed',
}

export const InboxStatus: FC<IProps> = ({ inbox, action }) => {
	const [isOpen, setIsOpen] = useState(false);

	const completedRecipients = useMemo(() => {
		let count = 0;
		inbox.recipients.forEach(recipient => {
			if (recipient.status === 'completed') {
				count += 1;
			}
		});
		return count;
	}, [inbox]);

	const renderStats = useMemo(() => {
		return `${completedRecipients}/${inbox.recipients.length} done`;
	}, [completedRecipients, inbox]);

	const statusLabel = useMemo(() => {
		return statusObj[action];
	}, [action]);

	const getStatusLabel = useMemo(() => {
		return <div className="InboxStatus__need-to-sign">{statusLabel}</div>;
	}, [statusLabel]);

	const doesCompletedExists = useMemo(
		() => inbox.recipients.some(item => item.status == 'completed'),
		[inbox.recipients]
	);
	const doesUnderReviewExists = useMemo(
		() => inbox.recipients.some(item => item.status == 'underReview'),
		[inbox.recipients]
	);
	const doesPendingExists = useMemo(
		() => inbox.recipients.some(item => item.status == 'pending'),
		[inbox.recipients]
	);

	const doesApprovedExists = useMemo(
		() => inbox.recipients.some(item => item.status === 'approved'),
		[inbox.recipients]
	);

	const renderSigningStatus = useMemo(() => {
		return (
			<div className="InboxStatus__signing-status">
				{STATUS_HEADER.map(({ key, label }) => {
					if (key === 'completed' && action === 'completed') {
						return;
					} else if (key === 'completed' && !doesCompletedExists) {
						return;
					} else if (key === 'underReview' && !doesUnderReviewExists) {
						return;
					} else if (key === 'pending' && !doesPendingExists) {
						return;
					}else if (key === 'approved' && !doesApprovedExists) {
						return;
					}
					return (
						<div key={key} className="InboxStatus__signing-status-card-item">
							<div className="InboxStatus__status-card-header">{label}</div>
							<div className="InboxStatus__status-card-details">
								{inbox.recipients.map(recipient => {
									if (key === 'pending') {
										return (
											recipient.status === 'pending' && (
												<div
													key={recipient.email}
													className="InboxStatus__status-user"
												>
													<div className="InboxStatus__status-user-color" />
													<div className="InboxStatus__status-user-item">
														<div className="InboxStatus__status-user-name">
															{recipient.name}
														</div>
														<div className="InboxStatus__status-user-email">
															{recipient.email?.toLowerCase()}
														</div>
													</div>
												</div>
											)
										);
									}
									if (key === 'underReview') {
										return (
											recipient.status === 'underReview' && (
												<div
													key={recipient.email}
													className="InboxStatus__status-user"
												>
													<div className="InboxStatus__status-user-color-review" />
													<div className="InboxStatus__status-user-item">
														<div className="InboxStatus__status-user-name">
															{recipient.name}
														</div>
														<div className="InboxStatus__status-user-email">
															{recipient.email?.toLowerCase()}
														</div>
													</div>
												</div>
											)
										);
									}
									if (key === 'completed') {
										return (
											recipient.status === 'completed' && (
												<div
													key={recipient.email}
													className="InboxStatus__status-user"
												>
													<div className="InboxStatus__status-user-color-completed" />
													<div className="InboxStatus__status-user-item">
														<div className="InboxStatus__status-user-name">
															{recipient.name}
														</div>
														<div className="InboxStatus__status-user-email">
															{recipient.email?.toLowerCase()}
														</div>
													</div>
												</div>
											)
										);
									}
									if (key === 'approved') {
										return (
											recipient.status === 'approved' && (
												<div
													key={recipient.email}
													className="InboxStatus__status-user"
												>
													<div className="InboxStatus__status-user-color-approved" />
													<div className="InboxStatus__status-user-item">
														<div className="InboxStatus__status-user-name">
															{recipient.name}
														</div>
														<div className="InboxStatus__status-user-email">
															{recipient.email?.toLowerCase()}
														</div>
													</div>
												</div>
											)
										);
									}
									return <></>;
								})}
							</div>
						</div>
					);
				})}
			</div>
		);
	}, [action, doesCompletedExists, doesUnderReviewExists, doesPendingExists, doesApprovedExists, inbox.recipients]);

	/**
	 * @Manish Calculate and render the status information based on the action
	 * */
	const renderStatus = useMemo(() => {
		if (action === 'voided' || action === "completed" || action === "processing") {
			/**
			 * @Manish If the action is 'voided', display 'Voided' status
			 * */
			return statusLabel;
		} else {
			/**
			 * @Manish If the action is not 'completed' or 'voided', render progress and stats
			 * */
			return (
				<>
					<div className="InboxStatus__status-stats-wrapper">
						{/* @Manish Render progress bar based on completed recipients */}
						<div className="InboxStatus__progress-wrapper">
							<Progress
								bgcolor="#33B87A"
								progress={(completedRecipients / inbox.recipients.length) * 100}
								parentStyle={{ width: '100%', height: '4px' }}
								childStyle={{ height: '4px' }}
							/>
						</div>
						{/* @Manish Display statistics related to the status */}
						<div className="InboxStatus__stats">{renderStats}</div>
					</div>
					{/* @Manish Display the label associated with the status */}
					<div className="InboxStatus__status-action-label">
						{getStatusLabel}
					</div>
				</>
			);
		}
	}, [action, completedRecipients, getStatusLabel, inbox.recipients.length, renderStats, statusLabel]);
	// Rahul SIngh: added inbox.status !== 'completed'
	return (
		<>
			<div
				className="InboxStatus__status"
				onMouseEnter={() => setIsOpen(true)}
				onMouseLeave={() => setIsOpen(false)}
				id={`signed-doc-inbox-status-${inbox._id}`}
			>
				{renderStatus}
				{isOpen &&
					completedRecipients !== inbox.recipients.length &&
					action !== 'voided' &&
					inbox.status !== 'completed' && (
						<SignedDocMenuTab
							setIsOpen={setIsOpen}
							elementId={`signed-doc-inbox-status-${inbox._id}`}
						>
							{renderSigningStatus}
						</SignedDocMenuTab>
					)}
			</div>
		</>
	);
};
