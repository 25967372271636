import { FC } from 'react';

interface IKeyValuePair {
	label: string;
	value: string | number;
	isDanger?: boolean;
}

export const KeyValuePair: FC<IKeyValuePair> = ({ label, value, isDanger }) => {
	return (
		<div key={label} className="ofac__row">
			<div className="ofac__row__label">{label || '--'}</div>

			<div className="ofac__row__value">
				<span>:</span>
				<div className={`${isDanger ? "high-risk-value" : ""}`} >{value || '--'}</div>
			</div>
		</div>
	);
};
