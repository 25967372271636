import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';

import { SelectedOnboardingAction } from 'global-stores';
import { NodesState } from 'views/complex-onboarding-flow/stores/states';
import { AddBlockButton } from '../add-button';
import { Label } from '../condition-label';
import { CustomHandle } from '../custom-handle';
import { RenderAmlQuestion } from './render-aml-question';
import { ComplexSettingFormState } from 'views/pipeline';

export interface IRenderBlock {
	label?: string;
	price?: number;
	stepId: string;
	nodeId: string;
	key: string;
}

export const RenderAmlBlock: FC<IRenderBlock> = ({
	label,
	price,
	nodeId,
	stepId,
}) => {
	// Global variables

	const [blockCount, setBlockCount] = useState(0);
	const [nodes, setNodes] = useRecoilState(NodesState);
	const setSelectedOnboarding = useRecoilValue(SelectedOnboardingAction);
	const complexSettingForm= useRecoilValue(
		ComplexSettingFormState
	);

	const { conditions: aml } = useMemo(
		() => nodes?.find((el: any) => el.id === nodeId) ?? [],
		[nodes, nodeId]
	);
	/* 
	@avinashSatschel
    # Set the block count value for "modify" and "clone" operations
    # Since the node already has a block 0 and other blocks, we need to find the
    # maximum block number currently in use and set it as the block count to avoid
    # block number conflicts when modifying or cloning the node.
    # This ensures that the new node has a unique block number.
    */
	useEffect(() => {
		if (/modify|clone/.test(setSelectedOnboarding.type)) {
			const currentNodesConditions = nodes.find(
				(el: any) => el.id === nodeId
			)?.conditions;
			if (currentNodesConditions) {
				const allNumber = [];

				for (let i = 0; i < currentNodesConditions.length; i++) {
					allNumber.push(
						currentNodesConditions[i]?.blockId?.split('_')?.[1] ?? 0
					);
				}
				setBlockCount(Math.max(...allNumber) + 1);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const addNewBlock = useCallback(() => {
		setBlockCount(prev => prev + 1);
		const count = blockCount + 1;
		const payload = {
			and: [{}],
			blockId: `block_${count}`,
			currentId: nodeId,
		};

		setNodes((prev: any) => {
			const prevObj = JSON.parse(JSON.stringify(prev));
			const findIndex = prevObj.findIndex((el: any) => el.id === nodeId);
			prevObj[findIndex].conditions.push(payload);
			return prevObj;
		});
	}, [blockCount, nodeId, setNodes]);

	const kybBlockList = useMemo(
		() => aml?.filter((el: any) => el.currentId === nodeId),
		[aml, nodeId]
	);

	const renderPriceLabel = useCallback(() => {
		if (complexSettingForm && complexSettingForm[nodeId ?? '']?.amlFrequency === 'dailyOnGoing') {
			return 'Per year';
		} else return 'Verification';
	}, [complexSettingForm, nodeId]);

	return (
		<>
			<CustomHandle />
			<Label label={label || ''} price={price || 0} priceLabel={renderPriceLabel()} />
			<div className="questions-wrapper">
				{kybBlockList &&
					kybBlockList?.map((el: any) => (
						<RenderAmlQuestion
							stepId={stepId}
							nodeId={nodeId}
							key={`${el.nodeId}_${el.blockId}`}
							blockId={el.blockId}
						/>
					))}
			</div>
			<AddBlockButton label="Add new condition block" onClick={addNewBlock} />
		</>
	);
};
