import { FC, useCallback, useMemo, useState } from 'react';
import { Image } from '@storybook';
import { IKybDetails, ILinkRepresentative } from 'global-stores/sessions/type';
import { AUTHORIZED_REPRESENTATIVE_LABEL, KYB_INFORMATION_DETAILS, PSB_OWNERSHIP_TYPE } from '../../hooks';
import { KybInfoHeader } from '../kyb-info-header';
import { showLastDigit } from 'views/user-identity-flow';
import { RejectionDetails } from '../rejection-details/rejection-details';

import './kyb-information.scss';
import { KycAmlMembersVerification } from '../kyc-aml-members-verification';
import { ViewMoreHOC } from '../view-more-hoc/view-more-hoc';
import { ExtraInformationSection } from '../enhanced';

interface IKYBInfo {
	item: IKybDetails;
	type: string;
	linkRepresentatives?: ILinkRepresentative[];
}

const KEY_MAP: Record<string, string> = {
	authorizedRepresentative: 'authorizedRepresentative',
	similarCompanies: 'similarCompanies',
	compliance: 'compliance',
};

export const KybInformationDetails: FC<IKYBInfo> = ({ item, type, linkRepresentatives }) => {
	const { basicInfo, authorizedRepresentative, compliance } = item ?? {};
	const { location, extraInfo, contactDetails, fullCompAddress, errorMessages } =
		basicInfo ?? {};

	const [showAllMembers, setShowAllMembers] = useState(false);

	const showHeader = useMemo(() => {
		if (type === 'contactDetails') {
			if (
				!contactDetails ||
				typeof contactDetails !== 'object' ||
				!contactDetails?.value ||
				typeof contactDetails?.value !== 'object'
			) {
				return false;
			}

			// Iterate over the child values and check if any array is not empty
			return Object.values(contactDetails?.value ?? {}).some(child => {
				// Ensure child and child.value exist and is an array, and check if it's not empty
				return child && Array.isArray(child?.value) && child?.value?.length > 0;
			});
		}
		if (KEY_MAP[type ?? ''] && item[type as keyof typeof item]) {
			return true;
		}
		if (basicInfo && basicInfo[type as keyof typeof basicInfo]) {
			return true;
		}
		return false;
	}, [basicInfo, type, item, contactDetails]);

	const formatDate = ({
		year,
		month,
		day,
	}: {
		day?: number;
		month?: number;
		year?: number;
	}) => {
		if (!year || !month || !day) {
			return 'N/A';
		}
		return `${month.toString().padStart(2, '0')}/${day
			.toString()
			.padStart(2, '0')}/${year}`;
	};

	const getInitials = useCallback((name: string) => {
		const words = name.split(' ');
		if (words.length === 0) return '';
		const firstInitial = words[0]?.charAt(0) || '';
		const lastInitial = words[words.length - 1]?.charAt(0) || '';
		return (firstInitial + lastInitial).toUpperCase();
	}, []);

	const renderValue = useCallback(
		(key: string, result: 'value' | 'status', idx = 0) => {
			if (!basicInfo) return null;
			const authorize = authorizedRepresentative?.value?.[idx]?.[key];
			switch (type) {
				case 'contactDetails':
					if (!contactDetails?.value?.[key as 'phone']?.value?.length) {
						return undefined;
					}
					return (
						contactDetails?.value?.[key as 'phone']?.value?.[0]?.[result] ||
						'N/A'
					);

				case 'location':
					switch (key) {
						case 'address':
							return  fullCompAddress?.value ?? 'N/A'
						case 'type':
							if ((basicInfo as any)?.[key] === undefined) {
								return undefined;
							}
							return location?.value?.[key as 'address']?.[result] || 'N/A';
						case 'registrationNumber':
							if (!basicInfo?.[key]) {
								return undefined;
							}
							return basicInfo?.[key]?.[result] || 'N/A';
						case 'registrationDate':
						case 'countryOfFormation':
						case 'dateOfFormation':
						case 'stateOfFormation':
						case 'fein':
							if (!basicInfo?.[key] || basicInfo?.[key]?.error) {
								return undefined;
							}
							return basicInfo?.[key]?.[result] || 'N/A'
						case 'tin':
							return basicInfo?.[key]?.[result] || basicInfo?.['registrationNumber']?.[result] ||'N/A';
						default:
							if (
								key === 'country' &&
								(location?.value as any)?.[key] === undefined
							) {
								return undefined;
							}
							return location?.value?.[key as 'address']?.[result] || 'N/A';
					}

				case 'extraInfo':
					if (!extraInfo?.value?.[key as 'taxNumber']) {
						return undefined;
					}
					return extraInfo?.value?.[key as 'taxNumber']?.[result] || 'N/A';
				case 'compliance':
					if (!item.compliance) {
						return undefined;
					}
					switch (key) {
						case 'SICCode':
							return (
								compliance?.[key]?.value ??
								compliance?.['BestSicCode']?.value ??
								null
							);
						case 'SICDescription':
						case 'NAICSCode':
						case 'NAICSDescription':
						case 'SOSFilingName':
						case 'TimeOnSOS':
						case 'SOSStatus':
						case 'LNStatus':
						case 'TimeOnPublicRecord':
						case 'County':
							// case 'BusinessFirstSeenYYYY':
							return compliance?.[key]?.value ?? null;
						default:
							return null;
					}

				case 'authorizedRepresentative':
					switch (key) {
						case 'relatesToBusiness': {
							return (authorize as any)?.[key]?.[result] ||
								(authorize as any)?.[result] !== 'no'
								? 'Yes'
								: 'No';
						}
						case 'dateOfBirth':
							if (result === 'value') {
								const dob = authorize?.value?.trim?.();
								if (typeof dob === 'string') {
									return dob || 'N/A';
								} else {
									return formatDate(dob ?? ({} as any));
								}
							} else {
								return authorize?.status || 'N/A';
							}

						case 'nationalIdNumber':
							if (result === 'value') {
								if (showLastDigit(authorize?.[result] || 'N/A') === 'NA') {
									return authorize?.[result] || 'N/A';
								}
								return showLastDigit(authorize?.[result] || 'N/A');
							} else {
								return authorize?.status || 'N/A';
							}

						case 'status': {
							return authorize?.[result] === 'active' ? 'Active' : 'Inactive';
						}
						case "type" :{
							return PSB_OWNERSHIP_TYPE[authorize?.[result]??"NA"]    
						}
						case "ownershipPercentage" :{
							return `${authorize?.[result]}%` || 'N/A';  
						}

						default:
							return authorize?.[result] || 'N/A';
					}

				default:
					return 'N/A';
			}
		},
		[
			item,
			fullCompAddress,
			basicInfo,
			authorizedRepresentative?.value,
			type,
			contactDetails?.value,
			extraInfo?.value,
			location?.value,
			compliance,
		]
	);

	const renderAuthorisedRepresentativeList = useCallback(
		(key: string, idx: number) => {
			if (key === 'designation') {
				return;
			}
			const getLinkRepresentatives = linkRepresentatives?.filter(
				rep => rep?.representativeId === renderValue('id', 'value', idx)
			);

			return (
				<div
					key={`authorizedRepresentative-${key}-${idx}`}
					className="kyb-wrapper__details__kybInfoDetailsRow"
				>
					{key === 'name' ? (
						<div className="kyc-aml-members__content">
							<span className="kyc-aml-members__content__member-icon">
								{getInitials(String(renderValue(key, 'value', idx) || 'N/A'))}
							</span>
							<div className="kyc-aml-members__content__member-info">
								<span className="kyc-aml-members__content__member-name">
									{renderValue('name', 'value', idx) || 'N/A'}
								</span>
								<span className="kyc-aml-members__content__member-designation">
									{renderValue('designation', 'value', idx)}
								</span>
							</div>
						</div>
					) : key === 'id' ? (
						// rendering kyc-aml verification status field
						<KycAmlMembersVerification item={getLinkRepresentatives} />
					) : (
						<>
							<div className="kyb-wrapper__details__kybInfoDetailsRow__label">
								{AUTHORIZED_REPRESENTATIVE_LABEL[key] || key !== 'id'}
							</div>
							<div
								key={key}
								className="kyb-wrapper__details__kybInfoDetailsRow__value"
							>
								<span>:</span>
								<div>{renderValue(key, 'value', idx)}</div>
								{renderValue(key, 'value', idx) !== 'N/A' &&
									(renderValue(key, 'status', idx) === 'verified' ? (
										<Image fileName="verified.svg" />
									) : (
										renderValue(key, 'status', idx) === 'not-verified' && (
											<Image fileName="Verified_1.svg" />
										)
									))}
							</div>
						</>
					)}
				</div>
			);
		},
		[getInitials, linkRepresentatives, renderValue]
	);

	const handleShowMoreButton = useCallback(() => {
		setShowAllMembers(!showAllMembers);
	}, [showAllMembers]);

	const noRecordFound = useMemo(() => {
		return (
			<div className="kyb-array-empty-wrapper">
				<div className="kyb-empty_data">
					<div className="kyb-empty_data__inner">
						<Image
							fileName="No_record_found.svg"
							className="empty_data_img"
							height="100"
						/>
						<div className="kyb-empty-data-txt">No records found</div>
					</div>
				</div>
			</div>
		);
	}, []);

	return showHeader ? (
		<div className="kyb-information-container">
			<div className="kyb-header-wrapper">
				<KybInfoHeader type={type} show={showHeader} />
				{type === 'authorizedRepresentative' &&
					authorizedRepresentative?.value?.length > 2 && (
						<div
							onClick={handleShowMoreButton}
							className="kyb-members_view-more"
						>
							{showAllMembers ? 'View less' : 'View more'}
						</div>
					)}
			</div>
			<div className="kyb-wrapper__details__kybInfoDetails">
				{type === 'compliance' && compliance?.error ? (
					noRecordFound
				) : (
					<>
						{type === 'authorizedRepresentative' ? (
							authorizedRepresentative?.value?.length ? (
								authorizedRepresentative?.value
									?.slice(
										0,
										showAllMembers ? authorizedRepresentative?.value?.length : 2
									)
									?.map((representative: any, idx: number) => {
										return (
											<div
												className="kyc-aml-members__wrapper"
												key={representative.id}
											>
												{Object.keys(representative)
													.filter(key => key !== 'countryCode' && key !== 'id')
													.map(key =>
														renderAuthorisedRepresentativeList(key, idx)
													)}

												{renderAuthorisedRepresentativeList('id', idx)}
											</div>
										);
									})
							) : (
								noRecordFound
							)
						) : (
							<>
								{KYB_INFORMATION_DETAILS[type]?.map(({ key, label }) => {
									if (label === 'Rejection Reason' && !errorMessages?.length) {
										return <></>;
									}
									return (
										renderValue(key as string, 'value') && (
											<div
												key={key}
												className="kyb-wrapper__details__kybInfoDetailsRow"
											>
												<div className="kyb-wrapper__details__kybInfoDetailsRow__label">
													{label}
												</div>
												<div className="kyb-wrapper__details__kybInfoDetailsRow__value">
													<span>:</span>
													{key === 'errorMessages' ? (
														<RejectionDetails
															data={errorMessages ?? []}
															isError={true}
														/>
													) : (
														<div>{renderValue(key as string, 'value')}</div>
													)}
													{renderValue(key as string, 'value') !== 'N/A' &&
														(renderValue(key as string, 'status') ===
														'verified' ? (
															<Image fileName="verified.svg" />
														) : (
															renderValue(key as string, 'status') ===
																'not-verified' && (
																<Image fileName="Verified_1.svg" />
															)
														))}
												</div>
											</div>
										)
									);
								})}
								{type === 'location' && (
									<ViewMoreHOC
										Component={ExtraInformationSection}
										componentProp={[
											...Object.entries(item?.basicInfo?.metadata ?? {}),
										]}
										isdefaultShowAllinfo={true}
										heading="Other Informations"
									/>
								)}
							</>
						)}
					</>
				)}
			</div>
		</div>
	) : (
		<></>
	);
};
