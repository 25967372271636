import { FC } from 'react';
import { ILinkedEntities } from 'global-stores/sessions/type';
import { AmlEntityCard } from '../entity-card';
import './aml-linked-entites.scss';
import { KYB_ICONS } from 'views';

interface IProps {
	items: ILinkedEntities[];
}
export const AmlLinkedEntities: FC<IProps> = ({ items }) => {
	return (
		<div>
			{(items ?? []).map(item => (
				<AmlEntityCard
					key={`entity-card${item?.entityName?.[0] ?? "--" + item?.name?.[0]}`}
					heading={item?.entityName?.[0] ?? item?.name?.[0] ?? ''}
					subHeading={item?.entityName ? 'Company' : 'Individual'}
					metadata={item}
                    cardType={item?.entityName ? KYB_ICONS['industry'] : KYB_ICONS['individual']}
				/>
			))}
		</div>
	);
};
