import { atom } from 'recoil';
import { IReviewer } from '../../assign-dropdown/ReviewerDropdown';

export const AssignSelectedSessionState = atom<any>({
	key: 'assign-selected-session-state',
	default: [],
});

export const dropDownSelectedReviewerState = atom<IReviewer[]>({
	key: 'drop-down-selected-reviewer-state',
	default: []
});

export const recallComplexSessionDataState = atom<boolean>({
	key: 'recall-complex-session-data-state',
	default: false
});