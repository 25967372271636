import { Loader } from '@storybook';
import Modal from '@storybook/new-modal/modal';
import { API_URL, environmentUrl, sandboxEnvHost } from 'constant';
import { loginState } from 'global-stores';
import { envHost } from 'helpers';
import { useNetwork, useNotification } from 'hooks';
import { useCallback, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { SimpliciSignIframe } from 'shared-components';
import { getDateWithTime } from 'utils';
import { Signatory } from '../constants';

interface SwipeSliderProps {
	slides: any;
}

export const DocSlider = ({ slides }: SwipeSliderProps) => {
	// local states
	const [isLoading, setIsLoading] = useState(false);
	const [modalVisibility, setModalVisibility] = useState(false);

	const { sandboxStatus } = useRecoilValue(loginState);

	// hooks
	const { successNotification, errorNotification } = useNotification();
	const { get } = useNetwork();

	const renderKey = useCallback((key: string) => {
		switch (key) {
			case 'Choose Identity Type':
				return 'Identity type';
			case 'Individual Accreditation':
				return 'Individual accre.';
			case 'Net Worth Validation':
				return 'Worth validation';
			case 'Income Validation':
				return 'Income Validation';
			case 'createdAt':
				return 'Created Date';
			case 'updatedAt':
				return 'Updated Date';
			case '_id':
				return 'Id';
			case 'kybStatus':
				return ' KYB Status';
			case 'envelopeStatus':
				return 'Packet status';
			case 'csvUrl':
				return ' CSV Url';
			case 'signatoryData':
				return 'Email of attorney, CPA, broker-dealer, or investment advisor';	
			default:
				return key;
		}
	}, []);

	// handler of send signing for packet status
	const handleSendSigning = useCallback(
		async (envelopeId: string | undefined) => {
			setIsLoading(true);
			const url = `${API_URL.RESEND_DOC_INVITES}/${envelopeId}`;
			const resp = await get(url);
			if (resp) {
				setIsLoading(false);
				successNotification('Document send successfully');
			} else {
				setIsLoading(false);
				errorNotification('something went wrong');
			}
		},
		[errorNotification, get, successNotification]
	);

	const renderViewModalBody = useCallback(
		(slideData: { [key: string]: string }) => {
			const host = !sandboxStatus
				? environmentUrl[envHost]
				: environmentUrl[sandboxEnvHost[envHost]];
			return (
				<SimpliciSignIframe
					signUrl={`${host}view-doc?envelopeId=${slideData?.['envelopeId']}&view=true`}
					className="viewModal__iframe"
					handleSubmitModal={() => ({})}
					id="view-modal-Iframe"
				/>
			);
		},
		[sandboxStatus]
	);

	const onModalClose = useCallback(() => {
		setModalVisibility(false);
	}, []);
	const onModalOpen = useCallback(() => {
		setModalVisibility(true);
	}, []);

	{
		/* render packet status key only when envelopeStatus get underReview */
	}
	const renderPacketStatus = useCallback(
		(slideData: { [key: string]: string }) => {
			return (
				slideData?.['envelopeStatus'] === 'underReview' && (
					<div className="kycDetail-row">
						<div className="report-label">Packet status</div>
						<div>:</div>
						<div className="packet-status">
							<span className="packet-status__value">Incomplete</span>
							<span
								className="packet-status__button"
								onClick={() => handleSendSigning(slideData?.['envelopeId'])}
							>
								Send document for signing
							</span>
							<div>{isLoading && <Loader type="loader" dimension={20} />}</div>
						</div>
					</div>
				)
			);
		},
		[handleSendSigning, isLoading]
	);

	// Determines if a document should be sent for signing based on the answer.
	const questionAnswers = (answer: string) => {
		if (Signatory[answer]) {
			return `Accreditation letter sent to ${answer} for signing`;
		}
		return answer ?? "";
	};

	// Returns 'Accreditation Letter' if any questionnaire answer matches a signatory; otherwise, returns 'Uploaded Documents'.
	const uploadedDocKey = (data: {
		questionnaire: { answers: string }[];
	}): string => {
		const isSignatoryIncluded = data?.questionnaire?.some(
			question => Signatory[question.answers] !== undefined
		) ?? false;
		return isSignatoryIncluded ? 'Accreditation Letter' : 'Uploaded Documents';
	};

	const downloadCsv = async (url: string, name: string) => {
		// Get the CSV data from the URL
		// Create a link element
		const csvName = name ? name + ',csv' : 'data.csv';
		const link = document.createElement('a');
		link.href = url;
		link.download = csvName;
		// Trigger the download
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
	};

	return (
		<>
			{(slides || []).map((slide: any) => {
				const slideData = slide.data ?? slide;
				return (
					<div className="kycDetail-swipe" key={`kycDetail__${slide.data}`}>
						{slideData['questionnaire']?.length > 0 ? (
							<>
								<div className="questionnaire-status">
									{slideData['questionnaire'].map((question: any) => {
										return (
											<div
												className="questionnaire-list"
												key={`${question?.answers}`}
											>
												<div className="questionnaire-dot-line">
													<div className="questionnaire-dot"></div>
													<div className="questionnaire-line"></div>
												</div>
												<div className="questionnaire-answer">
													{questionAnswers(question?.answers)}
												</div>
											</div>
										);
									})}

									{slideData['url'] && (
										<div className="questionnaire-list">
											<div className="questionnaire-dot-line">
												<div className="questionnaire-dot"></div>
												<div className="questionnaire-line"></div>
											</div>
											<div className="questionnaire-answer">
												{uploadedDocKey(slideData)}
												<a
													href={slideData['url']}
													target="_blank"
													rel="noreferrer"
												>
													View
													<svg
														width="16"
														height="12"
														viewBox="0 0 16 12"
														fill="none"
														xmlns="http://www.w3.org/2000/svg"
													>
														<path
															d="M0.787109 6C1.41378 2.58667 4.40511 0 7.99978 0C11.5944 0 14.5851 2.58667 15.2124 6C14.5858 9.41333 11.5944 12 7.99978 12C4.40511 12 1.41444 9.41333 0.787109 6ZM7.99978 9.33333C8.88383 9.33333 9.73168 8.98214 10.3568 8.35702C10.9819 7.7319 11.3331 6.88406 11.3331 6C11.3331 5.11595 10.9819 4.2681 10.3568 3.64298C9.73168 3.01786 8.88383 2.66667 7.99978 2.66667C7.11572 2.66667 6.26788 3.01786 5.64275 3.64298C5.01763 4.2681 4.66644 5.11595 4.66644 6C4.66644 6.88406 5.01763 7.7319 5.64275 8.35702C6.26788 8.98214 7.11572 9.33333 7.99978 9.33333ZM7.99978 8C7.46934 8 6.96064 7.78929 6.58556 7.41421C6.21049 7.03914 5.99978 6.53043 5.99978 6C5.99978 5.46957 6.21049 4.96086 6.58556 4.58579C6.96064 4.21071 7.46934 4 7.99978 4C8.53021 4 9.03892 4.21071 9.41399 4.58579C9.78906 4.96086 9.99978 5.46957 9.99978 6C9.99978 6.53043 9.78906 7.03914 9.41399 7.41421C9.03892 7.78929 8.53021 8 7.99978 8Z"
															fill="#3C65D6"
														/>
													</svg>
												</a>
											</div>
										</div>
									)}
								</div>
							</>
						) : (
							''
						)}
						{Object.keys(slideData).map(key => {
							if (
								key !== 'questionnaire' &&
								key !== 'type' &&
								!(key === 'url' && slideData['type'] === '506c') &&
								key !== 'envelopeStatus'
							) {
								return (
									<div
										className="kycDetail-row"
										key={`kycDetail-swipe${key}-${key}`}
									>
										<div className="report-label">{renderKey(key)}</div>
										<div>:</div>

										{key === 'signatoryData' ? (
											<p className="kycDetail-swipe-value">
												{slideData[key]?.email ?? '--'}
											</p>
										):key === 'url' ? (
											<div className="kycDetail-swipe-url">
												{slideData[key] ? (
													slideData?.['envelopeStatus'] === 'underReview' ? (
														<a rel="noreferrer" onClick={onModalOpen}>
															Click to View document
														</a>
													) : (
														<a
															href={slideData[key]}
															target="_blank"
															rel="noreferrer"
														>
															Click to Download document
														</a>
													)
												) : (
													<p className="kycDetail-swipe-value">--</p>
												)}
											</div>
										) : key === 'csvUrl' ? (
											slideData[key] ? (
												<div
													className="download-signagreement-csv"
													onClick={() =>
														downloadCsv(slideData[key], slideData._id)
													}
												>
													Click to Download Sign agreement CSV
												</div>
											) : (
												<p className="kycDetail-swipe-value">--</p>
											)
										) : (
											<p className="kycDetail-swipe-value">
												{key !== 'questionnaire' &&
													key !== 'envelopeStatus' &&
													(key === 'createdAt' || key === 'updatedAt'
														? getDateWithTime(slideData[key])
														: (slideData?.[key]?.label ?? slideData?.[key]) ||
														  'NA')}
											</p>
										)}
									</div>
								);
							}
							return <></>;
						})}
						{/* render packet status key only when envelopeStatus get underReview */}
						{renderPacketStatus(slideData)}
						{modalVisibility && (
							<Modal
								isOpen={modalVisibility}
								modalName=""
								className="sign-doc-sign-modal"
								closeModal={onModalClose}
							>
								{renderViewModalBody(slideData)}
							</Modal>
						)}
					</div>
				);
			})}
		</>
	);
};
