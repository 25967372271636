import { FC, Fragment } from 'react';
import { camelCaseToCapitalizedWords } from 'utils';
import { v4 } from 'uuid';
import { BasicSection } from 'views';

interface IProps {
	items: any[];
	isDataModified: boolean;
	isLineBetween?: boolean;
}

export const AmlWatcherBasicSection: FC<IProps> = ({
	items,
	isDataModified,
	isLineBetween,
}) => {
	return (
		<>
			{!isDataModified &&
				(items ?? []).map(item => (
					<div key={`aml-basic-${item?.id}-${item?.key}`}>
						<Fragment></Fragment>
						{(item?.value !== '' || item?.value?.length !== 0) && (
							<BasicSection
								key={item?.key}
								label={camelCaseToCapitalizedWords(item?.key)}
								value={item?.value ?? 'N/A'}
								isFromAML={true}
							/>
						)}
					</div>
				))}
			{isDataModified && (
				<>
					{(items ?? []).map((item, index) => (
						<Fragment key={v4()}>
							{Object.keys(item).map(key => (
								<Fragment key={`basic-section--${key}`}>
									{item[key] !== '' && item?.[key]?.length !== 0 && (
										<BasicSection
											label={camelCaseToCapitalizedWords(key)}
											value={item?.[key] ?? 'N/A'}
											isFromAML={true}
										/>
									)}
								</Fragment>
							))}
							{index + 1 !== items?.length && isLineBetween && (
								<div className="separator-line"></div>
							)}
						</Fragment>
					))}
				</>
			)}
		</>
	);
};
