import { Transaction } from 'global-stores/sessions/type';

import React from 'react';

import { formatUnderScoreRemove } from 'utils';
import { otherInfoDetails } from '../constants';

// Props interface for FundInvestmentHeader
interface FundInvestmentHeaderProps {
	fundInvestmentAmount: string; // Total investment amount
	currencyCode: string; // Currency code for the investment
	statusClass: string; // CSS class for the status icon
	fundAccount: Transaction; // Fund account details
	renderInfoValue: (fundAccount: Transaction, key: string) => string; // Function to render info values
}

/**
 * Component to render the header for fund investment details, including amount, status, and other information.
 */
export const FundInvestmentHeader: React.FC<FundInvestmentHeaderProps> = ({
	fundInvestmentAmount,
	currencyCode,
	statusClass,
	fundAccount,
	renderInfoValue,
}) => (
	<div className="FundInvestmentReportDetails__account-header">
		<div className="FundInvestmentReportDetails__amount-label">Amount</div>
		<div className="FundInvestmentReportDetails__amount-value">
			{fundInvestmentAmount}{' '}
			<span className="FundInvestmentReportDetails__currencyCode">
				{currencyCode || '--'}
			</span>
		</div>
		<div className="FundInvestmentReportDetails__status">
			<i className={statusClass} />
			<div className="FundInvestmentReportDetails__status-label">
				{formatUnderScoreRemove(fundAccount?.status ?? '')}
			</div>
		</div>
		<div className="FundInvestmentReportDetails__other-info">
			{otherInfoDetails.map(({ key, label }) => (
				<>
					<div>
						<span className="FundInvestmentReportDetails__other-label">
							{label}
						</span>
						:
						<span className="FundInvestmentReportDetails__other-value">
							{renderInfoValue(fundAccount, key)}
						</span>
					</div>
				</>
			))}
		</div>
	</div>
);
