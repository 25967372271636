import React, { FC, useMemo } from 'react';
import { v4 } from 'uuid';
import { KycTreeSubHeadingChildComponent } from './kyc-tree-sub-heading-childs';

type SectionDataValue = string[] | Array<Record<string, string>> | null;
interface SectionDataItem {
	label?: string;
	isFound?: boolean;
	value?: SectionDataValue;
	visible?: boolean;
}

type SectionData = SectionDataItem[];
interface IProps {
	sectionHeading: string;
	sectionData: SectionData;
}

export const KycTreeComponent: FC<IProps> = ({
	sectionHeading,
	sectionData,
}) => {
	const renderSectionData = useMemo(() => {
		return (
			<div className="kyc-comprohensive-chart__container">
				<div className="kyc-comprohensive-chart__container-heading">
					<span className="kyc-comprohensive-chart__container-heading-icon root-node"></span>
					<p className="kyc-comprohensive-chart__container-heading-title">
						{sectionHeading ?? 'N/A'}
					</p>
				</div>
				<div className="kyc-comprohensive-chart__container--sub-heading">
					{(sectionData ?? [])
						.filter(item => item?.visible !== false)
						.map(item => (
							<React.Fragment key={v4()}>
								<div className="kyc-comprohensive-chart__container--sub-heading-item">
									<span
										className={`kyc-comprohensive-chart__container--sub-heading-item-status-icon ${
											!item?.isFound ? 'check-icon' : 'subtract-icon'
										}`}
									>
										{!item?.isFound ? (
											<i className="ri-check-line"></i>
										) : (
											<i className="ri-subtract-line"></i>
										)}
									</span>
									<p className="kyc-comprohensive-chart__container--sub-heading-item--title">
										{item?.label ?? 'N/A'}
									</p>
								</div>
								<KycTreeSubHeadingChildComponent
									childData={item?.value ?? []}
								/>
							</React.Fragment>
						))}
				</div>
			</div>
		);
	}, [sectionData, sectionHeading]);
	return renderSectionData;
};
