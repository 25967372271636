import env from '@beam-australia/react-env';

export const REACT_APP_API_HOST = env('API_HOST');
// export const REACT_APP_API_HOST = 'https://api.stage.simplici.io/v1'

export const REACT_APP_HOST_URL = env('HOST_URL');
export const REACT_APP_STRIPE_SECRET = env('STRIPE_SECRET');
export const REACT_APP_GOOGLE_MAP_SECRET_TOKEN = env('GOOGLE_MAP_SECRET_TOKEN');
export const REACT_APP_CAPTABLE_URL = env('CAPTABLE_URL');
export const REACT_APP_DATADOG_CLIENT_TOKEN = env('DATADOG_CLIENT_TOKEN');
export const REACT_APP_DATADOG_ENV = env('DATADOG_ENV');
export const REACT_APP_DATADOG_SITE = env('DATADOG_SITE');
export const REACT_APP_DATADOG_SERVICE = env('DATADOG_SERVICE');
export const REACT_APP_DATADOG_VERSION = env('DATADOG_VERSION');
export const REACT_APP_DATADOG_FORWARD_ERROR_LOG = env(
	'DATADOG_FORWARD_ERROR_LOG'
);
export const REACT_APP_DATADOG_SESSION_SIMPLE_RATE = env(
	'DATADOG_SESSION_SIMPLE_RATE'
);
export const REACT_APP_DATADOG_FORWARD_CONSOLE_LOGS = env(
	'DATADOG_FORWARD_CONSOLE_LOGS'
);
export const REACT_APP_CDN_URL = env('CDN_URL');
export const REACT_APP_KYC_ID = env('KYC_ID');
export const REACT_APP_KYC_KYB_ID = env('KYC_KYB_ID');
export const REACT_APP_ARQ_SECURITIES_BUSINESSID = env('ARQ_SECURITIES_BUSINESSID');
export const REACT_APP_LIQUIDITY_BUSINESSID = env('LIQUIDITY_BUSINESSID');
export const REACT_APP_SESSION_WEB_COMPONENT = env('SESSION_WEB_COMPONENT');
export const REACT_APP_KYC_FRONTEND_BUILD = env('KYC_FRONTEND_BUILD');
export const REACT_APP_KYC_FRONTEND_DOMAIN = env('KYC_FRONTEND_DOMAIN');
// Firebase Env
export const REACT_APP_FIREBASE_APIKEY = env('FIREBASE_APIKEY');
export const REACT_APP_FIREBASE_AUTHDOMAIN = env('FIREBASE_AUTHDOMAIN');
export const REACT_APP_FIREBASE_DATABASEURL = env('FIREBASE_DATABASEURL');
export const REACT_APP_FIREBASE_PROJECTID = env('FIREBASE_PROJECTID');
export const REACT_APP_FIREBASE_STORAGEBUCKET = env('FIREBASE_STORAGEBUCKET');
export const REACT_APP_FIREBASE_MESSAGINGSENDERID = env(
	'FIREBASE_MESSAGINGSENDERID'
);
export const REACT_APP_FIREBASE_AAPID = env('FIREBASE_AAPID');
export const REACT_APP_ESIGN_WEBCOMPONENT_URL = env('ESIGN_WEBCOMPONENT_URL');
export const REACT_APP_AUTH_API_URL = env('AUTH_API_URL');
export const REACT_APP_AUTH_SCRIPT = env('AUTH_SCRIPT');
