import React from 'react';
import './assigndropdown.scss';
import ReviewerDropdown, { IReviewer } from './ReviewerDropdown';

interface AssignToReviewerProps {
	isFiltter: boolean;
	isDropdownOpen: boolean;
	toggleDropdown: () => void;
	searchTerm: string;
	setSearchTerm: React.Dispatch<React.SetStateAction<string>>;
	selectedReviewerNames: string;
	reviewers: IReviewer[];
	handleReviewerSelect: (item: IReviewer) => void;
	isSearch: boolean;
}

const AssignToReviewer: React.FC<AssignToReviewerProps> = ({
	isFiltter,
	isDropdownOpen,
	toggleDropdown,
	searchTerm,
	setSearchTerm,
	selectedReviewerNames,
	reviewers,
	handleReviewerSelect,
	isSearch,
}) => {
	return (
		<div className="reviewer-container">
			<div className="assign-box-model" onClick={toggleDropdown}>
				{!isFiltter ? (
					<p className="title">
						{selectedReviewerNames || 'Select Reviewer'}
					</p>
				) : (
					<p className="title">Assign Reviewer</p>
				)}
				<i className={`ri-arrow-down-s-line arrow`}></i>
			</div>

			{isDropdownOpen && (
				<ReviewerDropdown
					reviewers={reviewers}
					searchTerm={searchTerm}
					setSearchTerm={setSearchTerm}
					handleReviewerSelect={handleReviewerSelect}
					isFiltter={isFiltter}
					isSearch={isSearch}
				/>
			)}
		</div>
	);
};

export default AssignToReviewer;
