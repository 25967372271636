import { FC, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';
import Modal from '@storybook/new-modal/modal';
import './assign-reviewer-modal.scss';
import { Button, Loader } from '@storybook';
import AssignToReviewer from 'views/sessions/components/assign-dropdown/AssignDropdown';
import { Assignee, IReviewer } from '../assign-dropdown/ReviewerDropdown';
import { useSessionAssign } from 'views/sessions/hooks/use-session-assign';
import { AssigneeSessionState } from 'views/sessions/store';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import {
	AssignSelectedSessionState,
	dropDownSelectedReviewerState,
	recallComplexSessionDataState,
} from '../select-fund-modal';
import { toast } from 'react-toastify';
import { SessionItemSelected, userDataState } from 'global-stores';
import { useComplexSessionInfo } from 'views/sessions/hooks';

interface AssignReviewerModalProps {
	isOpen: boolean;
	handleClose: () => void;
	isCurrentUser: boolean;
}
type Session = {
	_id: string;
	name: string;
};
export const AssignReviewerModal: FC<AssignReviewerModalProps> = ({
	isOpen,
	handleClose,
	isCurrentUser,
}) => {
	const { AssignReviewer, SessionAssignToReviewer } = useSessionAssign();
	const sessionAssignee = useRecoilValue(AssigneeSessionState);
	const selectedSession: Session[] = useRecoilValue(AssignSelectedSessionState);
	const setDropDownSelectedReviewer = useSetRecoilState(
		dropDownSelectedReviewerState
	);
	const setRecallComplexSessionData = useSetRecoilState(recallComplexSessionDataState);
	const [isDropdownOpen, setIsDropdownOpen] = useState(false);
	const [searchTerm, setSearchTerm] = useState('');
	const [comments, setComments] = useState('');
	const [selectedReviewers, setSelectedReviewers] = useState<IReviewer[]>([]);
	const [loading, setLoading] = useState(false);
	const loginStateData = useRecoilValue(userDataState);
	const selectedSessionItem = useRecoilValue(SessionItemSelected);
	const { complexSessionInfo } = useComplexSessionInfo();

	useEffect(() => {
		AssignReviewer();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const filteredData = useMemo(
		() =>
			isCurrentUser
				? sessionAssignee
				: sessionAssignee?.filter(
						(user: { _id: any }) => user._id !== loginStateData.id
				  ),
		[isCurrentUser, sessionAssignee, loginStateData.id]
	);

	const reviewers = useMemo(
		() => [
			{
				id: null,
				name: 'Unassign',
				image: '',
			},
			...filteredData.map((assignee: Assignee) => ({
				id: `${assignee?._id}`,
				name: assignee?._id === loginStateData.id
					? 'Self assigned'
					: `${assignee.firstName} ${assignee.lastName}`,
				image: `${assignee.image}`,
			})),
		],
		[filteredData, loginStateData.id]
	);

	const toggleDropdown = useCallback(() => {
		setIsDropdownOpen(prev => !prev);
	}, []);

	const handleReviewerSelect = useCallback((item: IReviewer) => {
		setSelectedReviewers(prev =>
			prev.some(r => r.id === item.id) ? [] : [item]
		);
		setIsDropdownOpen(false);
	}, []);

	const handleInputChange = (e: {
		target: { value: SetStateAction<string> };
	}) => {
		setComments(e.target.value);
	};

	const selectedReviewerNames = useMemo(
		() => selectedReviewers.map(reviewer => reviewer.name).join(', '),
		[selectedReviewers]
	);

	const selectedReviewerId = useMemo(() => {
		const ids = selectedReviewers.map(reviewer => reviewer.id).filter(id => id != null);
		return selectedReviewers.length === 0 ? '' : (ids.length > 0 ? ids.join(', ') : null);
	}, [selectedReviewers]);

	const handleSessionAssineeSubmit = useCallback(async () => {
		const getSelectedSessionIds = selectedSession?.map((session)=> session?._id)
		const {_id} = selectedSessionItem;
		setLoading(true);
		const result = await SessionAssignToReviewer(
			selectedReviewerId as string,
			getSelectedSessionIds,
			comments
		);

		if (result === true) {
			toast.success('Session Assigned successfully');
			setDropDownSelectedReviewer(selectedReviewers);
			complexSessionInfo(_id ?? '');
			setRecallComplexSessionData(true);
			handleClose();
		}

		setLoading(false);
	}, [SessionAssignToReviewer, comments, complexSessionInfo, handleClose, selectedReviewerId, selectedReviewers, selectedSession, selectedSessionItem, setDropDownSelectedReviewer, setRecallComplexSessionData]);

	const isDisableAssignModalConfirmBtn = useMemo(() => {
		const hasValidReviewer = selectedReviewerId !== '';
		const hasComments = comments.length > 0;
		return hasValidReviewer && !loading && hasComments;
	  }, [selectedReviewerId, comments.length, loading]);

	const renderMainComponent = useMemo(() => {
		return (
			<>
				<div className="assign-reviewer-modal__body_wrapper">
						{selectedSession.length === 1 && (
							<div className="assign-reviewer-modal__body_wrapper__label">
								<p className="reviewer-name">
									{selectedSession[0]?.name || '--'}
								</p>
								<p className="reviewer-id">{selectedSession[0]?._id}</p>
							</div>
						)}
					<div className="assign-reviewer-modal__body_wrapper__reviewer-section">
						<label
							htmlFor="assign"
							className="assign-reviewer-modal__body_wrapper__reviewer-section__reviewer-label"
						>
							Assign Reviewer
						</label>
						<AssignToReviewer
							isFiltter={false}
							isSearch={true}
							isDropdownOpen={isDropdownOpen}
							toggleDropdown={toggleDropdown}
							searchTerm={searchTerm}
							setSearchTerm={setSearchTerm}
							selectedReviewerNames={selectedReviewerNames}
							reviewers={reviewers}
							handleReviewerSelect={handleReviewerSelect}
						/>
					</div>

					<div className="assign-reviewer-modal__body_wrapper__comments-section">
						<label
							htmlFor="comments"
							className="assign-reviewer-modal__body_wrapper__comments-section__comments-label"
						>
							Comments
						</label>
						<textarea
							id="comments"
							placeholder="Add comments"
							className="assign-reviewer-modal__body_wrapper__comments-section__comments-textarea"
							onChange={handleInputChange}
							maxLength={300}
						></textarea>
					</div>
				</div>
				<div className="assign-reviewer-modal__footer_wrapper">
					<Button
						handleClick={handleClose}
						label="Cancel"
						type="button__ghost button__filled--secondary"
						buttonType="button"
					/>

					<Button
						type="button__filled button__filled--primary"
						buttonType="button"
						label={
							loading ? (
								<Loader type="loader" className="loader-white" dimension={20} />
							) : (
								'Submit'
							)
						}
						handleClick={handleSessionAssineeSubmit}
						disabled={!isDisableAssignModalConfirmBtn}
					/>
				</div>
			</>
		);
	}, [handleClose, handleReviewerSelect, handleSessionAssineeSubmit, isDisableAssignModalConfirmBtn, isDropdownOpen, loading, reviewers, searchTerm, selectedReviewerNames, selectedSession, toggleDropdown]);
	return (
		<Modal
			isOpen={isOpen}
			closeModal={handleClose}
			className="assign-reviewer-modal"
			modalName="Assign Reviewer Modal"
			title={
				<div className="assign-reviewer-modal__header_wrapper">
					<div className="assign-reviewer-modal__header_wrapper__title">
						Assign Reviewer
					</div>
				</div>
			}
		>
			{renderMainComponent}
		</Modal>
	);
};
