import { atom } from "recoil";

import { IStatusUploadedDocs } from "./types";
import { Assignee } from "../components/assign-dropdown/ReviewerDropdown";

export const StatusUploadedDocsState = atom<IStatusUploadedDocs>({
	key: 'status-uploaded-docs-state-key',
	default: {
		isLoaded: true,
		error: false,
		data: [],
	},
});

export const AssigneeSessionState = atom<Assignee[]>({
	key: 'assignee-users',
	default: [],
});

export const IsAssigneeSessionLoadingState = atom<boolean>({
	key: 'is-assignee-session-loading-state',
	default: true,
});