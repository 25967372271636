import {
	ChangeEvent,
	FC,
	useCallback,
	useEffect,
	useMemo,
	useState,
} from 'react';
import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil';

import { Button, fetchCountryCodeFromPhone, Input, Loader } from '@storybook';
import { AsYouType, CountryCode as CountryType, parsePhoneNumberFromString } from 'libphonenumber-js';
import countries from 'json/country-codes.json';
import { CountryCode } from 'components';
import Modal from '@storybook/new-modal/modal';
import { API_URL, message } from 'constant';
import { useNetwork, useNotification } from 'hooks';

import {
	CreateSubAccountState,
	ICreateSubAccountState,
	ISubAccountListState,
	SUBACCOUNTDETAILS,
	SubAccountListState,
} from '../../hooks';
import { scrollToIndex } from 'utils/scroll';
import { SubAccountNameIds } from 'views/sub-account/constants';
import { SubscriptionPriceState } from 'global-stores';
import {
	capitalizeFirstLetter,
	isEmailValid,
	isValidRecipientName,
} from 'utils';

interface ICreateModal {
	isOpen: boolean;
	handleCloseModal: () => void;
	isEditable: boolean;
	subAccDetail: ISubAccountListState | null;
}

interface IErrorMessageState {
	firstName: string;
	lastName: string;
	email: string;
	phone: string;
	name: string;
}
const specialCharRegex = /^[a-zA-Z0-9 ]*$/;

export const CreateSubAccountModal: FC<ICreateModal> = ({
	isOpen,
	handleCloseModal,
	isEditable,
	subAccDetail,
}) => {
	const [createSubAccount, setCreateSubAccount] = useRecoilState(
		CreateSubAccountState
	);
	const [errorMessage, setErrorMessage] = useState<IErrorMessageState>({
		firstName: '',
		lastName: '',
		email: '',
		phone: '',
		name: '',
	});

	const [subAccountRows, setSubAccountRows] =
		useRecoilState(SubAccountListState);
	const subscriptionPrice = useRecoilValue(SubscriptionPriceState);
	const [isErrorAccountName, setIsErrorAccountName] = useState(false);

	const resetCreateAccount = useResetRecoilState(CreateSubAccountState);
	const [temporaryPhoneNumber, setTemporaryPhoneNumber] = useState('');
	const [countryCode, setCountry] = useRecoilState(CountryCode);
	const resetCountryCode = useResetRecoilState(CountryCode);
	const [invalidPhoneMessage, setInvalidPhoneMessage] = useState('');

	const {
		post: postCreateSubAccount,
		patch: patchEditSubAccount,
		loading,
	} = useNetwork();

	const { successNotification, errorNotification } = useNotification();

	const price = useMemo(() => {
		if (subscriptionPrice) {
			return subscriptionPrice[0]?.['entity'];
		} else return 0;
	}, [subscriptionPrice]);

	const showSubscriptionServices = useMemo(() => {
		return (
			createSubAccount['ownBilling'] || createSubAccount['differentCompany']
		);
	}, [createSubAccount]);

	useEffect(() => {
		if (isEditable && subAccDetail) {
			const { ownBilling, name, differentCompany } = subAccDetail ?? {};
			setCreateSubAccount({
				name: name?.trim(),
				ownBilling,
				differentCompany,
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleClose = () => {
		handleCloseModal();
		setTimeout(() => {
			setIsErrorAccountName(false);
			resetCreateAccount();
			resetCountryCode()
		}, 1000);
	};

	const handleCreate = useCallback(() => {
		const { name, primaryUserDetails, differentCompany, ownBilling } =
			createSubAccount;
		const enties = Object.entries(primaryUserDetails ?? {});
		let primaryValue = false;
		let invalidUserNamesFormat = false;
		let countryLabel: CountryType = 'US';
		// Fetch the country object based on the selected country code
		const countryObj = countries.find(item => item.label === countryCode);
		if (countryObj) countryLabel = countryObj.code as CountryType;
		const parsedPhoneNumber = parsePhoneNumberFromString(temporaryPhoneNumber, countryLabel);
		const isPhoneNumberValid = parsedPhoneNumber ? parsedPhoneNumber.isValid() : false;
		if (!isPhoneNumberValid) {
			// Set error message for invalid phone number
			if (countryObj?.value && countryObj?.value.length > 0) {
				setInvalidPhoneMessage(`Mobile number is invalid for ${countryObj?.value}`);
			} else {
				setInvalidPhoneMessage(`Invalid mobile number`);
			}
		}

		if (differentCompany || ownBilling) {
			for (const [key, value] of enties) {
				const capitalizeFieldName = capitalizeFirstLetter(key);

				if (!value) {
					setErrorMessage(prev => ({
						...prev,
						[key]: `${capitalizeFieldName} is required!`,
					}));

					if (key) {
						scrollToIndex(SubAccountNameIds[key]);
					}
				}

				if (
					(key == 'firstName' || key == 'lastName') &&
					!isValidRecipientName(value.trim())
				) {
					setErrorMessage(prev => ({
						...prev,
						[key]: message.VALID_NO_SPECIAL_NUMBER_MESSAGE,
					}));
					invalidUserNamesFormat = true;
					if (key) {
						scrollToIndex(SubAccountNameIds[key]);
					}
				}
			}
			primaryValue = Object.values(primaryUserDetails ?? {}).some(el => !el);
		}
		if (!name.trim()) {
			setErrorMessage(prev => ({
				...prev,
				['name']: `Sub-account name is required!`,
			}));

			scrollToIndex(SubAccountNameIds['name']);
		}
		if (name.trim().length > 50) {
			setErrorMessage(prev => ({
				...prev,
				['name']: 'Sub-account name must be less than or equal to 50 characters',
			}));
			scrollToIndex(SubAccountNameIds['name']);
			return;
		}

		const isNameExist = subAccountRows.find(
			item =>
				item?.name?.toLowerCase() ===
				createSubAccount.name?.toLowerCase()?.trim()
		);
		if (isNameExist && !isEditable) {
			setErrorMessage(prev => ({
				...prev,
				['name']: message.DUPLICATE_NAME_MESSAGE,
			}));

			scrollToIndex(SubAccountNameIds['name']);

			return setIsErrorAccountName(true);
		}

		if (
			isNameExist &&
			isEditable &&
			isNameExist?.name !== subAccDetail?.name?.trim()
		) {
			setErrorMessage(prev => ({
				...prev,
				['name']: message.DUPLICATE_NAME_MESSAGE,
			}));

			scrollToIndex(SubAccountNameIds['name']);

			return setIsErrorAccountName(true);
		}

		// Format phone number: Remove parentheses, spaces, dashes, and dots
		const cleanedPhoneNumber = temporaryPhoneNumber.replace(/[()\s.-]/g, '');

		const payload = {
			name: name.trim(),
			ownBilling,
			differentCompanyBilling: differentCompany,
			primaryUserDetail: {
				...primaryUserDetails,
				phone: cleanedPhoneNumber, // cleaned phone number
			},
		};

		if (isEditable && subAccDetail) {
			patchEditSubAccount(`${API_URL.SUB_ACCOUNT}/${subAccDetail?._id}`, {
				name: name.trim(),
			}).then(resp => {
				setInvalidPhoneMessage('');
				if (resp?._id) {
					successNotification(message.UPDATE_SUCCESS_MESSAGE);
					handleClose();
					setSubAccountRows(prev => {
						const prevObj = structuredClone(prev);
						const subResult = prevObj?.find(
							item => item._id === subAccDetail?._id
						);
						const idx = prevObj?.findIndex(
							item => item._id === subAccDetail?._id
						);
						if (subResult && idx !== -1) {
							const payload = {
								...subResult,
								['name']: name,
							};

							prevObj.splice(idx, 1, payload);
						}
						return prevObj;
					});
				} else {
					errorNotification(resp.message ?? message.SomethingWentWrongMessage);
				}
			});
		} else {
			if (!primaryValue && !invalidUserNamesFormat && isPhoneNumberValid && name.trim()) {
				postCreateSubAccount(API_URL.SUB_ACCOUNT, payload).then(resp => {
					if (resp?._id) {
						if (differentCompany || ownBilling) {
							resp['totalUsers'] = 1;
						}
						successNotification(message.SUB_ACCOUNT_CREATED_SUCCESS);
						setSubAccountRows(pre => [resp, ...pre]);
						handleClose();
					}
				});
			}
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [createSubAccount, countryCode]);

	const handleChange = useCallback(
		(
			e: ChangeEvent<HTMLInputElement & { countryCode?: string }>,
			name: string
		) => {
			const { value, checked, type } = e.target;
			if (type === 'checkbox') {
				setErrorMessage({
					firstName: '',
					lastName: '',
					email: '',
					phone: '',
					name: '',
				});
			} else {
				setErrorMessage(prev => ({
					...prev,
					[name]: '',
				}));
			}

			const capitalizeFieldName = capitalizeFirstLetter(name);
			if (type === 'checkbox') {
				setCreateSubAccount(prev => ({
					...prev,
					[name]: checked,
				}));
				return;
			}

			if (name === 'phone') {
				setInvalidPhoneMessage(""); // Reset the error message
				if (value?.startsWith('+')) {
					const code = fetchCountryCodeFromPhone(value);
					if (code) {
						const newPhone = value?.replace(code, '')?.replace(/\D/g, '');
						setCountry(code);
						setTemporaryPhoneNumber(newPhone);
					} else {
						setTemporaryPhoneNumber(value.replace(/\D/g, ''));
					}
				} else if (value.replace(/\D/g, '').length < 12) {
					if (value.length < 6) {
						setTemporaryPhoneNumber(value.replace(/\D/g, ''));
					} else {
						setTemporaryPhoneNumber(value);
					}
				}
			}

			if (name === 'name') {
				if (!specialCharRegex.test(value)) return;
				setCreateSubAccount(prev => ({
					...prev,
					[name]: value,
				}));

				setIsErrorAccountName(false);
				if (!value) {
					setErrorMessage(prev => ({
						...prev,
						[name]: `Sub-account name is required!`,
					}));
					return;
				}
				if (value.length > 50) {
					setErrorMessage(prev => ({
						...prev,
						[name]: `Sub-account name must be less than or equal to 50 characters`,
					}));
					return;
				}
			} else {
				setCreateSubAccount(
					prev =>
						({
							...prev,
							primaryUserDetails: {
								...prev.primaryUserDetails,
								[name]: value,
							},
						} as ICreateSubAccountState)
				);

				if (!value && name !== 'countryCode') {
					setErrorMessage(prev => ({
						...prev,
						[name]: `${capitalizeFieldName} is required!`,
					}));
				} else if (name === 'email' && !isEmailValid(value)) {
					setErrorMessage(prev => ({
						...prev,
						[name]: 'Invalid email address',
					}));
					return;
				} 
			}
		},
		[setCreateSubAccount, setCountry]
	);

	const getPhoneNumber = useMemo(() => {
		let countryLabel: CountryType = 'US';

		const countryObj = countries.find(item => item.label === countryCode);

		if (countryObj) countryLabel = countryObj.code as CountryType;

		let formattedNumber = '';
		const parsedPhoneNumber = parsePhoneNumberFromString(temporaryPhoneNumber, countryLabel);
		if (temporaryPhoneNumber.length > 6) {
			formattedNumber = new AsYouType(countryLabel).input(temporaryPhoneNumber);
			if (parsedPhoneNumber && parsedPhoneNumber.isValid()) {
				formattedNumber = parsedPhoneNumber.formatNational();
				// Remove leading zero if it exists
				if (formattedNumber.startsWith('0')) {
					formattedNumber = formattedNumber.slice(1);
				}
				// Remove the country calling code prefix
				return formattedNumber.replace(`+${parsedPhoneNumber.countryCallingCode} `, '');
			}
		} else {
			formattedNumber = temporaryPhoneNumber;
		}
		return formattedNumber;
	}, [countryCode, temporaryPhoneNumber]);


	useEffect(() => {
		// Clear the invalid phone message when the country code changes
		setInvalidPhoneMessage("");
	}, [countryCode]);  // Clear error message on country change

	const onSelectOptions = (_: boolean, name: string) => {
		if (isEditable) return;

		setCreateSubAccount(prev => {
			const newObj: any = structuredClone(prev);
			newObj['ownBilling'] = false;
			newObj['differentCompany'] = true;
			newObj[name] = false;
			return newObj;
		});
	};


	return (
		<Modal
			isOpen={isOpen}
			closeModal={handleClose}
			modalName="create-sub-account-modal"
			title={
				<div className="onboarding-details__header">
					<div className="onboarding-details__title">
						{isEditable ? 'Edit' : 'Create'} Sub account
					</div>
				</div>
			}
		>
			<>
				<div className="create-sub-account">
					<Input
						label="Sub account name"
						inputType="text"
						placeholder="Simplici"
						value={createSubAccount['name']}
						handleChange={e => handleChange(e, 'name')}
						isError={!!errorMessage.name || isErrorAccountName}
						errorMessage={errorMessage['name']}
						id="sub-account-name"
					/>
					<div className="create-sub-account_wrapper">
						<div className="create-sub-account_wrapper__inner">
							<div
								className="create-sub-account_wrapper__inner__section"
								style={{
									border: !createSubAccount['ownBilling']
										? '1.5px solid var(--color-primary-light)'
										: '1.5px solid var(--color-label-100-light)',
								}}
								onClick={() =>
									onSelectOptions(createSubAccount.ownBilling, 'ownBilling')
								}
							>
								<div className="sub-account-type-option-wrapper">
									<div className="create-sub-account__ownBilling">
										<div className="create-sub-account__ownBilling--title">
											{SUBACCOUNTDETAILS.OWN_BILLING_TITLE}
										</div>
										<div className="create-sub-account__ownBilling--description">
											{SUBACCOUNTDETAILS.OWN_BILLING_DESCRIPTION}
										</div>
									</div>

									<input
										type="radio"
										checked={!createSubAccount['ownBilling']}
									/>
								</div>
							</div>
							<div
								className="create-sub-account_wrapper__inner__section create-sub-account_wrapper__inner--disabled"
								style={{
									border: !createSubAccount['differentCompany']
										? '1.5px solid var(--color-primary-light)'
										: '1.5px solid var(--color-label-100-light)',
								}}
								//@avinashSatschel need to comment this method as this is disalbed for now
								// onClick={() =>
								// 	onSelectOptions(
								// 		'differentCompany'
								// 	)
								// }
							>
								<div className="sub-account-type-option-wrapper">
									<div className="create-sub-account__ownBilling">
										<div className="create-sub-account__ownBilling--title">
											{SUBACCOUNTDETAILS.COMPANY_SUB_ACCOUNT_TITLE}
										</div>
										<div className="create-sub-account__ownBilling--description">
											{SUBACCOUNTDETAILS.COMPANY_SUB_ACCOUNT_DESCRIPTION}
										</div>
									</div>
									<input
										type="radio"
										checked={!createSubAccount['differentCompany']}
										disabled
									/>
								</div>
								<div className="sub-account-type-option-wrapper__coming_soon">
									Coming Soon...
								</div>
							</div>
						</div>
					</div>
					{showSubscriptionServices && !isEditable && (
						<div className="create-sub-account__subscription">
							<div>
								<div className="create-sub-account__subscription--heading">
									<div className="create-sub-account__subscription--inner">
										<div className="create-sub-account__subscription_title">
											{SUBACCOUNTDETAILS.MANAGER_DETAILS_TITLE}
										</div>
									</div>
									<div className="create-sub-account__subscription__lines" />
								</div>
								<p className="create-sub-account__subscription--subHeading">
									{SUBACCOUNTDETAILS.MANAGER_DETAILS_DESCRIPTION}
								</p>
							</div>
							<div className="create-sub-account__subscriptionForm">
								<div className="create-sub-account__subscription--firstName">
									<Input
										label="First Name"
										inputType="text"
										placeholder="Enter first name"
										handleChange={(e: ChangeEvent<HTMLInputElement>) =>
											handleChange(e, 'firstName')
										}
										isError={!!errorMessage.firstName}
										errorMessage={errorMessage['firstName']}
										maxLength={50}
										isRequired
										id="sub-account-firstName"
									/>
								</div>
								<div className="create-sub-account__subscription--lastName">
									<Input
										label="Last Name"
										inputType="text"
										placeholder="Enter last name"
										id="sub-account-lastName"
										handleChange={(e: ChangeEvent<HTMLInputElement>) =>
											handleChange(e, 'lastName')
										}
										isError={!!errorMessage.lastName}
										errorMessage={errorMessage['lastName']}
										maxLength={50}
										isRequired
									/>
								</div>

								<div className="create-sub-account__subscription--email">
									<Input
										label="Email"
										inputType="text"
										placeholder="johndoe@examplemail.com"
										handleChange={(e: ChangeEvent<HTMLInputElement>) =>
											handleChange(e, 'email')
										}
										isError={!!errorMessage.email}
										errorMessage={errorMessage['email']}
										isRequired
										id="sub-account-email"
									/>
								</div>
								<div
									className="create-sub-account__subscription--phone"
									id="sub-account-phone"
								>
									<Input
										handleChange={(e: ChangeEvent<HTMLInputElement>) =>
											handleChange(e, 'phone')
										}
										inputType="text" //keep type text as number is not giving suggestions for auto fill will use regex to accept number
										value={getPhoneNumber}
										placeholder={``}
										label="Mobile Number"
										handleChangeCountry={e => {
											setCountry(e.label);
										}}
										isCountryCodeVisible={true}
										countryCode={countryCode}
										isError={!!errorMessage.phone || !!invalidPhoneMessage}
										errorMessage={errorMessage['phone'] || invalidPhoneMessage}

										autoComplete="tel"
										inputName="phone"
										isRequired={true}
									/>
								</div>
								<div className="create-sub-account__subscriptionEntity">
									<div className="create-sub-account__icon">
										<i className="ri-briefcase-fill"></i>
									</div>
									<div className="create-sub-account__subscriptionDetails">
										<div className="create-sub-account__subsTitle">
											Onboarding + esign
										</div>
										<div className="create-sub-account__price">
											{`$${price ?? 0}/user/month`}
										</div>
									</div>
								</div>
							</div>
						</div>
					)}
				</div>
				<div className="create-sub-account_footer">
					<Button
						label={isEditable ? 'Cancel' : 'Back'}
						type="button__filled--secondary"
						handleClick={handleClose}
					/>
					<Button
						label={
							loading ? (
								<Loader type="loader" dimension={24} className="loader-white" />
							) : isEditable ? (
								'Save'
							) : (
								'Create'
							)
						}
						type="button__filled--primary"
						handleClick={handleCreate}
						disabled={loading || !!invalidPhoneMessage}
					/>
				</div>
			</>
		</Modal>
	);
};
