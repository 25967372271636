import { Header } from "views/header";
import './esign-draft.scss';
import { EsignDraftTable } from "./components";
import { useSignedDocsInbox } from "views/signed-doc-inbox";
import { useEffect } from "react";

const EsignDraft = () => {
	const { getSignedDocsInbox } = useSignedDocsInbox();


	useEffect(() => {
		getSignedDocsInbox(false,'draft');
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
    return (
		<div className="SignedDocInbox__wrapper EsignDraft">
			<Header title="Esign Draft" />
			<div className="SignedDocInbox__body">
                <EsignDraftTable />
			</div>
		</div>
	);
}

export default EsignDraft