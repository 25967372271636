import { FC, ReactNode, useEffect, useMemo } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';

import { PipelineMasterSteps } from 'global-stores';
import { CustomHandle } from '../custom-handle';
import { ICustomNode } from '../custom-node';
import { RenderAmlBlock } from './renderl-aml-block';

import './aml-action.scss';
import { ActionList } from 'views/complex-onboarding-flow/constant';
import { ComplexSettingFormState } from 'views/pipeline';

const StepIds = ['Verify information about a business entity'];

export const AmlWrapper = ({ children }: { children: ReactNode }) => {
	return <div className="aml-action">{children}</div>;
};

export const AmlAction: FC<ICustomNode> = ({ id: nodeId, data }) => {
	const pipelinesSteps = useRecoilValue(PipelineMasterSteps);
	const [complexSettingForm, setComplexSettingForm] = useRecoilState(
		ComplexSettingFormState
	);

	const actionId = useMemo(() => data.label, [data.label]);

	const selectDefaultLabel = useMemo(
		() => complexSettingForm?.[nodeId]?.amlFrequency,
		[complexSettingForm, nodeId]
	);
	
	const actions = useMemo(
		() =>
			pipelinesSteps.find(
				pipelineStep => pipelineStep.key === (ActionList as any)[actionId]
			)?.actions[0],
		[actionId, pipelinesSteps]
	);

		// Calc. price
		const price = useMemo(() => {
			return (
				actions?.metadata?.[0]?.options?.find(el => el.key === selectDefaultLabel)
					?.price ?? actions?.price
			);
		}, [actions?.metadata, actions?.price, selectDefaultLabel]);

	//Shahbaaz Set aml actions in complex config setting form for showing price breakdown
	useEffect(() => {
		if(!complexSettingForm?.[nodeId]){
		setComplexSettingForm((prev: any) => {
			const newObj = structuredClone(prev);
			if (!newObj[nodeId]) {
				newObj[nodeId] = {};
			}
			Object.assign(newObj[nodeId], {
				amlVerification: 'AML',
				amlFrequency: 'oneTime'
			});

			return newObj;
		});
	}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [complexSettingForm, nodeId]);

	const renderActionLayout = useMemo(
		() =>
			StepIds.map((el: string) => (
				<RenderAmlBlock
					stepId="aml"
					label={actions?.label}
					key={el}
					price={price}
					nodeId={nodeId}
				/>
			)),
		[actions?.label, price, nodeId]
	);

	return (
		<AmlWrapper>
			{renderActionLayout}
			<CustomHandle value={nodeId} />
		</AmlWrapper>
	);
};
