import { createRef, FC, useCallback, useMemo, useState } from 'react';
import { useSetRecoilState } from 'recoil';
import { toast } from 'react-toastify';

import { IconBtn } from '@storybook';
import {
	checkBoxStateInTable,
	SelectedSessionsIdsForZipState,
	SelectedSessionsIdState,
} from 'global-stores';
import { API_URL, message } from 'constant';
import { useNetwork, useOutsideClick } from 'hooks';
import { ConfigureSession } from '../configure-session';
import { AssignSelectedSessionState } from '../select-fund-modal';

import './drop.scss';
import { PERMISSION_SCREEN, useUserRoles } from 'views';

export interface IDropOptions {
	handleAssignReviewer?: (
		e: React.MouseEvent<HTMLDivElement, MouseEvent>,
		item: any
	) => void;
	handleAssignFund?: (
		e: React.MouseEvent<HTMLDivElement, MouseEvent>,
		item: any
	) => void;
	handleConfigFund?: (
		e: React.MouseEvent<HTMLDivElement, MouseEvent>,
		id: string
	) => void;
	handleArchiveDrop?: (
		e: React.MouseEvent<HTMLDivElement, MouseEvent>,
		item: any
	) => void;
	DownloadPdf?: (item: any) => any;
	item: any;
}

export const DropOptions: FC<IDropOptions> = ({
	handleAssignReviewer,
	handleAssignFund,
	item,
	handleArchiveDrop,
	handleConfigFund,
}: any) => {
	const [showOption, setShowOption] = useState<boolean>(false);
	const [openConfigureSession, setOpenConfigureSession] = useState(false);
	const ref = createRef<HTMLDivElement>();
	const setSelectedSession = useSetRecoilState(AssignSelectedSessionState);
	const setSelectedIdState = useSetRecoilState(SelectedSessionsIdState);
	const setSelectedIdsForZip = useSetRecoilState(SelectedSessionsIdsForZipState);
	const setCheckBoxState = useSetRecoilState(checkBoxStateInTable);
	const { post: updateSessionIds, loading } = useNetwork();

	const { API_RESPONSE_NOTIFICATION } = message;
	const { checkUserWritePermission } = useUserRoles();

	const isUserPermissionWrite = useMemo(
		() => checkUserWritePermission(PERMISSION_SCREEN.Sessions),
		[checkUserWritePermission]
	);

	useOutsideClick(ref, () => {
		setShowOption(false);
	});

	const handleOptions = useCallback((e: any) => {
		e.stopPropagation();
		setShowOption(prev => !prev);
	}, []);

	const handleAssignFundSession = useCallback(
		(e: { stopPropagation: () => void }) => {
			e.stopPropagation();
			setSelectedSession([item]);
			handleAssignFund(true);
		},
		[handleAssignFund, item, setSelectedSession]
	);

	const handleAssignReviewerSession = useCallback(
		(e: { stopPropagation: () => void }) => {
			e.stopPropagation();
			if (isUserPermissionWrite) {
				setSelectedSession([item]);
				handleAssignReviewer(true);
			}
			return;
		},
		[handleAssignReviewer, isUserPermissionWrite, item, setSelectedSession]
	);

	const handleOnDownload = useCallback(
		async (e: { stopPropagation: () => void }) => {
			e.stopPropagation();
			const type = 'pdf';
			setSelectedSession([item]);
			setSelectedIdState([item._id]);
			setSelectedIdsForZip([item._id]);
			
			// Pradeep : toster messege added for download
			await toast
				.promise(
					updateSessionIds(API_URL.SESSION_REPORTS, {
						sessionIds: [item._id],
						fileType: type,
					}),
					{
						pending: API_RESPONSE_NOTIFICATION,
					}
				)
				.then(res => {
					if (type === 'pdf' && res?.url.length > 0) {
						res.url.map((url: any) => window.open(url, '_self'));
						setSelectedSession([]);
						setCheckBoxState(false);
					}
					setShowOption(false);
				});
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[item, updateSessionIds]
	);

	const handleDownloadDisabled = useMemo(() => {
		if (loading) return true;
		return false;
	}, [loading]);

	const handleDownloadLabel = useMemo(() => {
		if (loading) return 'Loading...';
		return 'Download';
	}, [loading]);

	const handleCloseModal = useCallback(() => {
		setOpenConfigureSession(false);
	}, []);

	return (
		<div ref={ref} className={'options-wrapper'}>
			<IconBtn
				className="extra"
				btnClassName="ri-more-2-fill"
				handleClickIcon={handleOptions}
			/>
			{showOption && (
				<div className="options-box">
					{item?.type !== 'complex' && (
						<>
							<button
								className="menu-item"
								onClick={e => {
									handleConfigFund(e, item._id);
									setSelectedSession([item]);
								}}
							>
								<i className="ri-edit-line space" />
								Configure
							</button>
						</>
					)}
					<button className={isUserPermissionWrite ?"menu-item user-permision__enable":"menu-item user-permision__disable"} onClick={handleAssignReviewerSession}>
						<i className="ri-user-settings-line space" />
						Assign Reviewer
					</button>
					{/* pradeep : Assign Fund enabled for complex */}
					<button onClick={handleAssignFundSession} className="menu-item">
						<i className="ri-add-line space" />
						Assign Fund
					</button>
					<button
						onClick={e => handleArchiveDrop(e, item)}
						className="menu-item"
					>
						<i className="ri-archive-line space" />
						Archive
					</button>
					{/* pradeep : download pdf enabled */}
					<button
						className="menu-item"
						onClick={handleOnDownload}
						disabled={handleDownloadDisabled}
					>
						<i className="ri-download-cloud-line space" />
						{handleDownloadLabel}
					</button>
				</div>
			)}
			{openConfigureSession && (
				<ConfigureSession
					isOpen={openConfigureSession}
					selectedSessionId={item._id}
					handleClose={handleCloseModal}
				/>
			)}
		</div>
	);
};
