/* eslint-disable react/no-array-index-key */
import { ITotalUnviewedCount } from 'global-stores/sessions/type';
import type { IReactResponsiveTable as props } from './type';
import Tippy from '@tippyjs/react';

import {
	ChangeEvent,
	Fragment,
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import classNames from 'classnames';
import { SkeletonTable, CustomPagination } from '@storybook';

import {
	filteringTagsState,
	IframePropsState,
	pipelinesFiltersState,
	SelectedDateRangeState,
	FilterTableIdState,
	UploadedDocsState,
	SelectedSessionsIdState,
	SelectedSessionsIdsForZipState,
	IUploadedDocuments,
	SelectedDocsForUploadState,
	IsBannerStatusState,
	TotalUnviewedCountState,
} from 'global-stores';
import {
	formatNumber,
	formatSizeUnits,
	getDate,
	getDateWithTime,
	getESTDateWithTime,
	getTime,
	getUtcMilliSecond,
	useFormatNumber,
} from 'utils';

import {
	FoundFilterSessionsCountState,
	TableSearchEmptyMessage,
	TextForFilterSessionsState,
} from './components';
import { useOutsideClick } from 'hooks';
import { TableHeader } from './table-header';
import {
	filterList,
	filterListWithType,
	IFilterTabStatus,
	SelectedFilterTabState,
} from './store';

import './react-responsive-table.scss';
import { SelectedTransactionIdsState } from 'views/transactions';
import { AssignSelectedSessionState } from 'views/sessions';
import { InboxfilteringTagsState } from 'views/signed-doc-inbox';
import { PacketInboxfilteringTagsState } from 'views/signed-doc-packets-inbox';
import { useWindowSize } from 'hooks/window-size';

interface ISorting {
	isSort: boolean;
	sortKey: string;
}

const floatDATA: { [key: string]: boolean } = {
	moneyRaised: true,
	totalMoneyRaised: true,
	postMoneyValuation: true,
	price: true,
};
const emailData: { [key: string]: boolean } = { email: true };
const invested: { [key: string]: boolean } = { invested: true };
const sortingKey: any = {
	_id: 'flowId',
	clientId: 'clientID',
	email: 'sessionEmail',
	date: 'date',
	name: 'documentName',
	status: 'status1',
	updatedAtDate: 'updatedAt',
	issueDate: 'issueDate',
	instanceName: 'instanceName',
};

const dateData: { [key: string]: string } | any = {
	updatedAtDate: 'updatedAt',
};

const dealsData: { [key: string]: string } | any = {
	dealId: 'deal_Id',
	sellerName : 'seller',
	buyerName : 'buyer',
	type : 'transaction_type',
	amount : 'totalAmount',
	date: 'createdAt'
};

const tabsData = [
	{ label: 'All', status: 'all', unviewed: 'totalUnviewedCount' },
	{
		label: 'Pending',
		status: 'pending',
		unviewed: 'totalUnviewedPendingCount',
	},
	{
		label: 'Under Review',
		status: 'processing',
		unviewed: 'totalUnviewedReviewedCount',
	},
	{
		label: 'Rejected',
		status: 'rejected',
		unviewed: 'totalUnviewedRejectedCount',
	},
	{
		label: 'Approved',
		status: 'completed',
		unviewed: 'totalUnviewedApprovedCount',
	},
	{
		label: 'Warning',
		status: 'amlWarning',
		unviewed: 'totalUnviewedAmlWarningCount',
	},
	{
		label: 'Assigned to me',
		status: 'assigned',
		unviewed: 'totalUnviewedAssigneeCount',
	},
];

/**
 * ReactTable component for displaying a dynamic table with search and filter options.
 *
 *   @param {Function} props.downloadZip - A function to handle downloading the table data as a zip file.
 *   @param {boolean} props.isLoaded - A flag indicating whether data has been loaded (default: true).
 *   @param {string} props.tableType - The type of table being displayed.
 *   @param {Array} props.column - An array of objects representing columns in the table.
 *   @param {string} props.column.label - The label for the column.
 *   @param {string} props.column.key - The key to identify the column.
 *   @param {Array} props.rows - An array of objects representing rows in the table (default: []).
 *   @param {boolean} props.isLoading - A flag indicating whether data is still loading (default: false).
 *   @param {boolean} props.showDateFilter - A flag indicating whether to show date filter options.
 *   @param {boolean} props.showFilter - A flag indicating whether to show filter options.
 *   @param {boolean} props.showArchiveFilter - A flag indicating whether to show archive filter options.
 *   @param {boolean} props.showSearch - A flag indicating whether to show search input.
 *   @param {string} props.className - Additional class names for the table.
 *   @param {boolean} props.columnHandle - A flag indicating whether to handle column options.
 *   @param {boolean} props.showSort - A flag indicating whether to show sorting options (default: true).
 *   @param {Function} props.handelRowClick - A function to handle row click events.
 *   @param {string} props.filterPage - The current filter page.
 *   @param {string} props.EmptyIllustration - The illustration to display when no data is present (default: 'noData.svg').
 *   @param {string} props.EmptyMessageHeading - The heading for the empty message (default: 'No Results Found').
 *   @param {string} props.EmptyMessageDescription - The description for the empty message (default: 'Sorry, there are no result found.').
 *   @param {boolean} props.isFilterDisabled - A flag indicating whether filter options are disabled (default: false).
 *   @param {boolean} props.isArchiveDisabled - A flag indicating whether archive options are disabled (default: false).
 *   @param {boolean} props.collapsible - A flag indicating whether the table is collapsible (default: false).
 *   @param {ReactNode} props.defaultComponent - The default component to render.
 *   @param {number} props.headerZIndex - The z-index for the table header (default: 2).
 *   @param {Object} props.customFilter - Custom filter options.
 *   @param {string[]} props.hideSortKey - hide sort for passed keys
 *   @param {boolean} props.numberPagination - for number pagination
 *   @param {Function} props.handlePageChange - for number pagination
 *   @param {boolean} props.isSignActivityLog- for time changes a/c to location
 *   @param {Function} props.handleInputSearch--for search handling
 *
 *
 */

export const ReactResponsiveTable = ({
	downloadZip,
	isLoaded = true,
	tableType,
	column,
	rows = [],
	isLoading = false,
	showDateFilter = false,
	showFilter = false,
	showArchiveFilter = false,
	showAssignee = false,
	showSearch = false,
	className = '',
	columnHandle = false,
	showSort = true,
	handelRowClick,
	filterPage = '',
	EmptyIllustration = 'noData.svg',
	EmptyMessageHeading = 'No Results Found',
	EmptyMessageDescription = 'Sorry, no results were found.',
	isFilterDisabled = false,
	isArchiveDisabled = false,
	collapsible = false,
	defaultComponent,
	headerZIndex = 2,
	customFilter,
	hideSortKey = [],
	handlePagination,
	isPagination = false,
	numberPagination = false,
	handlePageChange,
	totaloPageCount,
	itemsPerPage = 10,
	uploadedFiles,
	isSignActivityLog = false,
	getSearchText,
	handleInputSearch,
	searchParams = '',
	downloadChildren = <></>,
	showFilterTab = false,
	handleTabClick,
}: props) => {
	const filterRef = useRef();
	const { theadColor } = useRecoilValue(IframePropsState);
	const bannerStatus = useRecoilValue(IsBannerStatusState);
	const [searchedText, setSearchedText] = useState<string>('');
	const [tableRowsData, setTableRows] = useState<any[]>(rows);
	const [activeTab, setActiveTab] = useRecoilState(SelectedFilterTabState);
	const [hideSorting] = useState([
		'fullStatus',
		'action',
		'actions',
		'signAgreement',
		'506b',
		'paymentStatus',
		'amountStatus',
		'authType',
		'origin',
		'',
		...hideSortKey,
	]);
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const selectedDate: any = {};
	const [sortBy, setSortBy] = useState<ISorting>({
		isSort: false,
		sortKey: '',
	});
	const [isFilterOpen, setFilterOpen] = useState(false);
	const [isCollapsible] = useState(true);
	const [showTippyForHeader, setShowTippyForHeader] = useState<{
		[key: string]: boolean;
	}>({});

	//global state
	const filteringTags = useRecoilValue(filteringTagsState);
	const setFilterTableId = useSetRecoilState(FilterTableIdState);
	const pipelinesFilter = useRecoilValue(pipelinesFiltersState);
	const [selectedSession, setSelectedSession] = useRecoilState(
		AssignSelectedSessionState
	);
	const [addedFiles, setAddedFiles] = useRecoilState(UploadedDocsState);
	const setSelectedFiles = useSetRecoilState(SelectedDocsForUploadState);
	const selectedDateRange = useRecoilValue(SelectedDateRangeState);
	const setSelectedIdState = useSetRecoilState(SelectedSessionsIdState);
	const setSelectedIdsForZip = useSetRecoilState(
		SelectedSessionsIdsForZipState
	);
	const [selectedTransactionIds, setselectedTransactionIds] = useRecoilState(
		SelectedTransactionIdsState
	);
	const signDocFilterTags = useRecoilValue(InboxfilteringTagsState);
	const packetSignDocFilterTags = useRecoilValue(PacketInboxfilteringTagsState);
	const textForFilterSession = useRecoilValue(TextForFilterSessionsState);
	const setFoundFilteredSessions = useSetRecoilState(
		FoundFilterSessionsCountState
	);

	// Use the Recoil hook to get the current value of the `TotalUnviewedCountState` atom
	const totalUnviewed = useRecoilValue(TotalUnviewedCountState);

	useOutsideClick(filterRef, () => {
		setFilterOpen(false);
	});
	useEffect(() => {
		setTableRows(rows);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [rows]);

	const { innerSize } = useWindowSize();

	const formatDate = useCallback((date: any) => {
		const d = new Date(date);
		let month = '' + (d.getMonth() + 1),
			day = '' + d.getDate();
		const year = d.getFullYear();

		if (month.length < 2) month = '0' + month;
		if (day.length < 2) day = '0' + day;

		return [year, month, day].join('-');
	}, []);

	const checkSearch = useCallback(
		(name: string | number | undefined) => {
			return name
				?.toString()
				?.toLowerCase()
				?.includes?.(searchedText?.trim().toLowerCase());
		},
		[searchedText]
	);

	//added this method here for preveting flickering added by @avinashSatschel
	const handleOnClickDateFilter = useCallback(() => {
		const { endDate, startDate }: any = selectedDateRange[0];
		const fromDates = getUtcMilliSecond(startDate, 'start');
		const endDates = getUtcMilliSecond(endDate, 'end');

		const filteredArr = [...rows]?.filter(item => {
			const date = getUtcMilliSecond(item.createdAt);
			if (date >= fromDates && date <= endDates) {
				return item;
			}
			return null;
		});
		setTableRows(filteredArr);
	}, [selectedDateRange, rows, setTableRows]);
	const noDateTimeColumn = useMemo(() => {
		return tableType === 'pipeLine' || tableType === 'sessions';
	}, [tableType]);

	useEffect(() => {
		if (showDateFilter && !noDateTimeColumn) {
			handleOnClickDateFilter();
		}
	}, [handleOnClickDateFilter, selectedDateRange, showDateFilter,noDateTimeColumn]);

	const tableData = useMemo(
		() =>
			tableRowsData.filter(item => {
				if (searchedText.length > 0) {
					if (tableType === 'pipeLine') {
						if (checkSearch(item?.name) || checkSearch(item?.flowId)) {
							return true;
						}
						return false;
					} else if (tableType === 'sessions') {
						return tableRowsData ?? [];
					} else if (tableType === 'fund-details') {
						if (
							checkSearch(item?.name) ||
							checkSearch(item?.sendEmail) ||
							checkSearch(item?.price) ||
							checkSearch(item?.totalShares) ||
							checkSearch(item?.investedAmount) ||
							checkSearch(item?.lastName) ||
							checkSearch(item?.phone)
						) {
							return true;
						}
						return false;
					} else if (tableType === 'chequeFraud') {
						if (
							checkSearch(item?.userName) ||
							checkSearch(item?.sendEmail) ||
							checkSearch(item?.userMobileNumber) ||
							checkSearch(item.transactionId)
						) {
							return true;
						}
						return false;
					} else if (tableType === 'deals') {
						// searched text has handled by backend in deals
						return true
					} else {
						if (
							checkSearch(item?.name) ||
							checkSearch(item?.documentName) ||
							checkSearch(item?.sendEmail) ||
							checkSearch(item?.firstName) ||
							checkSearch(item?.lastName) ||
							checkSearch(item?.email) ||
							checkSearch(item?.fundName) ||
							checkSearch(item?.sender) ||
							checkSearch(item?.recipient) ||
							checkSearch(item?.amount) ||
							checkSearch(item?._id) ||
							checkSearch(item?.createdAt) ||
							checkSearch(item?.pending) ||
							checkSearch(item?.postMoneyValuation) ||
							checkSearch(item?.totalMoneyRaised) ||
							checkSearch(item?.documentId) ||
							checkSearch(item?.investor) ||
							checkSearch(item?.price) ||
							checkSearch(item?.flowId) ||
							(item?.createdAt && checkSearch(getDate(item.createdAt))) ||
							checkSearch(item?.raised) ||
							checkSearch(item?.round) ||
							checkSearch(item?.clientID) ||
							checkSearch(item?.sessionMobile) ||
							checkSearch(item?.sessionEmail) ||
							checkSearch(item?.['role.name']) ||
							checkSearch(item?.status1) ||
							checkSearch(item?.type)
						) {
							return true;
						}
						return false;
					}
				}

				if (!noDateTimeColumn){
					const date = new Date(formatDate(item.createdAt)).getTime();
							if (date >= selectedDate.fromDates && date <= selectedDate.endDates) {
								return item;
							}
				}
				return <></>;
			}),
		// eslint-disable-next-line
		[
			checkSearch,
			tableRowsData,
			searchedText,
			searchedText.length,
			selectedDate.endDates,
			selectedDate.fromDates,
		]
	);

	const { numberDecimal } = useFormatNumber();

	const { isSort, sortKey } = useMemo(() => sortBy, [sortBy]);

	const tableRows = useMemo(
		() =>
			tableData
				.filter(row => {
					const isMatched = column.find(({ key, format }) => {
						let value = row[key];
						switch (format) {
							case 'date':
								value = getDate(value);
								break;
							case 'dateTime':
								value = getDateWithTime(value);
								break;

							default:
								break;
						}

						if (format !== 'jsx') {
							return (
								value
									?.toString()
									?.toLowerCase()
									?.includes?.(searchedText?.trim().toLowerCase()) === true
							);
						}
						return <></>;
					});
					return !!isMatched;
				})
				// eslint-disable-next-line array-callback-return
				.sort((a: any, b: any) => {
					try {
						if (floatDATA[sortKey]) {
							const first =
								a?.[sortingKey?.[sortKey]] ?? typeof a?.[sortKey] === 'number'
									? a?.[sortKey]
									: a?.[sortKey]?.toLowerCase();
							const second =
								b?.[sortingKey?.[sortKey]] ?? typeof b?.[sortKey] === 'number'
									? b?.[sortKey]
									: b?.[sortKey]?.toLowerCase();
							if (isSort) {
								return first - second;
							} else {
								return second - first;
							}
						} else if (emailData[sortKey]) {
							const first = a.sendEmail.split('@')[0];
							const second = b.sendEmail.split('@')[0];
							if (isSort) {
								return first > second ? 1 : -1;
							}
							return first > second ? -1 : 1;
						} else if (invested[sortKey]) {
							const first =
								a?.[sortingKey?.['investedAmount']] ??
								typeof a?.['investedAmount'] === 'number'
									? a?.['investedAmount']
									: a?.['investedAmount']?.toLowerCase();

							const second =
								b?.[sortingKey?.['investedAmount']] ??
								typeof b?.['investedAmount'] === 'number'
									? b?.['investedAmount']
									: b?.['investedAmount']?.toLowerCase();
							if (isSort) {
								return first > second ? 1 : -1;
							}
							return first > second ? -1 : 1;
						} else if (dateData[sortKey]) {
							const first = new Date(a[dateData[sortKey]]);
							const second = new Date(b[dateData[sortKey]]);
							if (isSort) {
								return first.getTime() - second.getTime();
							}
							return second.getTime() - first.getTime();
						} else if (dealsData[sortKey]) {
							const first =
								a?.[sortingKey?.[dealsData[sortKey]]] ??
								typeof a?.[dealsData[sortKey]] === 'number'
									? a?.[dealsData[sortKey]]
									: a?.[dealsData[sortKey]]?.toLowerCase();
							const second =
								b?.[sortingKey?.[dealsData[sortKey]]] ??
								typeof b?.[dealsData[sortKey]] === 'number'
									? b?.[dealsData[sortKey]]
									: b?.[dealsData[sortKey]]?.toLowerCase();
							if (isSort) {
								return first > second ? 1 : -1;
							}
							return first > second ? -1 : 1;
						} else {
							let newSortKey = sortKey;
							if (typeof a?.[sortKey] === 'function') {
								switch (sortKey) {
									case 'firstName':
										newSortKey = 'name';
										break;
									case 'share':
										newSortKey = 'totalShares';
										break;
									case 'provider':
										newSortKey = 'providerName';
										break;
									case 'clientId':
										newSortKey = 'clientID';
										break;
									case 'name':
										newSortKey = 'documentName';
										break;
									case 'status':
										newSortKey = 'status1';
										break;
									case 'documentName':
										newSortKey = 'documentName1';
										break;
									case 'type':
										newSortKey = 'type1';
										break;
									default:
										break;
								}
							}

							if (newSortKey) {
								const first =
									a?.[sortingKey?.[newSortKey]] ??
									typeof a?.[newSortKey] === 'number'
										? a?.[newSortKey]
										: a?.[newSortKey]?.toLowerCase();
								const second =
									b?.[sortingKey?.[newSortKey]] ??
									typeof b?.[newSortKey] === 'number'
										? b?.[newSortKey]
										: b?.[newSortKey]?.toLowerCase();
								if (isSort) {
									return first > second ? 1 : -1;
								}
								return first > second ? -1 : 1;
							}
						}
						if (a['createdAt'] && b['createdAt'] && !isSort && !searchParams) {
							return a['createdAt'] < b['createdAt']
								? 1
								: a['createdAt'] > b['createdAt']
								? -1
								: 0;
						}
					} catch (error) {
						return 0;
					}
					return 1;
				}),
		[tableData, column, searchedText, sortKey, isSort, searchParams]
	);

	const isSorting = useCallback(
		(key: string) => {
			return showSort && !hideSorting.find(k => k === key);
		},

		[showSort, hideSorting]
	);

	const handleSort = useCallback(
		(key: string, value: boolean) => {
			if (isSorting(key)) {
				setSortBy({
					isSort: value,
					sortKey: key,
				});
			}
		},
		[isSorting]
	);

	const filteredRows = useMemo(() => {
		if (filterPage === 'pipelines') {
			const rows = tableRows.filter(row =>
				Object.keys(pipelinesFilter ?? {}).every(
					filter =>
						row[filter] !== undefined &&
						pipelinesFilter[filter].includes(row[filter])
				)
			);

			return rows;
		} else if (filterPage === 'sessions') {
			const rows = tableRows.filter(row =>
				Object.keys(filteringTags ?? {}).every(filter => {
					if (row.type === 'complex' && filterList[filter]) {
						let complexStatus = '';
						if (filterListWithType[filter]) {
							const complexNode = row?.['nodes']?.find(
								(node: any) => node.type === filterList[filter]
							);
							complexStatus =
								complexNode && (complexNode.action?.status || 'pending');
						} else {
							const complexNode = row?.['nodes']?.find(
								(node: any) => node.key === filterList[filter]
							);
							complexStatus =
								complexNode && (complexNode.action?.status || 'pending');
						}
						return (
							!!complexStatus && filteringTags[filter].includes(complexStatus)
						);
					}
					return (
						filter==="assignedUserIds" ? true: row[filter] !== undefined &&
						((filteringTags[filter].includes(row[filter])))
					);
				})
			);
			return rows;
		} else if (filterPage === 'signDocInbox') {
			const rows = tableRows.filter(row =>
				Object.keys(signDocFilterTags ?? {}).every(
					filter =>
						row[filter] !== undefined &&
						signDocFilterTags[filter].includes(row[filter])
				)
			);
			return rows;
			// Arun Kumar : Filter Tags for Inbox packet screen
		} else if (filterPage === 'signDocPacketInbox') {
			const rows = tableRows.filter(row =>
				Object.keys(packetSignDocFilterTags).every(
					filter =>
						row[filter] !== undefined &&
						(packetSignDocFilterTags[filter] ?? []).includes(row[filter])
				)
			);
			return rows;
		} else {
			return tableRows;
		}
	}, [
		filterPage,
		tableRows,
		pipelinesFilter,
		filteringTags,
		signDocFilterTags,
		packetSignDocFilterTags,
	]);

	const handleChangeCheckState = useCallback(
		(e: ChangeEvent<HTMLInputElement>) => {
			const { checked } = e.target;
			const files = JSON.parse(JSON.stringify(uploadedFiles ?? addedFiles));
			files.forEach((file: any) => {
				file.isChecked = checked;
			});
			if (uploadedFiles)
				setSelectedFiles((prevState: IUploadedDocuments[]) => {
					const clonedPrev = structuredClone(prevState);
					const updatedVal = clonedPrev.filter(item =>
						files.find((el: IUploadedDocuments) => el.node !== item.node)
					);
					return [...files, ...updatedVal];
				});
			else setAddedFiles(files);
			if (tableType === 'sessions') {
				// pradeep : removed complex condition for download zip
				setSelectedSession(checked ? filteredRows : []);
				setSelectedIdState(checked ? filteredRows.map(row => row._id) : []);
				setSelectedIdsForZip(checked ? filteredRows.map(row => row._id) : []);
			}
			if (tableType === 'transactions') {
				setselectedTransactionIds(
					checked ? tableRowsData.map(row => row._id) : []
				);
			}
		},
		[
			addedFiles,
			setAddedFiles,
			setSelectedIdState,
			setSelectedSession,
			setselectedTransactionIds,
			tableRowsData,
			tableType,
			filteredRows,
			setSelectedIdsForZip,
			uploadedFiles,
			setSelectedFiles,
		]
	);

	const isAllchecked = useMemo(() => {
		if (
			column?.[1]?.label === 'File Name' ||
			column?.[1]?.label === 'Document'
		) {
			const checked = (uploadedFiles ?? addedFiles).every(
				file => file.isChecked
			);
			return checked;
		}
		if (tableType === 'sessions') {
			// pradeep : removed code for prevent only complex sessions to checked

			// pradeep : removed complex condition for download zip
			const isSame = selectedSession.length === filteredRows?.length;
			if (isSame) {
				return true;
			}
		}
		if (
			tableType === 'transactions' &&
			selectedTransactionIds.length === tableRowsData.length
		)
			return true;
		return false;
	}, [
		column,
		tableType,
		selectedSession,
		filteredRows,
		selectedTransactionIds.length,
		tableRowsData.length,
		addedFiles,
		uploadedFiles,
	]);

	const renderColumn = useMemo(
		() =>
			column.map(({ label, key, width }, index) =>
				key === 'checkbox' ? (
					filteredRows.length !== 0 && (
						<th
							className="table__head-data"
							// eslint-disable-next-line react/no-array-index-key
							key={`renderColumns__${label}_${index}-${key}`}
							style={{ width }}
						>
							<input
								onChange={handleChangeCheckState}
								className="check-box"
								type="checkbox"
								checked={isAllchecked}
							/>
						</th>
					)
				) : (
					<th
						className="table__head-data"
						// eslint-disable-next-line react/no-array-index-key
						key={`renderColumns__${label}_${index}-${key}`}
						style={{ width }}
					>
						<div
							className="table__head-data--label"
							onClick={() =>
								handleSort(key, sortKey === key && isSort ? false : true)
							}
						>
							<Tippy
								disabled={
									!showTippyForHeader[`renderColumns__${label}_${index}-${key}`]
								}
								content={label}
							>
								<span id={`renderColumns__${label}_${index}-${key}`}>
									{label}
								</span>
							</Tippy>
							{isSorting(key) && label && !noDateTimeColumn &&(
								<Tippy			
								disabled={!noDateTimeColumn}	
								content={isSort ? 'Descending by Date' : 'Ascending by Date'}
							>
								<div
									className={`table__head-data--label__arrow table__head-data--label__${
										sortKey === key ? isSort : ''
									}`}
								>
									<i className="ri-arrow-down-s-fill" />
								</div>
								</Tippy>
							)}
						</div>
					</th>
				)
			),
		[
			column,
			filteredRows.length,
			handleChangeCheckState,
			isAllchecked,
			isSorting,
			sortKey,
			isSort,
			handleSort,
			showTippyForHeader,
			noDateTimeColumn
		]
	);

	useEffect(() => {
		const filterID = filteredRows.map(id => id._id);
		setFilterTableId(filterID);
		// eslint-disable-next-line
	}, [filteredRows]);

	const [collapseOpenItemId, setCollapseOpenItemId] = useState<any>([]);
	const setCollapsibleId = (item: any) => {
		if (collapseOpenItemId.indexOf(item.createdAt) >= 0) {
			setCollapseOpenItemId((prev: any) => [
				...prev.filter((x: any) => x !== item.createdAt),
			]);
		} else {
			setCollapseOpenItemId((prev: any) => [...prev, item.createdAt]);
		}
	};

	const renderRows = useMemo(() => {
		const renderingRows = filteredRows;
		if (textForFilterSession) {
			setFoundFilteredSessions(renderingRows?.length ?? 0);
		}
		return renderingRows.map((item, index) => (
			<Fragment key={`renderRows_${item.createdAt}__${index}`}>
				<tr
					onClick={() => {
						// eslint-disable-next-line @typescript-eslint/no-unused-expressions
						handelRowClick ? handelRowClick(item) : {};
						// eslint-disable-next-line @typescript-eslint/no-unused-expressions
						collapsible && setCollapsibleId(item);
					}}
					className={`${columnHandle ? 'row__hover' : ''}`}
				>
					{column.map(({ key, format, width, className }, idx) => {
						let value = item[key];

						if (value) {
							switch (format) {
								case 'number':
									break;
								case 'date':
									value = isSignActivityLog
										? getESTDateWithTime(value)
										: `${getDate(value)}  ${getTime(value)}`;
									break;
								case 'dateTime':
									value = getDateWithTime(value);
									break;
								case 'jsx':
									value = value();
									break;
								case 'price':
									value = `$${numberDecimal(Number(value))}`;
									break;
								case 'percentage':
									value = `${value}%`;
									break;
								case 'size':
									value = formatSizeUnits(value);
									break;
								default:
									break;
							}
						}
						return (
							<td
								key={`renderColumn_${index}__${idx}__${key}`}
								className={`${className ? className : ''}`}
								style={{ width }}
							>
								<div
									className={`${
										format === 'string'
											? tableType === 'pipeLine'
												? 'text-elipsis-pipeline'
												: ''
											: format === 'jsx'
											? 'steps-col-width'
											: ''
									}`}
								>
									{value || '--'}
								</div>
							</td>
						);
					})}
				</tr>
				{collapsible &&
					isCollapsible &&
					!collapseOpenItemId.includes(item.createdAt) &&
					item.instances?.length > 0 && (
						<td className="collapsible_row" colSpan={column.length}>
							{defaultComponent(item, item.instances)}
						</td>
					)}
			</Fragment>
		));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		filteredRows,
		columnHandle,
		column,
		collapsible,
		isCollapsible,
		collapseOpenItemId,
		defaultComponent,
		handelRowClick,
		tableType,
		numberDecimal,
		isSignActivityLog,
	]);

	const isEmpty = useMemo(() => {
		if (
			(!tableData.length && !renderRows.length) ||
			(!filteredRows.length && Object.keys(filteringTags).length)
		)
			return true;
		return false;
	}, [filteredRows.length, filteringTags, renderRows.length, tableData.length]);

	const reactTableResponsiveWrapper = useMemo(() => {
		return classNames(
			`react-table-responsive-wrapper ${
				!bannerStatus ? 'react-table-responsive-with-banner' : ''
			} ${className}`,
			{
				'react-table-responsive-fund-details-wrapper':
					tableType === 'fund-details',
				'react-table-responsive-sessions-wrapper':
					tableType === 'sessions' && bannerStatus,
				'react-table-responsive-sessions-wrapper-with-banner':
					tableType === 'sessions' && !bannerStatus,
				// 'react-table-responsive-pipeLine-wrapper':
				// 	tableType === 'pipeLine' && envHost === 'stage',
				'react-table-responsive-agreement-document-modal-sessions-wrapper':
					tableType === 'agreementdocumentmodal',
			}
		);
	}, [tableType, className, bannerStatus]);

	useEffect(() => {
		if (getSearchText) {
			getSearchText(searchedText);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [searchedText]);

	useEffect(() => {
		document
			.querySelectorAll('.table__head-data--label > span')
			.forEach(cell => {
				setShowTippyForHeader((pre: any) => ({
					...pre,
					[cell.id]: !(cell.scrollWidth <= cell.clientWidth),
				}));
			});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filteredRows, innerSize]);

	const handleTabFilterClick = useCallback(
		(selectedTab: IFilterTabStatus) => {
			if (isLoading) return;
			setActiveTab(selectedTab);
			handleTabClick?.();
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[isLoading]
	);

	const renderUnviewed = useCallback(
		(status: string, unviewed: keyof ITotalUnviewedCount) => {
			// Retrieve the count of unviewed items for the specified key
			const unviewedCount = totalUnviewed?.[unviewed];

			// Render the unviewed badge only if:
			// 1. The status is not 'all'
			// 2. There is a valid unviewed count
			// 3. The unviewed count is greater than 0
			if (status !== 'all' && unviewedCount && unviewedCount > 0) {
				const unviewedClass = classNames(
					'react-table-responsive-container__filter--unviewed',
					{
						'react-table-responsive-container__filter--warningUnviewed':
							status === 'amlWarning',
						'react-table-responsive-container__filter--assigneeUnviewed':
							status === 'assigned',
					}
				);

				return (
					<div className={unviewedClass}>
						{/* Format the unviewed count and display it */}
						{formatNumber(unviewedCount)}
					</div>
				);
			}

			// Return an empty fragment if the conditions are not met
			return <></>;
		},
		[totalUnviewed] // Dependency: React will only re-create the callback when totalUnviewed changes
	);

	const renderFiltersTabsForSessionDetails = useMemo(() => {
		return (
			<div className="react-table-responsive-container__filter">
				{tabsData.map(tab => (
					<div
						key={tab.status}
						className={`tab ${activeTab === tab.status ? 'active' : ''} ${
							isLoading ? 'disabled' : ''
						}`}
						onClick={() => handleTabFilterClick(tab.status as IFilterTabStatus)}
					>
						{tab.label}
						{renderUnviewed(
							tab.status,
							tab.unviewed as keyof ITotalUnviewedCount
						)}
					</div>
				))}
			</div>
		);
	}, [activeTab, handleTabFilterClick, isLoading, renderUnviewed]);

	return (
		<div className="react-table-responsive-container">
			<TableHeader
				downloadZip={downloadZip}
				showSearch={showSearch}
				showFilter={showFilter}
				showArchiveFilter={showArchiveFilter}
				showAssignee={showAssignee}
				setFilterOpen={setFilterOpen}
				isFilterOpen={isFilterOpen}
				filterRef={filterRef}
				filterPage={filterPage}
				setSearchedText={setSearchedText}
				showDateFilter={showDateFilter}
				searchedText={searchParams || searchedText}
				handleAppyDateFilter={handleOnClickDateFilter}
				isFilterDisabled={isFilterDisabled}
				isArchiveDisabled={isArchiveDisabled}
				customFilter={customFilter}
				handleInputSearch={handleInputSearch}
				handlePagination={handlePagination}
				totaloPageCount={totaloPageCount}
				isPageLoading={!isLoaded || isLoading}
				isPagination={isPagination}
			>
				{downloadChildren}
			</TableHeader>
			{showFilterTab && renderFiltersTabsForSessionDetails}

			<div
				className={reactTableResponsiveWrapper}
				id="react-table-responsive-table"
			>
				<table className={`react-table-responsive fl-table ${className}`}>
					<thead
						className="react-table-responsive__head"
						style={{ zIndex: headerZIndex }}
					>
						<tr
							className="react-table-responsive__head-row"
							style={{
								backgroundColor: `${theadColor}` || '#d9e1f2',
								height: '52px',
							}}
						>
							{renderColumn}
						</tr>
					</thead>
					{!!tableData.length && !!renderRows.length && (
						<tbody className="react-table-responsive__body">{renderRows}</tbody>
					)}
				</table>

				{!tableData.length && isLoading && (
					<SkeletonTable
						listsToRender={6}
						numberColumn={column?.length}
						column={column}
					/>
				)}

				{isEmpty && !isLoading && isLoaded && (
					<TableSearchEmptyMessage
						fileName={EmptyIllustration}
						messageHeading={EmptyMessageHeading}
						messageSubHeading={EmptyMessageDescription}
					/>
				)}
			</div>

			{numberPagination && rows.length > itemsPerPage && (
				<div style={{ height: '100px' }}>
					<CustomPagination
						totalItems={rows.length ?? 0} // Replace with your total number of items
						itemsPerPage={itemsPerPage} // Replace with the number of items per page
						onPageChange={handlePageChange}
					/>
				</div>
			)}
		</div>
	);
};
