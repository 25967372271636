import { ILinkedEntities } from 'global-stores/sessions/type';
import { camelCaseToCapitalizedWords } from 'utils';
import { BasicSection } from 'views';

interface IProps {
	heading: string;
	subHeading: string;
	cardType?: string;
	metadata: ILinkedEntities;
}
export const AmlEntityCard: React.FC<IProps> = ({
	heading,
	subHeading,
	cardType,
	metadata,
}) => {
	return (
		<div className="benifical__wrapper">
			<div className="benifical__heading--row">
				<div className="benifical__heading--row__label__wrapper">
					<div className="benifical__heading--row__icon">
						<i className={cardType}></i>
					</div>
					<div>
						<div className="benifical__heading--row__heading benifical__heading--row__bold">{heading}</div>
						<div className="benifical__heading--row__heading--sub">{subHeading}</div>
					</div>
				</div>
			</div>

			{Object.keys(metadata ?? {}).map(item => (
				<BasicSection
					key={item}
					label={camelCaseToCapitalizedWords(item)}
					value={metadata[item as keyof ILinkedEntities] ?? 'N/A'}
				/>
			))}
		</div>
	);
};
