import { FC, useMemo, useState } from 'react';
import { INewRiskView } from 'global-stores/sessions/type';
import { camelCaseToCapitalizedWords } from 'utils';
import { BasicSection, KeyValuePair, KybInfoHeader } from 'views';
import { KYBRiskViewLabel } from './constant';
import { v4 } from 'uuid';
import './kyb-risk-view.scss';
interface IProps {
	riskData: INewRiskView;
	type?: string;
	result?: any;
}
export const KYBRiskView: FC<IProps> = ({ riskData, type, result }) => {
	const [expandedSections, setExpandedSections] = useState<
		Record<string, boolean>
	>({});
	const riskInformation = useMemo(() => {
		const data = {
			riskDecision: result?.riskDecision || 'N/A',
			riskScore: result?.riskScore || 'N/A',
			riskLevel: result?.riskLevel || 'N/A',
			riskScoreEngineId: result?.riskScoreEngineId || 'N/A',
			riskTitle: result?.riskTitle || 'N/A',
		};

		return Object.entries(data).map(([key, value]) => ({
			label: KYBRiskViewLabel[key as keyof typeof KYBRiskViewLabel],
			value,
		}));
	}, [result]);

	const toggleSection = (key: string) => {
		setExpandedSections(prevState => ({
			...prevState,
			[key]: !prevState[key],
		}));
	};
	if (Object.keys(riskData ?? {})?.length === 0) {
		return <></>;
	}
	return (
		<div className="KYBRiskView">
			<KybInfoHeader type={type ?? 'Risk View'} />
			<div>
				{riskInformation?.map(data => {
					return data?.value === 'riskLevel' ? (
						<KeyValuePair
							key={v4()}
							label={data?.label}
							value={camelCaseToCapitalizedWords(data?.value)}
						/>
					) : (
						<BasicSection
							key={v4()}
							label={camelCaseToCapitalizedWords(data?.label)}
							value={data?.value}
							isFromAmlRisk={true}
						/>
					);
				})}
			</div>
			<div>
				<div>
					{Object.entries(riskData)?.map(([outerKey, outerValue]) => {
						const isExpanded = expandedSections[outerKey] ?? false;
						const outerDataEntries = Object.entries(outerValue);
						return (
							<div key={v4()}>
								<div className="KYBRiskView__outerHheader">
									<KybInfoHeader type={camelCaseToCapitalizedWords(outerKey)} />
									<div className="KYBRiskView__outerHheader--viewBtn">
										{Object.keys(outerValue).length > 1 && (
											<button
												className="KYBRiskView__outerHheader--btn"
												onClick={() => toggleSection(outerKey)}
											>
												{isExpanded ? 'View Less' : 'View More'}
											</button>
										)}
									</div>
								</div>
								{outerDataEntries
									?.slice(0, isExpanded ? outerDataEntries.length : 1)
									?.map(([innerKey, innerValue]) => {
										return (
											<div className="KYBRiskView__riskAudt" key={v4()}>
												<div className="KYBRiskView__keyValuePair">
													<KeyValuePair
														label={camelCaseToCapitalizedWords(outerKey)}
														value={camelCaseToCapitalizedWords(innerKey)}
													/>
													<div className="KYBRiskView__riskData">
														<KeyValuePair
															label="Absolute Score"
															value={
																camelCaseToCapitalizedWords(
																	(innerValue?.absoluteScore as any) || '0'
																) ?? 'N/A'
															}
														/>
														<KeyValuePair
															label="Selected"
															value={innerValue?.selected ? 'true' : 'false'}
														/>
														<KeyValuePair
															label="Weightage"
															value={innerValue?.weightage || '0'}
														/>
														<KeyValuePair
															label="Weighted Score"
															value={innerValue?.weightedScore || '0'}
														/>
													</div>
												</div>
											</div>
										);
									})}
							</div>
						);
					})}
				</div>
			</div>
		</div>
	);
};
